/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const missionsTableMock = [
    {
        "id": 3,
        "client_id": 5,
        "vendor_id": 1,
        "name": "General",
        "description": "General chatbot",
        "syscontext": "You are a helpful and polite chatbot working for a software company.",
        "chat_settings": {},
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-10-09T11:11:40.300796+00:00",
        "updated_by": null,
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "folders": [],
        "prompt": []
    },
    {
        "id": 2,
        "client_id": 5,
        "vendor_id": 1,
        "name": "Knowledgebase Search",
        "description": "Knowledge Base Search/Chatbot",
        "syscontext": "You are a helpful software documentation assistant.",
        "chat_settings": {},
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-08-08T19:37:40.300796+00:00",
        "updated_by": null,
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "folders": [
            {
                "id": 3,
                "name": "Software Documentation",
                "mission_id": 2
            }
        ],
        "prompt": []
    },
    {
        "id": 1,
        "client_id": 5,
        "vendor_id": 1,
        "name": "M&A Contracts Analysis",
        "description": "Harris Standard questions analyzing M&A target's contracts",
        "syscontext": "You are a corporate paralegal analyst in the mergers & acquisitions department. You are reviewing documents from an acquisition target as part of due diligence.",
        "chat_settings": {},
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-11T15:37:40.300796+00:00",
        "updated_by": null,
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "folders": [
            {
                "id": 1,
                "name": "Test Contracts",
                "mission_id": 1
            },
            {
                "id": 2,
                "name": "Receipts",
                "mission_id": 1
            }
        ],
        "prompt": [
            {
                "id": 1,
                "title": "Acceptance Clause"
            },
            {
                "id": 2,
                "title": "Agreement Type"
            },
            {
                "id": 3,
                "title": "Assignment Clause"
            },
            {
                "id": 4,
                "title": "Automatic Renewal"
            },
            {
                "id": 5,
                "title": "Automatic Renewal Opt-Out (days)"
            },
            {
                "id": 6,
                "title": "Automatic Renewal Term (months)"
            },
            {
                "id": 7,
                "title": "Change of Control Clause"
            },
            {
                "id": 8,
                "title": "Commencement Date"
            },
            {
                "id": 9,
                "title": "Contract Term (months)"
            },
            {
                "id": 10,
                "title": "Customer Data Clause"
            },
            {
                "id": 11,
                "title": "Data Breach Clause"
            },
            {
                "id": 12,
                "title": "Data Retention Clause"
            },
            {
                "id": 13,
                "title": "Data Security Clause"
            },
            {
                "id": 14,
                "title": "Document Title"
            },
            {
                "id": 15,
                "title": "Effective Date"
            },
            {
                "id": 16,
                "title": "Exclusivity Clause"
            },
            {
                "id": 17,
                "title": "File Name"
            },
            {
                "id": 18,
                "title": "Governing Law"
            },
            {
                "id": 19,
                "title": "Indemnification Clause"
            },
            {
                "id": 20,
                "title": "Invoice Payment (days)"
            },
            {
                "id": 21,
                "title": "Limitation of Liability Clause"
            },
            {
                "id": 22,
                "title": "Main Parties"
            },
            {
                "id": 23,
                "title": "Most Favored Nation Clause"
            },
            {
                "id": 24,
                "title": "Non-Compete Clause"
            },
            {
                "id": 25,
                "title": "Payment Terms Clause"
            },
            {
                "id": 26,
                "title": "Price Change Notification"
            },
            {
                "id": 27,
                "title": "Price Increase (Indexed) Clause"
            },
            {
                "id": 28,
                "title": "Renewal Date"
            },
            {
                "id": 29,
                "title": "Renewal Price Increase"
            },
            {
                "id": 30,
                "title": "Signature Date"
            },
            {
                "id": 31,
                "title": "Subcontracting Restrictions"
            },
            {
                "id": 32,
                "title": "Term Clause"
            },
            {
                "id": 33,
                "title": "Termination Clause"
            },
            {
                "id": 34,
                "title": "Termination Date"
            },
            {
                "id": 35,
                "title": "Third Party Software"
            }
        ]
    }
];