import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function FileNameColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { setShowFileModal, setCurrentFileId }
) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        data-tip
        data-for={`viewPosition${row.id}`}
        key={`view_file_${row.id}`}
        title={"View File"}
        style={{ color: "var(--primary)", textDecoration: "underline" }}
        onClick={() => {
          setCurrentFileId(row.id);
          setShowFileModal(true);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className={`svg-icon svg-icon-md mr-1 svg-icon-${row?.status === "analyzed" ? "warning" : "secondary"}`}
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
          </span>
          {row.filename}
        </div>
      </a>
    </>
  );
}
