// This React Modal component will use axios to retrieve the file from the server and display it in a modal window.
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import ReactMarkdown from "react-markdown";
import { base64ToBytes } from "./textUtils";

/**
 * FileDisplayModal component to display a file in a modal window with Vector DB chunking details below
 * @param {number} props.fileId - the file ID to display (fetch base64 binary contents from server)
 * @param {number} props.filePage - the page number to display in the file (works for PDF with #page= query string)
 * @param {function} props.setFilePage - the function to set the page number in parent component state
 * @param {boolean} props.showFileModal - the state variable to show/hide the modal
 * @param {function} props.setShowFileModal - the function to set the show/hide state in parent component state
 * @returns JSX
 */
export default function FileDisplayModal({
  fileId,
  filePage,
  fileSection,
  setFilePage,
  showFileModal,
  setShowFileModal,
}) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChunks, setShowChunks] = useState(false);
  const [loadingChunks, setLoadingChunks] = useState(true);
  const [error, setError] = useState(null);
  const [chunksError, setChunksError] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [blobPage, setBlobPage] = useState(1);
  const [chunksJson, setChunksJson] = useState([]);
  const [filename, setFilename] = useState("Loading");

  const loadTimeout = useRef(null);
  const loadChunksTimeout = useRef(null);
  const chunksContainerRef = useRef(null);
  const chunkPagesRef = useRef({});
  const markdownAreaRef = useRef(null);

  /**
   * Helper function to scroll to the first chunk by given page number
   * @param {*} filePage - the page number to scroll to
   */
  const scrollToChunk = (filePage, fileSection) => {
    chunksContainerRef.current.scrollTo(
      0,
      chunkPagesRef.current[`${filePage ?? 1}_${fileSection}`]?.offsetTop -
        chunksContainerRef.current?.offsetTop -
        50
    );
  };

  /**
   * Helper function to convert base64 string to Markdown text (UTF-8 encoded)
   * @param {string} base64 - The base64 string to convert
   * @returns {string} - The decoded Markdown text
   */
  const base64ToMarkdown = function (base64) {
    // Decode the base64 string of UTF-8 encoded text
    return new TextDecoder().decode(base64ToBytes(base64));
  };

  /**
   * Helper function to convert base64 string to Blob object to use as objectURL for iframe src
   * @param {*} base64 - the base64 string to convert
   * @param {*} mimeType - the MIME type of the file
   * @returns
   */
  const base64ToBlob = function (base64, mimeType) {
    // Decode the base64 string to binary data
    const binary = atob(base64);
    // Create a Uint8Array of the same length as the binary data
    const bytes = new Uint8Array(binary.length);
    // Assign the binary data to the Uint8Array
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    // Create a Blob from the Uint8Array
    return new Blob([bytes], { type: mimeType });
  };

  useEffect(() => {
    if (filePage && filename.toLowerCase().endsWith(".pdf")) {
      setBlobPage(filePage);
    } else if (fileSection && filename.toLowerCase().endsWith(".md")) {
      if (showFileModal) {
        const element = markdownAreaRef?.current?.querySelectorAll(
          "h1, h2, h3, h4, h5, h6"
        );
        const matchElement = Array.from(element).find((el) =>
          fileSection.trim().startsWith(el.innerText.trim())
        );
        if (matchElement) {
          // scroll markdownAreaRef to matchElement (timeout delay to avoid race condition)
          setTimeout(() => {
            matchElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }, 300);
        }
      }
    }
    if (showFileModal) {
      if (chunkPagesRef?.current?.[`${filePage ?? 1}_${fileSection}`]) {
        scrollToChunk(filePage, fileSection);
      }
    }
  }, [filePage, fileSection, showFileModal, filename]);

  useEffect(() => {
    if (loadTimeout?.current) {
      clearTimeout(loadTimeout.current);
    }
    if (loadChunksTimeout?.current) {
      clearTimeout(loadChunksTimeout.current);
    }
    if (fileId >= 0) {
      setLoading(true);
      setError(null);
      setFilename("Loading . . .");
      const axios_cancel_source = new AbortController();
      loadTimeout.current = setTimeout(() => {
        axios
          .post(
            `/rest/rpc/get_file_base64`,
            { p_file_id: fileId },
            { signal: axios_cancel_source?.signal }
          )
          .then((res) => {
            setFile(res.data);
            setLoading(false);
            setShowFileModal(true);
            // setDataUrl(URL.createObjectURL(res.data));
            // file.querySelector("#file_iframe").src =
            setFilename(res?.data?.[0]?.filename ?? "Loading . . .");
            setBlobUrl(
              URL.createObjectURL(
                //decode res.data.contents from base64 to byte array
                base64ToBlob(
                  res.data[0].contents_base64,
                  res.data[0].filename.toLowerCase().endsWith(".docx")
                    ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" //TODO: iframe causes immediate download of docx files, would rather display a PDF conversion or something
                    : "application/pdf"
                )
              )
            );
          })
          .catch((err) => {
            console.error(err);
            setError(err);
            setLoading(false);
          });
      }, 500);
      loadChunksTimeout.current = setTimeout(() => {
        axios
          .post(
            `/rest/api/get_file_chunks`,
            { file_id: fileId },
            { signal: axios_cancel_source?.signal }
          )
          .then((res) => {
            setLoadingChunks(false);
            setChunksJson(res.data);
          })
          .catch((err) => {
            console.error(err);
            setChunksError(err);
            setLoadingChunks(false);
          });
      }, 500);
      return function cleanUp() {
        axios_cancel_source.abort();
        if (loadTimeout.current) {
          clearTimeout(loadTimeout.current);
        }
        if (loadChunksTimeout.current) {
          clearTimeout(loadChunksTimeout.current);
        }
        // isCancelled = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  useEffect(() => {
    // Update page in parent component useState variable
    if (setFilePage) {
      setFilePage(blobPage);
    }
  }, [blobPage, setFilePage]);

  chunkPagesRef.current = {}; // Reset chunkPagesRef on every render

  return (
    <Modal
      show={showFileModal}
      size="xl"
      onHide={() => setShowFileModal(false)}
    >
      <Modal.Header>
        <Modal.Title>{filename}</Modal.Title>

        <button
          id={`close_modal_btn`}
          title="Close"
          style={{ marginRight: "2px" }}
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => setShowFileModal(false)}
        >
          <span
            id={`modal_close_svg_icon`}
            className="svg-icon svg-icon-md svg-icon-secondary"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error.message}</Alert>}
        {/* PDF display iframe */}
        {!loading && file && filename.toLowerCase().endsWith(".pdf") && (
          <iframe
            key={blobPage}
            title="file"
            id="file_iframe"
            src={`${blobUrl}#page=${blobPage}`}
            width="100%"
            height={window.innerHeight * (showChunks ? 0.35 : 0.7)} // Make room for Chunks div by shrinking file iframe
          ></iframe>
        )}
        {/* Markdown display */}
        {!loading && file && filename.toLowerCase().endsWith(".md") && (
          <div
            ref={markdownAreaRef}
            style={{
              width: "100%",
              height: window.innerHeight * (showChunks ? 0.35 : 0.7), // Make room for Chunks div by shrinking file iframe
              overflowY: "scroll",
              padding: "10px",
              border: "1px solid #ccc",
              backgroundColor: "#fef6d5",
              marginBottom: "5px",
            }}
          >
            <ReactMarkdown
              children={base64ToMarkdown(file[0].contents_base64)}
            />
          </div>
        )}
        {/* Word doc display */}
        {!loading && file && filename.toLowerCase().endsWith(".docx") && (
          <div
            style={{
              width: "100%",
              height: window.innerHeight * (showChunks ? 0.35 : 0.7), // Make room for Chunks div by shrinking file iframe
              overflowY: "scroll",
              padding: "10px",
              border: "1px solid #ccc",
              backgroundColor: "#f5f5f5",
              marginBottom: "5px",
            }}
          >
            <pre>Word document display not yet supported</pre>
          </div>
        )}
        {/* Chunks display */}
        {chunksError && <Alert variant="danger">{chunksError.message}</Alert>}
        {!loadingChunks && chunksJson && (
          <div
            ref={chunksContainerRef}
            style={{
              width: "100%",
              height: window.innerHeight * (showChunks ? 0.35 : 0.0), // Hide Chunks div by default with the height parameter
              overflowY: "scroll",
            }}
          >
            {chunksJson.map((chunk, i) => (
              <div key={i}>
                {/* Logic to print out section_title headers (when they change from previous chunk) to group chunks display */}
                {(i === 0 ||
                  (i > 0 &&
                    chunksJson[i - 1]?.metadata?.section_title !==
                      chunk?.metadata?.section_title)) && (
                  <div>
                    <hr />
                    <h3
                      title={`Click to scroll to this ${
                        filename.toLowerCase().endsWith(".pdf")
                          ? "page"
                          : "section"
                      }`}
                      style={{ cursor: "pointer", color: "var(--primary)" }}
                      onClick={() => {
                        if (filename.toLowerCase().endsWith(".pdf")) {
                          if (chunk?.metadata?.page_number) {
                            setBlobPage(chunk?.metadata?.page_number);
                          }
                        } else if (filename.toLowerCase().endsWith(".md")) {
                          if (chunk?.metadata?.section_title) {
                            const element =
                              markdownAreaRef?.current?.querySelectorAll(
                                "h1, h2, h3, h4, h5, h6"
                              );
                            const matchElement = Array.from(element).find(
                              (el) =>
                                chunk?.metadata?.section_title
                                  ?.trim()
                                  .startsWith(el.innerText?.trim())
                            );
                            if (matchElement) {
                              // scroll markdownAreaRef to matchElement
                              matchElement.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest",
                              });
                            }
                          }
                        }
                      }}
                    >
                      {chunk?.metadata?.section_title}
                    </h3>
                  </div>
                )}
                <div
                  ref={(element) => {
                    if (
                      element &&
                      chunk?.metadata?.section_title &&
                      !chunkPagesRef.current[
                        `${chunk?.metadata?.page_number ?? 1}_${
                          chunk?.metadata?.section_title
                        }`
                      ]
                    ) {
                      chunkPagesRef.current[
                        `${chunk?.metadata?.page_number ?? 1}_${
                          chunk?.metadata?.section_title
                        }`
                      ] = element;
                    }
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: "#f5f5f5",
                      marginBottom: "2px",
                    }}
                  >
                    <h5
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (filename.toLowerCase().endsWith(".pdf")) {
                          if (chunk?.metadata?.page_number) {
                            setBlobPage(chunk?.metadata?.page_number);
                          }
                        } else if (filename.toLowerCase().endsWith(".md")) {
                          if (chunk?.metadata?.section_title) {
                            const element =
                              markdownAreaRef?.current?.querySelectorAll(
                                "h1, h2, h3, h4, h5, h6"
                              );
                            const matchElement = Array.from(element).find(
                              (el) =>
                                chunk?.metadata?.section_title
                                  ?.trim()
                                  .startsWith(el.innerText?.trim())
                            );
                            if (matchElement) {
                              // scroll markdownAreaRef to matchElement
                              matchElement.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest",
                              });
                            }
                          }
                        }
                      }}
                    >
                      {chunk?.metadata?.page_number
                        ? `Page # ${chunk?.metadata?.page_number} - `
                        : ""}
                      Chunk {i + 1}
                    </h5>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        chunksJson[i].showFull = !chunksJson[i].showFull;
                        setChunksJson([...chunksJson]);
                      }}
                    >
                      {chunk?.showFull ? "▼ " : "▶ "}
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: chunk?.contents?.replaceAll("\n", "<br />"),
                      }}
                    />
                    <pre
                      id={`chunk_${i}_full_json`}
                      style={{
                        display: chunk?.showFull ? "block" : "none",
                        margin: "10px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {JSON.stringify(chunk, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          disabled={loadingChunks}
          onClick={() => {
            setShowChunks(!showChunks);
            if (!showChunks) {
              if (chunkPagesRef?.current?.[`${filePage ?? 1}_${fileSection}`]) {
                scrollToChunk(filePage, fileSection);
              }
            }
          }}
        >
          {showChunks && "Hide Details"}
          {!showChunks && "Show Details"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setShowFileModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
