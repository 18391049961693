/**
 * Vendors: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr(2021)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { Input } from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import copy from "clipboard-copy";

import { Snackbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const objecttype = "vendors";

/**
 * vendors: An object that contains information about a vendor.
 *
 * @constructor
 */
const vendors = {
  objecttype: objecttype,
  title: "Vendors",
  singular: "Vendor",
  nameField: "name",
  initObject: {},
  actions: [],
  privileges: {
    //Hardcoded
    create: ["matchaadmin"],
    copy: ["matchaadmin"],
    update: ["matchaadmin", "vendoradmin"],
    delete: ["matchaadmin"],
  },
  initialFilter: {
    sortOrder: "asc", // asc||desc
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: ["id", "name", "vendor_guid", "created_at"],
  GetForm: GetForm,
  saveObject: saveObject,
  getValidateForm: getValidateForm,
};

/**
 * Defines the vendor object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(_props) {
  return [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
  ];
}

/**
 * Creates the form to used to edit the vendor object
 * @constructor
 * @returns the form to be edited
 */
function GetForm({
  id, //unused for now but added in getform call
  formikProps, //unused for now but added in getform call
}) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [showFeedback, setShowFeedback] = useState(false);

  /**
   * Copy Selected vendor GUID to clipboard
   * @param {*} e - event from onClick
   */
  const copyVendorGUID = (e) => {
    e.preventDefault();

    let vendor_guid = formikProps.values.vendor_guid;
    copy(vendor_guid).then(() => {
      setShowFeedback(true);
    });

    e.stopPropagation();
  };

  return (
    <Form className="form form-label-right">
      <div className="form-group row">
        {/* Vendor Name */}
        <div className="col-lg-4">
          <Field
            name="name"
            className={`form-control ${formikProps?.errors?.name ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Vendor Name"
            label="Vendor Name"
            disabled={
              vendors.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Description */}
        <div className="col-lg-4">
          <Field
            name="description"
            className={`form-control ${formikProps?.errors?.description ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Description"
            label="Description"
            disabled={
              vendors.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Email */}
        <div className="col-lg-4">
          <Field
            name="email"
            className={`form-control ${formikProps?.errors?.email ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Contact E-mail"
            label="Contact E-mail"
            disabled={
              vendors.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Phone */}
        <div className="col-lg-4">
          <Field
            name="phonenumber"
            className={`form-control ${formikProps?.errors?.phonenumber ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Contact Phone"
            label="Contact Phone"
            disabled={
              vendors.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Vendor GUID */}
        {vendors.privileges.update.indexOf(user.role) > -1 ? (
          <div className="col-lg-4">
            <label htmlFor="vendor_guid">
              Vendor GUID
              {/* Only show copy button if there is something to copy */}
              {formikProps.values.vendor_guid ? (
                <span
                  className="inline-copy"
                  title="Copy Vendor GUID"
                  onClick={copyVendorGUID}
                />
              ) : null}
            </label>
            <Field
              name="vendor_guid"
              className={`form-control ${formikProps?.errors?.vendor_guid ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Vendor GUID generates automatically"
              disabled={true}
            />
          </div>
        ) : null}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
        message={<span id="message-id">Vendor GUID copied to clipboard!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Form>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Vendor name is required"),
      description: Yup.string()
        .min(3, "Minimum 3 symbols")
        //.max(50, "Maximum 50 symbols")
        .required("Description is required"),
      email: Yup.string()
        .min(3, "Minimum 3 symbols")
        .matches(
          /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$/,
          "Incorrect email format"
        )
        .required("Email is required"),
      phonenumber: Yup.string()
        .min(3, "Minimum 3 symbols")
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          "Incorrect Phone Number Format"
        ),
    });
  };
}

export default vendors;
