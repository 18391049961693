/**
 * Prompts: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr (2024)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { getBreadcrumb } from "../../partials/getBreadcrumb";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

import {
  Input,
  TextAreaInput,
} from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import { Modal } from "react-bootstrap";

import SelectField from "../../../partials/SelectField";

// import { objectsSlice } from "../../../_redux/objects/objectsSlice";
// const { actions: slice } = objectsSlice; //necessary for some reason

const objecttype = "prompts";

/**
 * prompts: An object that contains information about a prompt.
 *
 * @constructor
 */
const prompts = {
  objecttype: objecttype,
  title: getDynamicTitle,
  singular: "Prompt",
  nameField: "title",
  initObject: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlMissionId = parseInt(urlParams.get("mission_id"));
    const client_id = parseInt(urlParams.get("client_id"));
    const vendor_id = parseInt(urlParams.get("vendor_id"));
    return {
      prompt_type: "multiple-choice",
      client_id: client_id,
      vendor_id: vendor_id,
      mission_id: urlMissionId,
    };
  },
  actions: [],
  privileges: {
    //Hardcoded
    create: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    copy: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    update: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    delete: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
  },
  initialFilter: {
    selectFields: [
      "*",
      "vendor:vendors(id,name,vendor_guid)",
      "client:clients(id,name,client_guid)",
      "mission:missions(id,name)",
      "mission_filter:missions(id,name)", // TODO: Should ObjectsCrud be smart enought to automatically add the _filter version if the embedded table.column is used as a filter?
    ],
    sortOrder: "asc", // asc||desc
    sortField: "title",
    pageNumber: 1,
    pageSize: 100,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [
    "id",
    { name: "title", newSuffix: " (copy #)" },
    "created_at",
    "created_by",
    "updated_at",
    "updated_by",
  ],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: saveObject,
  getValidateForm: getValidateForm,
};

/**
 * getDynamicTitle: Gets the title for the prompt object
 * @returns JSX with Breadcrumb link, and ReactSelect to allow Mission change within Prompts screen
 */
function getDynamicTitle() {
  return getBreadcrumb("prompts");
}

/**
 * Defines the prompt object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(props) {
  return [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: PromptTitleColumnFormatter,
      formatExtraData: {
        navigate: props.navigate,
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "prompt_type",
      text: "Prompt Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "in(data.prompt_type)",
    },
  ];
}

/**
 * Formatter for the PromptTitleColumn, to make the prompt title
 * @param {*} _cellContent unused in this formatter
 * @param {*} row information about the row being formatted
 * @param {*} _rowIndex unused in this formatter
 * @param {*} props additional props to pass to the formatter, passed in via getColumn
 * @returns a clickable link in place of the usual name text
 */
function PromptTitleColumnFormatter(
  _cellContent,
  row,
  _rowIndex,
  { navigate }
) {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className={`svg-icon svg-icon-md mr-1 svg-icon-primary`}>
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/SingleArrow.svg")} />
        </span>
        {row.title}
      </div>
    </>
  );
}

/**
 * Creates the form to used to edit the prompt object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  id,
  formikProps,
  setInitialValues,
  copyMode,
  copiedObjectID,
}) {
  const { user, lookups, stateEnums } = useSelector(
    (state) => ({
      user: state.auth.user,
      lookups: state.objects.lookups,
      stateEnums: state.objects.enumTypes,
    }),
    shallowEqual
  );

  const [showAdvancedPanel, setShowAdvancedPanel] = useState(false);

  return (
    <Form className="form form-label-right">
      <div className="form-group row" style={{ display: "none" }}>
        {/* Client Id */}
        {lookups?.["clients"]?.length > 0 && (
          <div className="col-lg-4">
            <label>Client</label>
            <Field
              component={SelectField}
              id="prompt_client_id"
              name="client_id"
              isDisabled={
                prompts.privileges.create.indexOf(user.role) > -1 ? false : true
              }
              options={lookups["clients"]?.reduce((prev, curr) => {
                prev.push({ value: curr.id, label: curr.name });
                return prev;
              }, [])}
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          </div>
        )}
      </div>
      <div className="form-group row">
        {/* Mission */}
        <div className="col-lg-4">
          <label>Mission</label>
          <Field
            component={SelectField}
            id="mission_id"
            name="mission_id"
            isDisabled={true}
            options={lookups["missions"]?.reduce((prev, curr) => {
              prev.push({ value: curr.id, label: curr.name });
              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          ></Field>
        </div>
        {/* Prompt Title */}
        <div className="col-lg-4">
          <Field
            name="title"
            className={`form-control ${formikProps?.errors?.title ? "is-invalid" : ""}`}
            component={Input}
            placeholder="title"
            label="Title"
          />
        </div>
        {/* Prompt Type */}
        <div className="col-lg-4">
          <label>Type</label>
          <Field
            component={SelectField}
            id="prompt_type"
            name="prompt_type"
            isDisabled={
              prompts.privileges.create.indexOf(user.role) > -1 ? false : true
            }
            options={stateEnums["data.prompt_type"].reduce((prev, curr) => {
              prev.push({ value: curr, label: curr });
              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          ></Field>
        </div>
      </div>
      <div className="form-group row">
        {/* Prompt Prompt */}
        <div className="col-lg-6">
          <Field
            name="prompt"
            rows="8"
            className={`form-control ${formikProps?.errors?.prompt ? "is-invalid" : ""}`}
            component={TextAreaInput}
            placeholder="What is the question or prompt you want to ask about each document?"
            label="Question/Prompt"
          />
        </div>
        {/* Prompt Vector Match Text */}
        <div className="col-lg-6">
          <Field
            name="vectormatchtext"
            rows="8"
            className={`form-control ${formikProps?.errors?.vectormatchtext ? "is-invalid" : ""}`}
            component={TextAreaInput}
            placeholder="Help Matcha AI understand the information you're looking for by providing an example of similar text"
            label="Example Text to Match"
          />
        </div>
      </div>
      {user.role !== "webuser" ? (
        <div className="form-group row">
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setShowAdvancedPanel(!showAdvancedPanel);
            }}
          >
            {showAdvancedPanel ? "▼ " : "▶ "} <strong>Advanced Options</strong>
          </span>
        </div>
      ) : null}
      {showAdvancedPanel && (
        <div className="form-group row">
          {/* Prompt SysPrompt */}
          <div className="col-lg-6">
            <Field
              name="sysprompt"
              rows="8"
              className={`form-control ${formikProps?.errors?.sysprompt ? "is-invalid" : ""}`}
              component={TextAreaInput}
              placeholder="Add any additional instructions beyond the missions' instructions"
              label="System Prompt"
            />
          </div>
          {/* Prompt Precontext */}
          <div className="col-lg-6">
            <Field
              name="precontext"
              rows="8"
              className={`form-control ${formikProps?.errors?.preccontext ? "is-invalid" : ""}`}
              component={TextAreaInput}
              placeholder="Add any additional context that may help the AI understand the prompt"
              label="Pre-context"
            />
          </div>
        </div>
      )}
    </Form>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const title_text = title;
  const title_style = { display: "inline-block" };

  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text}
      </Modal.Title>
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      title: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(150, "Maximum 150 symbols")
        .required("Prompt title is required"),
      mission_id: Yup.string().required("Mission is required"),
    });
  };
}

export default prompts;
