// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";

// Separate "Action" icons to left side, and "Edit" (standard) icons to right side of ObjectsTable (ActionsColumnFormatter), using actionsGroup prop.
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    refreshCounter,
    setRefreshCounter,
    openEditObjectDialog,
    openCopyObjectDialog,
    openDeleteObjectDialog,
    objectsDict,
    objecttype,
    role,
    navigate,
    location,
    dispatch,
    missionPromptCount,
  }
) {
  let has_admin_buttons = false;
  if (
    objectsDict[objecttype].actions &&
    objectsDict[objecttype].actions.length > 0
  ) {
    has_admin_buttons = objectsDict[objecttype].actions.reduce(
      (x, y) => x || y.displayable(row, role) === "admin",
      false
    );
  }

  return (
    <>
      {(() => {
        if (
          objectsDict[objecttype].actions &&
          objectsDict[objecttype].actions.length > 0
        ) {
          return objectsDict[objecttype].actions.map((elem) => {
            if (elem.displayable() === "before") {
              return (
                <a
                  data-tip
                  data-for={`overridePosition${row.id}`}
                  key={`${elem.title}${row.id}`}
                  title={elem.title}
                  style={{ marginRight: "2px" }}
                  className={`btn btn-icon btn-light btn-hover-${elem.color} btn-sm`}
                  onClick={() =>
                    elem.onClick(row, navigate, location, dispatch)
                  }
                >
                  <span
                    className={`svg-icon svg-icon-xl svg-icon-${elem.color}`}
                  >
                    <SVG src={toAbsoluteUrl(elem.icon)} />
                  </span>
                </a>
              );
            } else {
              return "";
            }
          });
        } else {
          return <></>;
        }
      })()}

      {/* EDIT BUTTON */}
      {objecttype === "files" && (
        <button
          title={
            row?.status !== "analyzed" && row?.status !== "analyzing"
              ? "You must Analyze this Folder before viewing the File Report"
              : "View File Report"
          }
          style={{ marginRight: "2px" }}
          disabled={
            row?.status !== "analyzed" &&
            row?.status !== "analyzing" &&
            row?.status !== "error"
          }
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openEditObjectDialog(objecttype, row.id)}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
          </span>
        </button>
      )}
      {objecttype !== "files" && objecttype !== "missions" && (
        <a
          title={`Edit ${objectsDict[objecttype].singular}`}
          style={{ marginRight: "2px" }}
          className={`btn btn-icon btn-light btn-hover-primary btn-sm`}
          onClick={() => openEditObjectDialog(objecttype, row.id)}
        >
          <span className={`svg-icon svg-icon-xl svg-icon-primary`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      )}

      {/* COPY BUTTON */}
      {objectsDict[objecttype].privileges.copy.indexOf(role) > -1 ? (
        <a
          title={`Copy ${objectsDict[objecttype].singular}`}
          style={{ marginRight: "2px" }}
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openCopyObjectDialog(objecttype, row.id)}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")}
            />
          </span>
        </a>
      ) : (
        ""
      )}

      {/* DELETE BUTTON */}
      {objectsDict[objecttype].privileges.delete.indexOf(role) > -1 &&
        (row?.ispublished ? ( //disable button and add tooltip if objecttype = movietemplate and ispublished = true
          <OverlayTrigger
            overlay={<Tooltip> Cannot delete published VXTemplates </Tooltip>}
          >
            <span
              style={{ marginRight: "2px" }}
              className="btn btn-icon btn-light btn-sm svg-icon svg-icon-xl svg-icon-danger disabled"
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </OverlayTrigger>
        ) : (
          <a
            title={`Delete ${objectsDict[objecttype].singular}`}
            style={{ marginRight: "2px" }}
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openDeleteObjectDialog(objecttype, row.id)}
          >
            <span className="svg-icon svg-icon-xl svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </a>
        ))}

      {(() => {
        if (
          objectsDict[objecttype].actions &&
          objectsDict[objecttype].actions.length > 0
        ) {
          return objectsDict[objecttype].actions.map((elem) => {
            if (elem.displayable() === "after") {
              return (
                <a
                  data-tip
                  data-for={`overridePosition${row.id}`}
                  key={`${elem.title}${row.id}`}
                  title={
                    elem?.objecttype === "files" ? "File Controls" : elem.title
                  }
                  style={{ marginRight: "2px" }}
                  className={`btn btn-icon btn-light btn-hover-${elem.color} btn-sm`}
                  onClick={() =>
                    elem.onClick(row, navigate, location, dispatch)
                  }
                >
                  <span
                    className={`svg-icon svg-icon-xl svg-icon-${elem.color}`}
                  >
                    <SVG src={toAbsoluteUrl(elem.icon)} />
                  </span>
                </a>
              );
            } else {
              return "";
            }
          });
        } else {
          return <></>;
        }
      })()}

      {(() => {
        if (has_admin_buttons) {
          return <span className="admin-action-separator" />;
        } else {
          return <></>;
        }
      })()}

      {(() => {
        if (
          objectsDict[objecttype].actions &&
          objectsDict[objecttype].actions.length > 0
        ) {
          return objectsDict[objecttype].actions.map((elem) => {
            if (elem.displayable() === "admin") {
              /* 
                Treating disabled actions a little differently. Easier to style with hardcoded
                   classes rather than passing row/role into each object action.
                Using button instead of link because disabled links can't have tooltips.
              */
              if ("disabled" in elem && elem.disabled(row, role)) {
                return (
                  <button
                    data-tip
                    data-for={`overridePosition${row.id}`}
                    type="button"
                    key={`${elem.title}${row.id}`}
                    title={
                      "disabledTooltip" in elem && elem.disabledTooltip
                        ? elem.disabledTooltip
                        : elem.title
                    }
                    style={{ marginRight: "2px", cursor: "default" }}
                    className={`btn btn-icon btn-light btn-hover-disabled btn-sm disabled`}
                    disabled={true}
                  >
                    <span className={`svg-icon svg-icon-xl svg-icon-disabled`}>
                      <SVG src={toAbsoluteUrl(elem.icon)} />
                    </span>
                  </button>
                );
              } else {
                return (
                  <a
                    data-tip
                    data-for={`overridePosition${row.id}`}
                    key={`${elem.title}${row.id}`}
                    title={elem.title}
                    style={{ marginRight: "2px" }}
                    className={`btn btn-icon btn-light btn-hover-${elem.color} btn-sm`}
                    onClick={() =>
                      elem.onClick(row, navigate, location, dispatch)
                    }
                  >
                    <span
                      className={`svg-icon svg-icon-xl svg-icon-${elem.color}`}
                    >
                      <SVG src={toAbsoluteUrl(elem.icon)} />
                    </span>
                  </a>
                );
              }
            } else {
              return "";
            }
          });
        } else {
          return <></>;
        }
      })()}
      {/* Files Analyze BUTTON: disabled if mission has no prompts!*/}
      {objecttype === "files" && (
        <a
          title={
            missionPromptCount <= 0
              ? "Add some mission prompts to analyze first!"
              : "Analyze File"
          }
          style={{ marginRight: "2px" }}
          disabled={missionPromptCount <= 0}
          className={`btn btn-icon btn-light btn-hover-${missionPromptCount > 0 ? "warning" : "secondary"} btn-sm`}
          onClick={() => {
            // axios call to analyze all files in folder
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/rpc/analyze_file`,
                {
                  p_clientid: row.client_id,
                  p_fileid: row.id,
                  p_reanalyze: true,
                },
                { headers: { "Content-Type": "application/json" } }
              )
              .then((resp) => {
                // console.log("Analysis Started response: ", resp);
                setRefreshCounter(refreshCounter + 1);
              });
          }}
        >
          <span
            className={`svg-icon svg-icon-xl svg-icon-${missionPromptCount > 0 ? "warning" : "secondary"}`}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}
            />
          </span>
        </a>
      )}
    </>
  );
}
