Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.getByCurrPage = exports.alignPage = undefined;

var _const = require("./const");

var _const2 = _interopRequireDefault(_const);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var getNormalizedPage = function getNormalizedPage(page, pageStartIndex) {
  var offset = Math.abs(1 - pageStartIndex);
  return page + offset;
};

var endIndex = function endIndex(page, sizePerPage, pageStartIndex) {
  return getNormalizedPage(page, pageStartIndex) * sizePerPage - 1;
};

var startIndex = function startIndex(end, sizePerPage) {
  return end - (sizePerPage - 1);
};

// eslint-disable-next-line no-unused-vars
var alignPage = (exports.alignPage = function alignPage(
  dataSize,
  prevDataSize,
  page,
  sizePerPage,
  pageStartIndex
) {
  if (prevDataSize < dataSize) return page;
  if (page < pageStartIndex) return pageStartIndex;
  if (dataSize <= 0) return pageStartIndex;
  if (
    page >= Math.floor(dataSize / sizePerPage) + pageStartIndex &&
    pageStartIndex === 1
  ) {
    return Math.ceil(dataSize / sizePerPage);
  }
  if (page >= Math.floor(dataSize / sizePerPage) && pageStartIndex === 0) {
    var newPage = Math.ceil(dataSize / sizePerPage);
    return (
      newPage - Math.abs(_const2.default.PAGE_START_INDEX - pageStartIndex)
    );
  }
  return page;
});

// eslint-disable-next-line no-unused-vars
var getByCurrPage = (exports.getByCurrPage = function getByCurrPage(
  data,
  page,
  sizePerPage,
  pageStartIndex
) {
  var dataSize = data.length;
  if (!dataSize) return [];

  var end = endIndex(page, sizePerPage, pageStartIndex);
  var start = startIndex(end, sizePerPage);

  var result = [];
  for (var i = start; i <= end; i += 1) {
    result.push(data[i]);
    if (i + 1 === dataSize) break;
  }
  return result;
});
