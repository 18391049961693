// This React Modal component will use axios to retrieve the file from the server and display it in a modal window.
import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextAreaInput } from "../../../../_metronic/_partials/controls";
import { saveAnalysisCorrection } from "../_redux/util/correctionActions";

/**
 * FileDisplayModal component to display a file in a modal window with Vector DB chunking details below
 * @param {number} props.fileId - the file ID to display (fetch base64 binary contents from server)
 * @param {number} props.filePage - the page number to display in the file (works for PDF with #page= query string)
 * @param {function} props.setFilePage - the function to set the page number in parent component state
 * @param {boolean} props.showFileModal - the state variable to show/hide the modal
 * @param {function} props.setShowFileModal - the function to set the show/hide state in parent component state
 * @returns JSX
 */
export default function AnalysisCorrectionModal({
  showAnalysisModal,
  setShowAnalysisModal,
  analysisJson,
  analysisJsonKey,
  fileId,
  fileName,
  addAnalysisCorrection,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlFolderName = urlParams.get("folder_name") ?? "Global Knowledge";

  let initialValues = {
    prompttext: analysisJson?.["llm_response"]?.["question"],
    currentanswer: analysisJson?.["llm_response"]?.["answer"],
    currentdetails: analysisJson?.["llm_response"]?.["supporting details"],
    correctanswer: analysisJson?.["correction"]?.["answer"] ?? "",
    correctiondetail:
      analysisJson?.["correction"]?.["supporting details"] ?? "",
    filename: fileName,
    foldername: urlFolderName,
  };

  let objectSchema = function () {
    return Yup.object().shape({
      correctanswer: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(3000, "Maximum 3000 symbols")
        .required("Correct Answer is required"),
    });
  };

  // refresh the page when the modal is first shown
  useEffect(() => {}, [showAnalysisModal]);

  // callback to save the correction.
  const saveCorrection = (file_id, correctedanswer, correcteddetails) => {
    saveAnalysisCorrection(
      file_id,
      analysisJsonKey,
      analysisJson,
      correctedanswer,
      correcteddetails
    ).then(() => {
      // call the parent function to add the correction to the analysis
      if (typeof addAnalysisCorrection === "function") {
        addAnalysisCorrection(
          analysisJsonKey,
          correctedanswer,
          correcteddetails
        );
      }
      // closing delete modal
      setShowAnalysisModal(false);
    });
  };

  return (
    <>
      {showAnalysisModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={objectSchema}
          onSubmit={(values, helpers) => {
            saveCorrection(
              fileId,
              values["correctanswer"],
              values["correctiondetail"]
            );
          }}
        >
          {(formikProps) => {
            return (
              <Modal
                show={showAnalysisModal}
                size="xl"
                onHide={() => setShowAnalysisModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>
                    {analysisJson?.["correction"]?.["answer"] ? "Edit" : "Add"}{" "}
                    Correction for <b>{analysisJsonKey}</b>
                  </Modal.Title>

                  <button
                    id={`close_modal_btn`}
                    title="Close"
                    style={{ marginRight: "2px" }}
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    onClick={() => setShowAnalysisModal(false)}
                  >
                    <span
                      id={`modal_close_svg_icon`}
                      className="svg-icon svg-icon-md svg-icon-secondary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Close.svg"
                        )}
                      />
                    </span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <Form className="form form-label-right">
                    <div className="form-group">
                      <div className="row mb-2">
                        <div className="col-lg-6">
                          <Field
                            name="filename"
                            label="File Name"
                            component={Input}
                            disabled={true}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                          />
                        </div>
                        <div className="col-lg-6">
                          <Field
                            name="foldername"
                            label="Folder Name"
                            component={Input}
                            disabled={true}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <Field
                            name="prompttext"
                            label="Prompt"
                            component={Input}
                            disabled={true}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row mb-2">
                        <div className="col-lg-6">
                          <Field
                            name="currentanswer"
                            label="Current Answer"
                            component={Input}
                            disabled={true}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                          />
                        </div>
                        <div className="col-lg-6">
                          <Field
                            name="correctanswer"
                            label="Corrected Answer"
                            component={Input}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Field
                            name="currentdetails"
                            label="Current Explanation"
                            component={TextAreaInput}
                            disabled={true}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                            rows="6"
                          />
                        </div>
                        <div className="col-lg-6">
                          <Field
                            name="correctiondetail"
                            label="Explanation"
                            component={TextAreaInput}
                            className={`form-control ${formikProps?.errors?.filename ? "is-invalid" : ""}`}
                            rows="6"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      formikProps.handleSubmit();
                    }}
                  >
                    Save
                  </button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowAnalysisModal(false);
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export function CorrectionDeleteModal({
  show,
  onHide,
  analysisJson,
  analysisJsonKey,
  fileId,
  addAnalysisCorrection,
}) {
  useEffect(() => {}, [show]);

  const deleteObject = () => {
    saveAnalysisCorrection(fileId, analysisJsonKey, analysisJson, null, null)
      .then(() => {
        addAnalysisCorrection(analysisJsonKey, null, null);
        // closing delete modal
        onHide();
      })
      .catch(function (error) {
        console.log(error);
        alert("Error deleting correction");
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Delete Correction for {analysisJsonKey}?
        </Modal.Title>
        <button
          id={`close_modal_btn`}
          title="Close"
          style={{ marginRight: "2px" }}
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={onHide}
        >
          <span
            id={`modal_close_svg_icon`}
            className="svg-icon svg-icon-md svg-icon-secondary"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <>
            <p>Are you sure you want to permanently delete this Correction?</p>
            <p>
              <b>Prompt:</b> {analysisJsonKey}
            </p>
            <p>
              <b>Corrected Answer:</b>{" "}
              {analysisJson?.["correction"]?.["answer"] || ""}
            </p>
            <p>
              <b>Supporting Detail:</b>{" "}
              {(analysisJson?.["correction"]?.["supporting details"] || "")
                .trim()
                .substring(0, 30)}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={deleteObject}
          className="btn btn-primary btn-elevate"
        >
          Delete Correction
        </button>
      </Modal.Footer>
    </Modal>
  );
}
