/**
 * objectsSlice: A list of states (and methods related to them) relating to server requests.
 *
 * TODO: Add better comments
 * @author: Tyler Carr(), Xavier Clark(Nov 2021)
 */
import { createSlice } from "@reduxjs/toolkit";

const initialObjectsState = {
  listLoading: false,
  actionsLoading: false,
  saveLoading: false, //for submitting/updating/deleting an object
  hasError: false,
  hideDialog: "", //for signalling that objectEditForm should be hidden (ie call an onhide function, whatever that might be)
  totalCount: 0,
  unfilteredTotalCount: null,
  wasQueue: true,
  entities: [],
  objectForEdit: {},
  enumTypes: {},
  lookups: {},
  lookupStatus: {},
  lastError: null,
  clientMessage: null,
  schema: null,
};

export const objectsSlice = createSlice({
  name: "objects",
  initialState: initialObjectsState,
  reducers: {
    /**
     * Reset state to initial values
     * @param {*} state
     * @param {*} _action
     */
    resetState: (_state, _action) => initialObjectsState,

    /**
     * catchError*: Catch an error from a specific type of server request.
     * Push what the error is, set "hasError" to true, the *Loading to false (because it no longer is loading)
     * TODO: probably could be made refactored since they all use basically the same code
     *
     * @param {*} state current objectsSlice Redux object's state (originally initialized from initialObjectsState above)
     * @param {*} action The action (with payload) that was being sent to the server / what was sent back.
     */
    /** Catch error for each type  */
    catchErrorSave: (state, action) => {
      state.hasError = true;
      state.lastError = `${
        action?.payload?.error?.response?.data?.message
          ? action.payload.error.response.data.message
          : ""
      }${
        action?.payload?.error?.response?.data?.message &&
        action?.payload?.error?.response?.data?.details
          ? " - "
          : ""
      }${
        action?.payload?.error?.response?.data?.details
          ? action.payload.error.response.data.details
          : ""
      }`;
      state.clientMessage = action.payload.error.clientMessage;
      state.saveLoading = false;
    },
    catchErrorList: (state, action) => {
      state.hasError = true;
      state.lastError = `${
        action?.payload?.error?.response?.data?.message
          ? action?.payload?.error?.response?.data?.message
          : ""
      }${
        action?.payload?.error?.response?.data?.message &&
        action?.payload?.error?.response?.data?.details
          ? " - "
          : ""
      }${
        action?.payload?.error?.response?.data?.details
          ? action?.payload?.error?.response?.data?.details
          : ""
      }`;
      state.clientMessage = action?.payload?.error?.clientMessage;
      state.listLoading = false;
    },
    catchErrorAction: (state, action) => {
      state.hasError = true;
      state.lastError = `${
        action?.payload?.error?.response?.data?.message
          ? action.payload.error.response.data.message
          : ""
      }${
        action?.payload?.error?.response?.data?.message &&
        action?.payload?.error?.response?.data?.details
          ? " - "
          : ""
      }${
        action?.payload?.error?.response?.data?.details
          ? action.payload.error.response.data.details
          : ""
      }`;
      state.clientMessage = action.payload.error.clientMessage;
      state.actionsLoading = false;
    },

    /**
     * resetErrors: Reset all states related to an error.
     * Usually happens when user clicks "okay" on an error message.
     * @param {*} state  current objectsSlice Redux object's state (originally initialized from initialObjectsState above)
     */
    resetErrors: (state, _action) => {
      state.clientMessage = null;
      state.hasError = false;
      state.lastError = null;
      state.error = null;
      state.hideDialog = "";
    },

    /**
     * After you hide a dialog, this should be called to reset the hideDialog flag
     * @param {*} state current objectsSlice Redux object's state (originally initialized from initialObjectsState above)
     */
    resetHideDialog: (state, _action) => {
      state.hideDialog = "";
    },

    // loadPostgRESTapi
    setRestSchema: (state, action) => {
      state.schema = action.payload.schema;
      state.enumTypes = {};
      //digest schema to store enum type dict
      for (const defkey in state.schema.definitions) {
        const def = state.schema.definitions[defkey];
        if (def.properties) {
          for (const propkey in def.properties) {
            const prop = def.properties[propkey];
            if (prop.enum) {
              state.enumTypes[prop.format] = prop.enum;
            }
          }
        }
      }
    },

    /**
     * startCall*: Runs whenever a dispatch for a certain server call happens.
     * Either you begin to save an object, begin to load a page, or begin to load a list of objects
     * sets the current errors to nothing and the appropriate loading attribute to "true"
     * @param {*} state  current objectsSlice Redux object's state (originally initialized from initialObjectsState above)
     */
    startCallSave: (state, _action) => {
      state.error = null;
      state.saveLoading = true;
    },
    startCallList: (state, _action) => {
      state.error = null;
      state.listLoading = true;
    },
    startCallAction: (state, _action) => {
      state.error = null;
      state.actionsLoading = true;
    },

    /**
     * endCall*: Only runs when manually called.
     * You decide to manually signal the end of a certain call - usually used when making a call that doesn't have a standard return.
     *  See Clients.js for an example
     * sets the current errors to nothing and the appropriate loading attribute to "false"
     * @param {*} state  current objectsSlice Redux object's state (originally initialized from initialObjectsState above)
     */
    endCallSave: (state, action) => {
      state.error = null;
      state.saveLoading = false;
      state.hideDialog = action.payload.objecttype;
    },
    endCallList: (state, _action) => {
      state.error = null;
      state.listLoading = false;
    },
    endCallAction: (state, _action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // getObjectById
    objectFetched: (state, action) => {
      state.actionsLoading = false;
      state.objectForEdit = action.payload.objectForEdit;
      state.error = null;
    },
    // findObjects
    objectsFetched: (state, action) => {
      const { totalCount, entities, isFiltered } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      if (!isFiltered) {
        // if no filter, set unfilteredTotalCount
        state.unfilteredTotalCount = totalCount;
      }
    },
    // expireLookup
    expireLookup: (state, action) => {
      const { objecttype } = action.payload;
      if (state.lookups && state.lookups[objecttype]) {
        delete state.lookups[objecttype];
      }
    },
    // Keep track of whether or not you are on the "queue" page of vxVids
    // so you can reset data on the formik form when changed
    setWasQueue: (state, action) => {
      state.wasQueue = action.payload.isQueue;
    },
    /**
     * expireAllLookups:
     * reset all lookups to {}, forcing a reload for all lookups
     */
    expireAllLookups: (state, _action) => {
      state.lookups = {};
    },
    // prefetchLookup
    lookupLoading: (state, action) => {
      const { objecttype } = action.payload;
      state.lookups[objecttype] = [];
    },
    // fetchLookup
    lookupFetched: (state, action) => {
      const { objecttype, entities } = action.payload;
      state.lookups[objecttype] = entities;
    },
    // createObject
    objectCreated: (state, action) => {
      state.saveLoading = false;
      state.error = null;
      state.hideDialog = action.payload.objecttype;
    },
    // pushEntities
    pushEntities: (state, action) => {
      state.entities.push(action.payload.object);
    },
    // clearEntities
    clearEntities: (state, action) => {
      state.entities = [];
    },
    // updateObject
    objectUpdated: (state, action) => {
      state.error = null;
      state.saveLoading = false;
      state.hideDialog = action.payload.objecttype;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.object.id) {
          return action.payload.object;
        }
        return entity;
      });
    },
    // deleteObject
    objectDeleted: (state, action) => {
      state.error = null;
      state.saveLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteObjects
    objectsDeleted: (state, action) => {
      state.error = null;
      state.saveLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
  },
});
