import React, { useMemo } from "react";
import objectPath from "object-path";
import { NavLink } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
// import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
// import { AnimateLoading } from "../../../_partials/controls";

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  let devsiteLabel = "";
  if (
    [
      "matcha-gui.ideazig.com",
      "matcha.harriscomputer.com",
      "localhost",
    ].indexOf(window.location.host.split(":")[0]) < 0
  ) {
    devsiteLabel = window.location.host.split(":")[0].split(".")[0];
  }

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        style={{ maxHeight: "65px" }}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Topbar*/}
        <Topbar />
        {/*end::Topbar*/}
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between aside-menu`}
          style={{
            maxHeight: "65px",
            position: "fixed",
            top: "0px",
            zIndex: 95,
          }}
        >
          <div className="d-flex align-items-stretch mr-3">
            <NavLink to="/matcha/objects/missions">
              <img
                alt="logo"
                style={{ height: "100%", padding: "5px" }}
                src={layoutProps.headerLogo}
              />
            </NavLink>
            {devsiteLabel && (
              <div
                style={{
                  color: "white",
                  position: "absolute",
                  top: "45px",
                  left: "120px",
                  textShadow: `#000 -1px -1px 0, #000 1px 1px 0, 
                #000 1px -1px 0, #000 -1px 1px 0, 
                #000 0 1px 0, #000 1px 0 0, 
                #000 -1px 0 0, #000 0 -1px 0,
                #000 -1.2px -1.2px 0, #000 1.2px 1.2px 0, 
                #000 1.2px -1.2px 0, #000 -1.2px 1.2px 0, 
                #000 0 1.2px 0, #000 1.2px 0 0, 
                #000 -1.2px 0 0, #000 0 -1.2px 0`,
                }}
              >
                <strong>{devsiteLabel}</strong>
              </div>
            )}
          </div>
          {/* <AnimateLoading /> */}
          {/*begin::Header Menu Wrapper*/}
          {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
          <div />
          {/*end::Header Menu Wrapper*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
