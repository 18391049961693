/**
 * SessionTimer - Keeps track of how long the user has been using the page without interacting,
 * pops up a modal warning user of being locked out if inactive for too long.
 *
 * Available time is based on time until JWT token expires
 * If user's location changes, resets the session timer back to full (resets the jwt token)
 *
 * @author Tyler Carr(2021), Xavier Clark(2022)
 */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { decodeJwt } from "jose";
import { useNavigate, useLocation } from "react-router-dom";
import { actions } from "../../../../app/modules/Auth/_redux/authRedux";
import { getUserByToken } from "../../../../app/modules/Auth/_redux/authCrud";
import { Modal } from "react-bootstrap";

export function SessionTimer() {
  const TIME_TO_SHOW_MODAL = 300; // time left in seconds (300 seconds means the SessionTimer Pop-up warning will appear when there 5 minutes left in the user's session)
  const TIME_TO_LOGOUT = 0; // time left in seconds

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //Determine token expiration timestamp seconds from JWT authToken
  const { auth } = useSelector((state) => ({ auth: state.auth }), shallowEqual);

  const [timeLeft, setTimeLeft] = useState(null);
  const [lastLocation, setLastLocation] = useState(
    `${location.pathname}${location.search}${location.hash}`
  );
  const [isFetching, setIsFetching] = useState(false);

  /**
   * Refreshes the JWT session token and resets showModal to hide the "click this" modal
   * Also sets "isFetching" to true, to stop multiple requests happening at once.
   */
  const refreshSessionToken = useCallback(() => {
    // setting showTimeoutModal is in authRedux.js

    if (!isFetching) {
      setIsFetching(true);
      getUserByToken()
        .then((data) => {
          const record = data.data;
          dispatch(actions.fulfillUser(record));
          setIsFetching(false);
        })
        .catch((error) => {
          console.error(error);
          navigate(`/logout`);
        });
    }
  }, [dispatch, isFetching, navigate]);

  // reset time if current page changes
  useEffect(() => {
    if (
      lastLocation !== `${location.pathname}${location.search}${location.hash}`
    ) {
      refreshSessionToken();
      setLastLocation(`${location.pathname}${location.search}${location.hash}`);
    }
  }, [lastLocation, location, timeLeft, refreshSessionToken]);

  // Create a counter to see how many seconds left until JWT token expires. When close to expiry, display modal to warn user.
  useEffect(() => {
    let myInterval = setInterval(() => {
      const secondsLeft =
        parseInt(decodeJwt(auth.authToken).exp) -
        parseInt(new Date().getTime() / 1000);

      if (secondsLeft <= TIME_TO_SHOW_MODAL) {
        // Show modal to warn user about logout
        dispatch(actions.setShowTimeoutModal(true));
      }

      if (secondsLeft <= TIME_TO_LOGOUT) {
        clearInterval(myInterval);
        setTimeLeft(0);
        navigate(`/logout`); //Redirect to logout screen
      } else {
        setTimeLeft(secondsLeft); //re-sync date with expiration
      }
    }, 1000);

    return () => {
      //Needed because when refreshSessionToken runs, auth changes, creating a new interval. Without the return both will run at once (old one won't end)
      clearInterval(myInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Modal show={auth.showTimeoutModal ? true : false} centered>
      <Modal.Header>
        <Modal.Title>
          {" "}
          Session Timeout - {Math.floor(timeLeft / 60)}:
          {("0" + (timeLeft % 60)).slice(-2)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="col-12 text-align-center">
          You are being timed out for inactivity. Please press "Continue" within
          the next
          <strong>
            {" "}
            {Math.floor(timeLeft / 60) === 0
              ? ""
              : Math.floor(timeLeft / 60) + " minutes and "}
            {timeLeft % 60} seconds{" "}
          </strong>
          to continue your session, or do nothing to be logged out.
        </h4>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <a
          onClick={() => navigate(`/logout`)}
          className="btn btn-danger"
          style={{ flex: 1 }}
        >
          Logout
        </a>
        <a
          onClick={refreshSessionToken}
          className="btn btn-primary"
          style={{ flex: 1 }}
          id="resetTimerButton"
        >
          Continue Working
        </a>
      </Modal.Footer>
    </Modal>
  );
}
