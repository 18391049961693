/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const available_embedding_modelsTablesMock = [
    {
        "id": 1,
        "name": "all-MiniLM-L6-v2",
        "model_name": "all-MiniLM-L6-v2",
        "model_dimensions": 384,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-17T13:17:32.109+00:00",
        "updated_by": null,
        "updated_at": null
    },
    {
        "id": 2,
        "name": "avsolatorio/GIST-small-Embedding-v0",
        "model_name": "avsolatorio/GIST-small-Embedding-v0",
        "model_dimensions": 384,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-17T18:24:16.102+00:00",
        "updated_by": null,
        "updated_at": null
    },
    {
        "id": 3,
        "name": "mixedbread-ai/mxbai-embed-large-v1",
        "model_name": "mixedbread-ai/mxbai-embed-large-v1",
        "model_dimensions": 1024,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-08-16T14:25:16.102+00:00",
        "updated_by": null,
        "updated_at": null
    }
];