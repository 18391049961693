/**
 * Formik wrapper for react-select control to enable the nice formik validations and state management and styling via one simple component
 *
 * Example code included in a formik form:
 *  <Field
 *    component={SelectField}
 *    name="client_id"
 *    options={
 *      lookups['clients']?.reduce((prev, curr) => {
 *        prev.push({ value: curr.id, label: curr.name })
 *        return prev;
 *      }, [])
 *    }
 *    isDisabled={users.privileges.create.indexOf(user.role) > -1 ? false : true}
 *    setInitialValues={(val) => {setInitialValues(val)}}
 *  >
 *  </Field>
 *
 *  -----
 *
 * additional onChange effects can be added by setting:
 *  onChangeSideEffect={(selectedValue) => { some actions }} //some function
 *
 * Can also add isMulti to the props to make the select allow multiple values
 *
 * @author Tyler Carr(2022)
 */

import React, { useEffect } from "react";
import Select from "react-select";
import { useField } from "formik";

export default function SelectField(props) {
  // const [field, state, { setTouched }] = useField(props.field.name);
  const fieldArray = useField(props.field.name);
  const state = fieldArray[1];
  const setTouched = fieldArray[2].setTouched;

  // value is an array now
  const onChange = (value) => {
    if (!props.isMulti) {
      //This is because regular selects require destinct values, not objects (but multis are big arrays, ie must be object)
      value = value?.value;
    }

    props.form.setFieldValue(props.field.name, value);
    if (props.onChangeSideEffect) {
      //extra onchange effects
      props.onChangeSideEffect(value);
    }
    if (props.autoSubmit) {
      props.form.handleSubmit();
    }
  };

  /**
   * if only one option, select it automatically
   *     if no previous value selected, don't dirty the form
   * if more than one option, don't select anything
   *     if something already selected (and selected value isn't in options), select null
   * if no options, don't select anything
   *     if something already selected, select null
   */
  useEffect(() => {
    // console.log(props.field.name, props.options, state.value)

    //is not multi
    if (
      props?.options?.length === 1 &&
      props?.options[0]?.value !== state?.value
    ) {
      // one option and not already selected
      if (!props?.form?.dirty && props?.setInitialValues && !state?.value) {
        // auto "select" single option unless flag forbids it
        if (
          !Object.hasOwn(props, "autoSelectSingleOption") ||
          (Object.hasOwn(props, "autoSelectSingleOption") &&
            props.autoSelectSingleOption)
        ) {
          //maintain not dirty (and no option selected)
          props.setInitialValues({
            ...props.form.initialValues,
            [props.field.name]: props.isMulti
              ? [props.options[0].value]
              : props.options[0].value,
          });
        }
      } else {
        //TODO: Triggers yup validation message incorrectly (not seeing the value this is setting)
        onChange(props?.isMulti ? [props.options[0]] : props.options[0]); // will dirty the form
      }
    } else if (
      (props?.options?.length > 1 || props?.options?.length === 0) &&
      state.value
    ) {
      // new select means new choices but more than one = no autopick
      let optionsValues = props.options.reduce((total, option) => {
        total.push(option.value);
        return total;
      }, []); // consolidate values into simple array
      if (!optionsValues.includes(state.value)) {
        // previous value isn't existing
        onChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  // use value to make this a  controlled component
  // now when the form receives a value for 'campfeatures' it will populate as expected
  return (
    <>
      <Select
        {...props}
        isDisabled={props.isDisabled || props?.options?.length === 0} //if no options, disable.
        className={
          "react-select-cursor " + (state.error ? "react-select-error" : "")
        }
        value={
          props?.isMulti && state?.value
            ? state.value
            : props.options && state?.value
            ? props.options.find((option) => option.value === state?.value)
            : ""
        }
        onChange={onChange}
        onBlur={setTouched}
      />
      <div className="text-danger">{state.error}</div>
    </>
  );
}
