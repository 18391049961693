import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

import { objectsSlice } from "../../Matcha/_redux/objects/objectsSlice.js";
const { actions: slice } = objectsSlice; //necessary for some reason

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      sessionStorage.removeItem("sessionFilter"); //also remove any sort of client / vendor filtering when logging into a new account
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(
            /*({ token })*/ (data) => {
              // console.log(data.data.token)
              disableLoading();
              dispatch(slice.resetState());
              props.login(data.data.token, from, fromSearch);
            }
          )
          .catch((err) => {
            console.error(err.response);
            if (err.response.status === 502) {
              setStatus(
                "Matcha is currently undergoing system maintenence. Please check back later."
              );
            } else if (err.response.data?.message === "user locked") {
              setStatus(
                "Account locked due to too many failed login attempts. Please reset your password."
              );
            } else if (
              err.response.data?.message === "reset password in progress"
            ) {
              setStatus(
                "Your password is in the process of being reset. Please check your email for more information."
              );
            } else {
              setStatus("The login detail is incorrect");
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 250); //250ms delay after clicking the "Sign In" button before the login actually occurs (REST API is so quick that it looks funny to humans, who expect a very brief spin while authenticating)
    },
  });

  const { from } = location?.state || { from: "/" }; // retrieves the URL set on the PrivateRoute component above
  const fromSearch = location?.state?.search || ""; // retrieves the URL search params set on the PrivateRoute component above

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login Account</h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            autoComplete="on"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            autoComplete="on"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default connect(null, auth.actions)(Login);
