import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { NavLink } from "react-router-dom";

/**
 * getBreadcrumb: Gets the breadcrumb for the file object
 */
export function getBreadcrumb(type) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlMissionId = parseInt(urlParams.get("mission_id"));
  const urlMissionName = urlParams.get("mission_name");
  const urlFolderId = parseInt(urlParams.get("folder_id"));
  const urlFolderName = urlParams.get("folder_name") ?? "Global Knowledge";
  const urlFileId = parseInt(urlParams.get("file_id"));
  const urlFileName = urlParams.get("file_name") ?? "All Files";
  const urlClientId = parseInt(urlParams.get("client_id"));
  const urlVendorId = parseInt(urlParams.get("vendor_id"));
  const urlPrevFileSearch = urlParams.get("fis");
  const urlPrevFolderSearch = urlParams.get("fs");
  const urlPrevMissionSearch = urlParams.get("ms");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h2 style={{ marginBottom: "10px" }}>Mission: {urlMissionName}</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          fontWeight: "lighter",
        }}
      >
        <NavLink
          title="Return to Missions"
          to={`/matcha/objects/missions${
            urlPrevMissionSearch ? "?s=" + urlPrevMissionSearch : ""
          }`}
        >
          <div
            className={`svg-icon svg-icon-xl svg-icon-primary ml-1`}
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
          </div>
        </NavLink>
        {((type !== "chatbot" && type !== "prompts") ||
          (type === "chatbot" && (urlFolderId ?? -1)) > 0) && (
          <>
            <div className={"ml-3"}>{`>`}</div>
            {type === "folders" ? (
              <span className={"ml-3"}>All Folders</span>
            ) : (
              <NavLink
                className={"ml-3"}
                to={`/matcha/objects/folders?mission_id=${urlMissionId}&mission_name=${encodeURIComponent(
                  urlMissionName
                )}&client_id=${urlClientId}&vendor_id=${urlVendorId}${
                  urlPrevFolderSearch ? "&s=" + urlPrevFolderSearch : ""
                }${urlPrevMissionSearch ? "&ms=" + urlPrevMissionSearch : ""}`}
              >
                <span>All Folders</span>
              </NavLink>
            )}
          </>
        )}
        {type === "prompts" && (
          <>
            <div className={"ml-3"}>{`>`}</div>
            <span className={"ml-3"}>All Prompts</span>
          </>
        )}

        {type === "files" && (
          <>
            <div className={"ml-3"}>{`>`}</div>
            <span className={"ml-3"}>{urlFolderName}</span>
          </>
        )}
        {type === "chatbot" && (
          <>
            {(urlFolderId ?? -1) > 0 && (
              <>
                <div className={"ml-3"}>{`>`}</div>
                <NavLink
                  className={"ml-3"}
                  to={`/matcha/objects/files?mission_id=${urlMissionId}&mission_name=${encodeURIComponent(
                    urlMissionName
                  )}&folder_id=${urlFolderId}&folder_name=${encodeURIComponent(
                    urlFolderName
                  )}&client_id=${urlClientId}&vendor_id=${urlVendorId}${
                    urlPrevFileSearch
                      ? "&s=" + encodeURIComponent(urlPrevFileSearch)
                      : ""
                  }${
                    urlPrevFolderSearch
                      ? "&fs=" + encodeURIComponent(urlPrevFolderSearch)
                      : ""
                  }${
                    urlPrevMissionSearch
                      ? "&ms=" + encodeURIComponent(urlPrevMissionSearch)
                      : ""
                  }`}
                >
                  <span>{urlFolderName}</span>
                </NavLink>
              </>
            )}
            <div className={"ml-3"}>{`>`}</div>
            <span className={"ml-3"}>Chatbot</span>
            {urlFileName && (urlFileId ?? -1) > 0 && (
              <>
                <div className={"ml-3"}>({urlFileName})</div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
