/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const vendorsTableMock = [
    {
        "id": 1,
        "name": "Harris",
        "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d",
        "description": "N Harris Computer Corporation",
        "email": "tcarr@harriscomputer.com",
        "phonenumber": "343-809-6413",
        "created_at": "2021-11-11T15:14:03.173922+00:00",
        "updated_at": null
    }
];