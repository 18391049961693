import axios from "axios";
import _ from "lodash";

/**
 * Save/Update the correction for the analysis for the specified prompt. If correctedanswer is null, the correction will be removed.
 * @param {*} file_id
 * @param {*} analysisJsonKey
 * @param {*} analysisJson
 * @param {*} correctedanswer
 * @param {*} correcteddetails
 * @returns Promise for the axios post request
 */
export function saveAnalysisCorrection(
  file_id,
  analysisJsonKey,
  analysisJson,
  correctedanswer,
  correcteddetails
) {
  let analysisCorrection = _.cloneDeep(analysisJson);

  if (!correctedanswer) {
    if (analysisCorrection["correction"]) {
      delete analysisCorrection["correction"];
    }
  } else {
    analysisCorrection["correction"] = {};
    analysisCorrection["correction"]["answer"] = correctedanswer;
    analysisCorrection["correction"]["supporting details"] = correcteddetails;
  }

  return axios({
    method: "post",
    url: "/rest/rpc/analysis_correction",
    data: {
      p_file_id: file_id,
      p_analysis_json_key: analysisJsonKey,
      p_analysis_json: analysisCorrection,
    },
  });
}
