/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const usersTableMock = [
    {
        "id": 1,
        "firstname": "alice",
        "lastname": "webuser",
        "vendor_id": 1,
        "email": "alice@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webuser",
        "status": "active",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    },
    {
        "id": 2,
        "firstname": "bob",
        "lastname": "webadmin",
        "vendor_id": 1,
        "email": "bob@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webadmin",
        "status": "active",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    },
    {
        "id": 3,
        "firstname": "ux",
        "lastname": "matcha",
        "vendor_id": null,
        "email": "matchaadmin@demo.com",
        "password": "demo",
        "phone": null,
        "role": "matchaadmin",
        "status": "active",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": "2022-01-31T17:26:36.348855+00:00",
        "client_ids": null
    },
    {
        "id": 4,
        "firstname": "pending",
        "lastname": "status",
        "vendor_id": 1,
        "email": "pending@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webuser",
        "status": "pending",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    },
    {
        "id": 5,
        "firstname": "locked",
        "lastname": "status",
        "vendor_id": 1,
        "email": "locked@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webuser",
        "status": "locked",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    },
    {
        "id": 6,
        "firstname": "deleted",
        "lastname": "status",
        "vendor_id": 1,
        "email": "deleted@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webuser",
        "status": "deleted",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    },
    {
        "id": 7,
        "firstname": "vendor",
        "lastname": "example",
        "vendor_id": 1,
        "email": "vendoradmin@demo.com",
        "password": "demo",
        "phone": null,
        "role": "vendoradmin",
        "status": "active",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2022-01-31T17:26:36.348855+00:00",
        "updated_at": null,
        "client_ids": null
    },
    {
        "id": 8,
        "firstname": "charlie",
        "lastname": "webwriter",
        "vendor_id": 1,
        "email": "webwriter@demo.com",
        "password": "demo",
        "phone": null,
        "role": "webwriter",
        "status": "active",
        "bad_login_count": 0,
        "last_bad_login": null,
        "last_login": null,
        "created_at": "2024-09-16T16:06:36.348855+00:00",
        "updated_at": null,
        "client_ids": "^5^"
    }
];