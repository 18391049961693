/**
 * ExcelexportHelpers: Defines functions to helps prepare data for the react-data-export Excel rendering
 *
 * @author Tyler Carr(2024)
 */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers.js";

import ExcelFile from "../../../ExcelPlugin/components/ExcelFile"; // Extended version from react-data-export
import ExcelSheet from "../../../ExcelPlugin/elements/ExcelSheet"; // Extended version from react-data-export

/**
 * A component with the download button and accompanying excel export of file analyses in folder
 * @param {*} folder_id is the Folder to export analyses for
 * @param {*} folder_name is the Folder name to export analyses
 * @returns download-fileanalyses-as-excel button with associated functionality
 */
export default function ExcelFileAnalysesExport({
  folder_id,
  folder_name,
  file_id,
  file_name,
}) {
  const [fileAnalyses, setFileAnalyses] = useState([]);
  const [excelSheetData, setExcelSheetData] = useState({
    columns: [],
    data: [],
  });
  useEffect(() => {
    const excelSheetData = getFileAnalysesExcelDataSet(file_name);
    setExcelSheetData(excelSheetData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAnalyses]);

  // Use Axios to fetch details about analyzed status files in this folder_id
  useEffect(() => {
    const axios_cancel_source = new AbortController();
    async function reloadFiles() {
      // Reload files list with axios for the folder chosen
      let fileWhereClause = "";
      if (file_id) {
        fileWhereClause = `id.eq.${file_id},`;
      }
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/files?order=filename.asc&select=id,filename,analysis&and=(folder_id.eq.${folder_id},${fileWhereClause}status.eq.analyzed)`,
          { signal: axios_cancel_source?.signal }
        )
        .then((resp) => {
          setFileAnalyses(resp.data);
        })
        .catch((err) => {
          if (!axios_cancel_source?.signal?.aborted) {
            console.error(err.response, err);
          }
        });
    }
    reloadFiles();
    return function cleanUp() {
      axios_cancel_source.abort();
    };
  }, [folder_id, file_id]);

  /**
   * getFileAnalysesExcelDataSet will extract all the prompts/resopnses for each file in the folder
   * to render in an ExcelFile from react-export-data
   * @param {*} folder_id is the Folder to export analyses for
   * @returns newExcelDataSet as array of columns/data Objects
   */
  function getFileAnalysesExcelDataSet(file_name) {
    const newExcelDataSet = { columns: [], data: [] };

    // Loop over data to capture headers and filename sizes
    const promptTitles = {};
    let maxFilenameLength = 30;
    fileAnalyses.forEach((file) => {
      if (file?.filename?.length > maxFilenameLength) {
        maxFilenameLength = file?.filename?.length;
      }
      Object.keys(file?.analysis).forEach((key) => {
        promptTitles[key] = key;
      });
    });

    // Add column headings for the excel export
    const allColumns = [
      { title: "File Name", width: { wpx: 7 * maxFilenameLength } },
    ];
    Object.keys(promptTitles)
      .sort()
      .forEach((key) => {
        allColumns.push({
          title: key,
          width: { wpx: key.length * 7 > 150 ? key.length * 7 : 150 },
        });
      });
    newExcelDataSet.columns = allColumns;

    // Loop over files in folder and extract the analyses for each row
    fileAnalyses.forEach((file) => {
      let dataRow = [];
      dataRow.push({
        value: file.filename,
        style: {
          alignment: { vertical: "top", wrapText: true },
          font: { bold: true },
        },
      });
      Object.keys(promptTitles)
        .sort()
        .forEach((key) => {
          let answer = file?.analysis?.[key]?.llm_response?.answer;
          let details =
            file?.analysis?.[key]?.llm_response?.["supporting details"];
          if (file?.analysis?.[key]?.correction?.answer) {
            answer = file?.analysis?.[key]?.correction?.answer;
            details =
              file?.analysis?.[key]?.correction?.["supporting details"] ?? "";
          }
          const newCell = {
            value: answer,
            width: {
              wpx: 200,
            },
            style: { alignment: { vertical: "top", wrapText: true } },
          };
          let commentStr = "";
          if (details) {
            commentStr += `${details}`;
          }
          //Loop over vector_db_context for source page #'s and snippets
          // hide if this is a corrected answer
          if (
            file?.analysis?.[key]?.vector_db_context &&
            !file?.analysis?.[key]?.correction?.answer
          ) {
            file.analysis[key].vector_db_context.forEach((context) => {
              if (
                (!context?.relevanceScore || context?.relevanceScore >= 50) &&
                context?.skip !== true
              ) {
                // Capture first 10 words of context?._source?.metadata?.original_contents
                let snippet = context?._source?.metadata?.original_contents;
                if (snippet) {
                  snippet = snippet.split(" ").slice(0, 10).join(" ");
                  commentStr += `\n\n${context?._source?.metadata?.section_title} (Page #${context?._source?.metadata?.page_number}): ${snippet} ...`;
                }
              }
            });
          } else if (file?.analysis?.[key]?.correction?.answer) {
            commentStr += `\n\n* This answer was manually corrected}`;
          }
          if (commentStr.length > 0) {
            newCell["comment"] = commentStr;
          }
          dataRow.push(newCell);
        });
      newExcelDataSet.data.push(dataRow);
    });
    return [newExcelDataSet];
  }

  const currTimestamp = new Date().toISOString().replace(/:/g, "-");

  return (
    <ExcelFile
      filename={
        file_name
          ? `${folder_name}_${file_name}_Analysis.${currTimestamp}`
          : `${folder_name}_Analyses.${currTimestamp}`
      }
      element={
        <button
          type="button"
          variant="light"
          title={
            fileAnalyses.length <= 0
              ? "You must analyze at least one file!"
              : "Download File Analyses"
          }
          className={` btn btn-icon btn-light btn-hover-primary ${
            file_id ? "btn-md" : "btn-lg mr-2"
          }`}
          size="lg"
          disabled={fileAnalyses.length <= 0}
        >
          <span
            className={`svg-icon svg-icon-${file_id ? "lg" : "xl"} svg-icon-${fileAnalyses.length <= 0 ? "secondary" : "primary"}`}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Files/ExcelSimple.svg")}
            />
          </span>
        </button>
      }
    >
      {/* limit name to 30 chars */}
      <ExcelSheet
        dataSet={excelSheetData}
        name={`${folder_name.substring(0, 30)}`}
      />
    </ExcelFile>
  );
}
