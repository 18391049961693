import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/objects/objectsActions";
import { useObjectsUIContext } from "../ObjectsUIContext";
import axios from "axios";

export function FolderDeleteDialog({ objecttype, id, show, onHide }) {
  // Getting current state of objects list from store (Redux)
  const {
    currentState,
    session_vendor_id,
    session_client_id,
    isLoading,
    user,
  } = useSelector(
    (state) => ({
      currentState: state.objects,
      session_vendor_id: state.auth.vendor_id,
      session_client_id: state.auth.client_id,
      isLoading: state.objects.actionsLoading,
      user: state.auth.user,
    }),
    shallowEqual
  );
  const location = useLocation();
  const [filesList, setFilesList] = useState([]);

  // keep track of all of the files that are connected to the selected folder
  useEffect(() => {
    if (
      currentState?.entities?.find?.((f) => f?.id === Number(id))?.file
        ?.length > 0
    ) {
      setFilesList(currentState.entities.find((f) => f.id === Number(id)).file);
    }
  }, [currentState, filesList, id]);

  // Objects UI Context
  const objectsUIContext = useObjectsUIContext();
  const objectsUIProps = useMemo(() => {
    return {
      setIds: objectsUIContext.setIds,
      objecttype: objectsUIContext.objecttype,
      queryParams: objectsUIContext.queryParams,
    };
  }, [objectsUIContext]);

  // Objects Redux state
  const dispatch = useDispatch();

  // if !id we should close modal
  useEffect(() => {
    if (!id && objecttype) {
      // Critical to check for non-null objecttype, otherwise the useEffect onHide fires every time!
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteObject = () => {
    axios({
      method: "post",
      url: "/rest/rpc/folder_delete",
      data: {
        folder_id: id,
        file_ids: filesList.reduce((prev, curr) => [...prev, `${curr.id}`], []),
      },
    }).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchObjects(
          objectsUIProps.objecttype,
          objectsUIProps.queryParams,
          currentState.enumTypes,
          currentState.schema,
          session_vendor_id,
          session_client_id,
          null,
          location.search,
          user
        )
      );
      // clear selections list
      objectsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Folder Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <>
            <p>Are you sure you want to permanently delete this folder?</p>
            {filesList.length > 0 && (
              <p>
                This will also delete the {filesList.length} files stored within
                it.
              </p>
            )}
            <p>
              <strong>This action cannot be reversed!</strong>
            </p>
          </>
        )}
        {isLoading && <span>Folder is being deleted...</span>}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={deleteObject}
          className="btn btn-primary btn-elevate"
        >
          {`Delete Folder ${
            filesList.length > 0 ? ` And ${filesList.length} Files` : ""
          }`}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
