/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);

  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.role,
    }),
    shallowEqual
  );

  const dropdownToggleRef = useRef();

  return (
    <Dropdown drop="down" align="end">
      <Dropdown.Toggle
        ref={dropdownToggleRef}
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-light btn-hover-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstname} {user.lastname}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user.firstname[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        style={{ width: role?.endsWith("admin") ? "150px" : "80px" }}
      >
        <div className="navi">
          <NavLink
            className="btn btn-light-primary font-weight-bold"
            style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
            to="/matcha/objects/missions"
            onClick={() => {
              // Forcing dropdown to close its menu
              dropdownToggleRef?.current?.click();
            }}
          >
            Missions
          </NavLink>
          <div className="navi-separator" style={{ marginTop: "5px" }}></div>
          {["matchaadmin", "vendoradmin", "webadmin"].indexOf(role) >= 0 && ( //don't show to customerservicereps
            <>
              {/*begin::2 Level*/}
              {["matchaadmin", "vendoradmin"].indexOf(role) >= 0 && (
                <NavLink
                  className="btn btn-light-primary font-weight-bold"
                  style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
                  to="/settings/objects/vendors"
                  onClick={() => {
                    // Forcing dropdown to close its menu
                    dropdownToggleRef?.current?.click();
                  }}
                >
                  Vendors
                </NavLink>
              )}
              <NavLink
                className="btn btn-light-primary font-weight-bold"
                style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
                to="/settings/objects/clients"
                onClick={() => {
                  // Forcing dropdown to close its menu
                  dropdownToggleRef?.current?.click();
                }}
              >
                Clients
              </NavLink>
              <NavLink
                className="btn btn-light-primary font-weight-bold"
                style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
                to="/settings/objects/available_llms"
                onClick={() => {
                  // Forcing dropdown to close its menu
                  dropdownToggleRef?.current?.click();
                }}
              >
                LLMs
              </NavLink>
              {role === "matchaadmin" && (
                <NavLink
                  className="btn btn-light-primary font-weight-bold"
                  style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
                  to="/settings/objects/available_embedding_models"
                  onClick={() => {
                    // Forcing dropdown to close its menu
                    dropdownToggleRef?.current?.click();
                  }}
                >
                  Embedding Models
                </NavLink>
              )}
              <NavLink
                className="btn btn-light-primary font-weight-bold"
                style={{ width: "94%", margin: "5px", marginBottom: "0px" }}
                to="/settings/objects/users"
                onClick={() => {
                  // Forcing dropdown to close its menu
                  dropdownToggleRef?.current?.click();
                }}
              >
                Users
              </NavLink>
              {/*end::2 Level*/}
              <div
                className="navi-separator"
                style={{ marginTop: "5px" }}
              ></div>
            </>
          )}
          <NavLink
            to="/logout"
            className="btn btn-light-danger font-weight-bold"
            style={{
              width: role?.endsWith("admin") ? "94%" : "92%",
              margin: "5px",
            }}
          >
            Sign Out
          </NavLink>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
