import {
  LOGIN_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
  LOGACTIVITY_URL,
} from "../_redux/authCrud";
import { SignJWT, jwtVerify, decodeJwt } from "jose";
import { usersTableMock } from "../../Matcha/__mocks__/usersTableMock";

export default function mockAuth(mock) {
  const createMeToken = async function (user) {
    const jwtToken = await new SignJWT(
      {
        role: user.role,
        user_id: user.id,
        client_id: user.client_id,
        vendor_id: user.vendor_id,
      })
      .setExpirationTime('2h')
      .setProtectedHeader({ alg: "HS256" })
      .sign(
      new TextEncoder().encode("demo")
    )
    const meToken = {
      me: { ...user, password: undefined },
      token: jwtToken,
    };
    return meToken;
  };

  mock.onPost(LOGIN_URL).reply(async ({ data }) => {
    const { email, password } = JSON.parse(data);

    if (email && password) {
      const user = usersTableMock.find(
        (x) =>
          x.email.toLowerCase() === email.toLowerCase() &&
          x.password === password
      );

      if (user) {
        return [200, await createMeToken(user)];
      }
    }

    return [400];
  });

  mock.onPost(LOGACTIVITY_URL).reply(({ data }) => {

    //Assume logActivity always succeeds
    return [200];
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        id: generateUserId(),
        email,
        fullname,
        username,
        password,
        firstname: fullname,
        lastname: "Stark",
        roles: [2], // Manager
        authToken: "auth-token-" + Math.random(),
        refreshToken: "auth-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg",
      };

      usersTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = usersTableMock.find(
        (x) => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(async ({ headers: { Authorization } }) => {
    const authToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    const validJwt = await jwtVerify(authToken, new TextEncoder().encode("demo"));
    if (authToken && validJwt) {
      const authRecord = decodeJwt(authToken);
      const user = usersTableMock.find((x) => x.id === authRecord.user_id);

      if (user) {
        return [200, await createMeToken(user)];
      } else if (authRecord.role === "customer_sso") {
        return [
          200,
          {
            me: {
              id: "0",
              client_id: authRecord.client_id,
              client_name: "Demo Templates",
              vendor_id: 10,
              vendor_name: "Demo Vendor",
              firstname: "sso",
              lastname: "single sign on",
              email: "csr@email.com",
              phone: "",
              role: "customer_sso",
            },
            token: await new SignJWT(
              {
                role: "customer_sso",
                user_id: 0,
                client_id: authRecord.clientid,
                vendor_id: 10,
                exp: parseInt(new Date().getTime() / 1000) + 1000,
              }
              .setExpirationTime('2h')
              .setProtectedHeader({ alg: "HS256" })
              .sign(
              new TextEncoder().encode("demo")))
          },
        ];
      }
    }

    return [401];
  });

  function generateUserId() {
    const ids = usersTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
