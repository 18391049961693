/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const clientsTableMock = [
    {
        "id": 5,
        "name": "Matcha Test Files",
        "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282",
        "vendor_id": 1,
        "description": "Matcha Templates",
        "email": "templates@email.com",
        "phonenumber": "555-123-1212",
        "created_at": "2021-11-11T15:12:27.009469+00:00",
        "updated_at": null,
        "status": "demo",
        "timezone": "US/Eastern",
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "missions": [
            {
                "id": 1,
                "name": "M&A Contracts Analysis"
            },
            {
                "id": 2,
                "name": "Knowledgebase Search"
            },
            {
                "id": 3,
                "name": "General"
            }
        ],
        "folders": [
            {
                "id": 1,
                "name": "Test Contracts",
                "mission_id": 1
            },
            {
                "id": 2,
                "name": "Receipts",
                "mission_id": 1
            },
            {
                "id": 3,
                "name": "Software Documentation",
                "mission_id": 2
            }
        ]
    }
];