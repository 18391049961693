/**
 * Folders: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr (2024)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { getBreadcrumb } from "../../partials/getBreadcrumb";
import {
  Input,
  TextAreaInput,
} from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import { FolderDeleteDialog } from "../object-delete-dialog/FolderDeleteDialog";
import copy from "clipboard-copy";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

import SelectField from "../../../partials/SelectField";
import { Snackbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const objecttype = "folders";

/**
 * folders: An object that contains information about a folder.
 *
 * @constructor
 */
const folders = {
  objecttype: objecttype,
  title: getDynamicTitle,
  singular: "Folder",
  nameField: "name",
  initObject: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlMissionId = parseInt(urlParams.get("mission_id"));
    const client_id = parseInt(urlParams.get("client_id"));
    const vendor_id = parseInt(urlParams.get("vendor_id"));
    return {
      vendor_id: vendor_id,
      client_id: client_id,
      mission_id: urlMissionId,
      description: "",
      embedding_model: 3,
    };
  },
  actions: [],
  privileges: {
    //Hardcoded
    create: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    copy: [],
    update: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    delete: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
  },
  initialFilter: {
    selectFields: [
      "*",
      "vendor:vendors(id,name,vendor_guid)",
      "client:clients(id,name,client_guid)",
      "client_filter:clients(id,name,client_guid)",
      "mission:missions(id,name)",
      "mission_filter:missions(id,name)",
      "file:files(id,filename,status)",
    ],
    sortOrder: "asc", // asc||desc
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [
    "id",
    "name",
    "description",
    "created_at",
    "updated_at",
    "updated_by",
    "created_by",
  ],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: saveObject,
  ObjectDeleteDialog: FolderDeleteDialog,
  getValidateForm: getValidateForm,
};

/**
 * Navigates the user browser session away from the Folders table to the Files Table filtered for that folder.
 *
 * @param {*} row the row of data you'd like to interact with
 * @param {*} navigate
 */
function openFolderFiles(row, navigate) {
  const prevFolderSearch = new URLSearchParams(window.location.search).get("s");
  const prevMissionSearch = new URLSearchParams(window.location.search).get(
    "ms"
  );

  navigate({
    pathname: `/matcha/objects/files`,
    search: `?folder_id=${row.id}&folder_name=${encodeURIComponent(
      row.name
    )}&client_id=${row.client_id}&vendor_id=${row.vendor_id}&mission_id=${
      row?.mission?.id
    }&mission_name=${encodeURIComponent(row?.mission?.name)}${
      prevFolderSearch ? "&fs=" + encodeURIComponent(prevFolderSearch) : ""
    }${
      prevMissionSearch ? "&ms=" + encodeURIComponent(prevMissionSearch) : ""
    }`,
  });
}

/**
 * Formatter for the FolderNameColumn, to make the folder name clickable to open folder
 * @param {*} _cellContent unused in this formatter
 * @param {*} row information about the row being formatted
 * @param {*} _rowIndex unused in this formatter
 * @param {*} props additional props to pass to the formatter, passed in via getColumn
 * @returns a clickable link in place of the usual name text
 */
function FolderNameColumnFormatter(_cellContent, row, _rowIndex, { navigate }) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        data-tip
        data-for={`viewPosition${row.id}`}
        key={`view_file_${row.id}`}
        title={`Open Folder (${row?.file?.length} Files)`}
        style={{ color: "var(--primary)", textDecoration: "underline" }}
        onClick={() => {
          openFolderFiles(row, navigate); // Load folder once analyze starts
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className={`svg-icon svg-icon-md mr-1 svg-icon-${row?.files_count > 0 ? "warning" : "secondary"}`}
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder.svg")} />
          </span>
          {row.name}
        </div>
      </a>
    </>
  );
}

/**
 * getDynamicTitle: Gets the title for the folder object
 * @param {*} lookups
 * @param {*} history
 * @returns JSX with Breadcrumb link, and ReactSelect to allow Mission change within Prompts screen
 */
function getDynamicTitle() {
  return getBreadcrumb("folders");
}

/**
 * Defines the folder object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display
 */
function getColumns(props) {
  return [
    {
      dataField: "name",
      text: "Folder Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: FolderNameColumnFormatter,
      formatExtraData: {
        navigate: props.navigate,
      },
      filterType: "ilike",
    },
    // hidden rows added to pass in client_id and vendor_id into row object for openFolderFiles
    {
      dataField: "client_id",
      text: "Client ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
    {
      dataField: "vendor_id",
      text: "Client ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
    {
      dataField: "files_count",
      text: "File Count",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
  ];
}

/**
 * Creates the form to used to edit the folder object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  id,
  formikProps,
  setInitialValues,
  _copyMode,
  _copiedObjectID,
}) {
  const { user, lookups } = useSelector(
    (state) => ({
      user: state.auth.user,
      lookups: state.objects.lookups,
    }),
    shallowEqual
  );

  const searchParams = new URLSearchParams(window.location.search);

  return (
    <Form className="form form-label-right">
      <div
        className="form-group row"
        style={{
          display:
            (lookups?.["clients"]?.length > 1 &&
              !searchParams.get("client_id")) ||
            (lookups?.["missions"]?.length > 1 &&
              !searchParams.get("mission_id"))
              ? "block"
              : "none",
        }}
      >
        {/* Client Id */}
        <div
          className="col-lg-4"
          style={{
            display:
              lookups?.["clients"]?.length > 1 && !searchParams.get("client_id")
                ? "block"
                : "none",
          }}
        >
          <label>Client</label>
          <Field
            component={SelectField}
            id="folder_client_id"
            name="client_id"
            isDisabled={folders.privileges.create.indexOf(user.role) === -1}
            options={lookups["clients"]?.reduce((prev, curr) => {
              prev.push({ value: curr.id, label: curr.name });
              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          ></Field>
        </div>
        {/* Mission */}
        <div
          className="col-lg-4"
          style={{
            display:
              lookups?.["missions"]?.length > 1 &&
              !searchParams.get("mission_id")
                ? "block"
                : "none",
          }}
        >
          <label>Mission</label>
          <Field
            component={SelectField}
            id="mission_id"
            name="mission_id"
            isDisabled={folders.privileges.create.indexOf(user.role) === -1}
            options={lookups["missions"]?.reduce((prev, curr) => {
              // Filter missions by client_id
              if (curr.client_id === formikProps?.values?.client_id) {
                prev.push({ value: curr.id, label: curr.name });
              }

              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          ></Field>
        </div>
      </div>
      <div className="form-group row">
        {/* Folder Name */}
        <div className="col-lg-4">
          <Field
            name="name"
            className={`form-control ${formikProps?.errors?.name ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Folder Name"
            label="Folder Name"
            disabled={folders.privileges.update.indexOf(user.role) === -1}
          />
        </div>
        {/* Embedding Model Id */}
        <div
          className="col-lg-4"
          style={{
            display: user.role === "matchaadmin" ? "block" : "none",
          }}
        >
          <label>Embedding Model</label>
          {lookups?.["available_embedding_models"]?.length > 0 && (
            <Field
              component={SelectField}
              id="embedding_model"
              name="embedding_model"
              isDisabled={
                id || folders.privileges.create.indexOf(user.role) === -1
              }
              options={lookups["available_embedding_models"]?.reduce(
                (prev, curr) => {
                  prev.push({ value: curr.id, label: curr.name });
                  return prev;
                },
                []
              )}
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          )}
        </div>
      </div>
      <div className="form-group row">
        {/* Description */}
        <div className="col-lg-12">
          <Field
            rows="4"
            name="description"
            className={`form-control ${formikProps?.errors?.description ? "is-invalid" : ""}`}
            component={TextAreaInput}
            placeholder="Description"
            label="Description"
            disabled={folders.privileges.update.indexOf(user.role) === -1}
          />
        </div>
      </div>
    </Form>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [showIdFeedback, setShowIdFeedback] = useState(false);
  const can_see_id = folders.privileges.update.indexOf(user.role) > -1;
  const folder_id = formikProps.values.id;
  const title_text = title + (can_see_id && folder_id ? " - " + folder_id : "");
  const title_style = { display: "inline-block" };
  /**
   * Copy Selected folder ID to clipboard
   * @param {*} e - event from onClick
   */
  const copyFolderId = (e) => {
    e.preventDefault();

    let copied_id = formikProps.values.id;
    copy(copied_id).then(() => {
      setShowIdFeedback(true);
    });

    e.stopPropagation();
  };

  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text}
        {can_see_id && folder_id ? (
          <span
            className="inline-copy"
            title="Copy Folder ID"
            onClick={copyFolderId}
          />
        ) : null}
      </Modal.Title>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showIdFeedback}
        onClose={() => setShowIdFeedback(false)}
        message={<span id="id-message-id">Folder ID copied to clipboard!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowIdFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Folder name is required"),
      description: Yup.string(),
      client_id: Yup.string().required("Client is required"),
      mission_id: Yup.string().required("Mission is required"),
      embedding_model: Yup.string().required("Embedding Model is required"),
    });
  };
}

export default folders;
