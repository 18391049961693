import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { verifyToken, resetPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

function VerifyEmail(props) {
  const [isRequested, setIsRequested] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isFailedVerification, setIsFailedVerification] = useState(false);
  const [email, setEmail] = useState("");

  props.logout();
  //Verify token from e-mail link to resolve e-mail address and ensure token is not already expired
  if (!isVerifying) {
    setIsVerifying(true);
    const tokenparam = new URLSearchParams(window.location.search).get("token");
    verifyToken(tokenparam)
      .then((response) => {
        setEmail(response.data.status.tokenstatus.payload.email);
      })
      .catch((err) => {
        setIsFailedVerification(true);
        console.log(err);
      });
  }

  const ChangePasswordSchema = Yup.object().shape({
    password1: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
      .matches(/[A-Z]/, "Password must contain at least one UPPERCASE letter.")
      .matches(/[0-9]/, "Password must contain at least one numeric digit 0-9.")
      .matches(
        /[^A-z0-9]/,
        "Password must contain at least one symbol (i.e. $ # ! & *)."
      )
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    password2: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .oneOf([Yup.ref("password1"), null], "Passwords must match")
      .required("Password is required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const tokenparam = new URLSearchParams(window.location.search).get(
        "token"
      );
      resetPassword(tokenparam, values.password1)
        .then(() => setIsRequested(true))
        .catch((err) => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(err.response.data.message);
        });
    },
  });

  return (
    <>
      {isRequested && <Navigate to="/auth/login" />}
      {isFailedVerification && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              This link has expired, please retry the&nbsp;
              <Link
                to="/auth/forgot-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                Forgot Password
              </Link>
              &nbsp; page
            </h3>
          </div>
        </div>
      )}
      {!isFailedVerification && !isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              Set your new {email} password below:
            </h3>
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                style={{ display: "none" }}
                autoComplete="username"
                name="username"
                placeholder="Username"
                {...formik.getFieldProps("email")}
                defaultValue={email}
              />
              {formik.errors.password1 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                autoComplete="new-password"
                name="password1"
                placeholder="New Password"
                {...formik.getFieldProps("password1")}
              />
              {formik.errors.password1 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password1}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                autoComplete="new-password-verify"
                name="password2"
                placeholder="Confirm Password"
                {...formik.getFieldProps("password2")}
              />
              {
                /*formik.touched.password2 &&*/ formik.errors.password2 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password2}
                    </div>
                  </div>
                ) : null
              }
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default connect(null, auth.actions)(VerifyEmail);
