/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const foldersTableMock = [
    {
        "id": 2,
        "client_id": 5,
        "vendor_id": 1,
        "name": "Receipts",
        "description": "Receipts group",
        "mission_id": 1,
        "embedding_model": 1,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-06T10:33:33.471+00:00",
        "updated_by": null,
        "updated_at": null,
        "files_count": 2,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 3,
        "client_id": 5,
        "vendor_id": 1,
        "name": "Software Documentation",
        "description": "Software Documentation",
        "mission_id": 2,
        "embedding_model": 1,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-08-08T15:33:33.471+00:00",
        "updated_by": null,
        "updated_at": null,
        "files_count": 0,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 2,
            "name": "Knowledgebase Search"
        }
    },
    {
        "id": 1,
        "client_id": 5,
        "vendor_id": 1,
        "name": "Test Contracts",
        "description": "Test Contracts",
        "mission_id": 1,
        "embedding_model": 1,
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-06T10:31:53.228+00:00",
        "updated_by": null,
        "updated_at": null,
        "files_count": 2,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    }
];