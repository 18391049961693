/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const available_llmsTablesMock = [
    {
        "id": 5,
        "name": "Azure XL (Public)",
        "url": "https://azureopenairesourceharrisbi.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2023-03-15-preview",
        "prompt_type": "openai",
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-07-29T10:50:14.232+00:00",
        "updated_by": null,
        "updated_at": null,
        "character_limit": 12000
    },
    {
        "id": 2,
        "name": "Harris SM (Internal)",
        "url": "http://10.0.0.6:6112",
        "prompt_type": "llama3",
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-08-28T11:12:41.45+00:00",
        "updated_by": null,
        "updated_at": null,
        "character_limit": 12000
    },
    {
        "id": 1,
        "name": "Harris XL (Internal)",
        "url": "http://10.0.0.4:6115",
        "prompt_type": "llama3",
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-17T12:19:41.45+00:00",
        "updated_by": null,
        "updated_at": null,
        "character_limit": 12000
    }
];