export const addClientTimezoneOffset = function (dateStr) {
  // Javascript helpers to get timeZoneOffsetStr to query via local client time
  const timezoneOffset = new Date(dateStr).getTimezoneOffset() / -60; //TODO: Someday, locales with half-hour timezone offsets will have to be programed for with %60
  const padDigits = 2;
  const timeZoneOffsetStr =
    (timezoneOffset < 0 ? "-" : "+") +
    Array(
      Math.max(padDigits - String(Math.abs(timezoneOffset)).length + 1, 0)
    ).join(0) +
    Math.abs(timezoneOffset);
  return `${dateStr}${timeZoneOffsetStr}`;
};
