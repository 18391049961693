import axios from "axios";
import { escapeRegExp } from "lodash";
import { objectsDict } from "../../pages/objects/ObjectsUIHelpers";

export const OBJECTS_URL = `${process.env.REACT_APP_API_URL}`;

// CREATE =>  POST: add a new object to the server
export function createObject(objecttype, object) {
  // You may have requested extra fields in the initialFilter.selectFields
  // If so, remove them from the object before sending to the server
  if (objectsDict[objecttype].initialFilter.selectFields) {
    objectsDict[objecttype].initialFilter.selectFields.forEach((field) => {
      if (field.match(/([A-z0-9]+)[:].*[(].*[)].*$/)) {
        const fieldToDelete = field.replace(
          /([A-z0-9]+)[:].*[(].*[)].*$/,
          "$1"
        );
        delete object[fieldToDelete];
      }
    });
  }
  const queryParamsDict = {};
  let selectStr = "";
  if (objectsDict[objecttype].initialFilter.selectFields) {
    objectsDict[objecttype].initialFilter.selectFields.forEach((field) => {
      selectStr += `${selectStr === "" ? "" : ","}${field}`;
    });
    if (selectStr.length > 0) {
      queryParamsDict["select"] = selectStr;
    }
  }
  //selectFields
  return axios.post(`${OBJECTS_URL}/${objecttype}`, object, {
    headers: {
      Prefer: "return=representation",
    },
    params: queryParamsDict,
  });
}

// READ
export function getAllObjects(objecttype) {
  return axios.get(`${OBJECTS_URL}/${objecttype}`);
}

export function getPostgRESTapi() {
  return axios.get(`${process.env.REACT_APP_API_URL}/`);
}

export function getObjectById(objecttype, objectId) {
  let selectStr = "";
  const queryParamsDict = {};
  if (objectsDict[objecttype].initialFilter.selectFields) {
    objectsDict[objecttype].initialFilter.selectFields.forEach((field) => {
      selectStr += `${selectStr === "" ? "" : ","}${field}`;
    });
    if (selectStr.length > 0) {
      queryParamsDict["select"] = selectStr;
    }
  }
  return axios.get(`${OBJECTS_URL}/${objecttype}`, {
    params: { ...queryParamsDict, id: `eq.${objectId}` },
    headers: {
      Accept: "application/vnd.pgrst.object+json",
    },
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findObjects(
  objecttype,
  queryParams,
  enumTypes,
  stateSchema,
  session_vendor_id,
  session_client_id,
  axios_cancel_source,
  locationSearchParams,
  user,
  fromLookup = false,
  fromClientPickerLookup = false
) {
  const queryParamsDict = {};
  const locationParams = new URLSearchParams(locationSearchParams);

  // loop over locationParams.entries and add to locationParamsDict
  const locationParamsDict = {};
  for (const [key, value] of locationParams.entries()) {
    locationParamsDict[key] = value;
  }

  if (queryParams.sortField) {
    const sortSplitList = queryParams.sortField.split(".");
    if (sortSplitList.length > 1) {
      queryParamsDict[`order`] =
        `${sortSplitList[0]}(${sortSplitList[1]}).${queryParams.sortOrder}`;
    } else {
      queryParamsDict["order"] = `${queryParams.sortField.replaceAll(
        /[,]/g,
        "." + queryParams.sortOrder + ","
      )}.${queryParams.sortOrder}`;
    }
  }

  let tableColumnsString = "";

  // fromLookup=false //If logging data for mockObjects, need fromLookups to be false
  if (
    fromLookup &&
    objectsDict[objecttype] &&
    objectsDict[objecttype].getColumns
  ) {
    const tableDisplayColumns = objectsDict[objecttype].getColumns({
      session_client_id: session_client_id,
      user: user,
    });
    tableColumnsString = tableDisplayColumns.reduce((prev, curr) => {
      //Do not add join columns from select fields, just core columns from objecttype table (check if column name exists in stateSchema definitions)
      let newString = prev;
      if (
        stateSchema.definitions[objecttype]?.properties[curr.dataField] &&
        curr !== "id"
      ) {
        if (newString !== "") {
          newString += ",";
        }
        newString += curr.dataField;
      }
      return newString;
    }, "id"); //TODO: Might need to look-up id column name if table doesn't contain a literal "id" columnname
  }
  let selectStr = "";
  if (tableColumnsString !== "") {
    selectStr = tableColumnsString;
  }
  if (queryParams.selectFields) {
    queryParams.selectFields.forEach((field) => {
      if (field.indexOf("(") > -1 || field.indexOf(":") > -1 || !fromLookup) {
        // Only add fields that are embedded/joined to tableColumnsString
        selectStr += `${selectStr === "" ? "" : ","}${
          field === "*" && tableColumnsString !== ""
            ? tableColumnsString
            : field
        }`;
      }
    });
    if (selectStr.length > 0) {
      queryParamsDict["select"] = selectStr;
    }
  } else {
    selectStr = tableColumnsString;
  }
  if (selectStr.length > 0) {
    queryParamsDict["select"] = selectStr;
  }

  //turn list from getColumns into files that match, skip over any that don't have a "filterType"
  const filterfields = objectsDict[objecttype]
    ?.getColumns({ session_client_id: session_client_id, user: user })
    .reduce((total, v) => {
      if (v?.filterType && (!v?.displayable || v.displayable())) {
        return {
          [v.dataField]: v.filterType,
          ...total,
        };
      }
      return total;
    }, {});

  let filterStrList = [];
  let columnsFilterStr = "";
  const columnsParamDict = {};

  // check if locationParamsDict has a column match in stateSchema definitions, and if so, add to columnsParamDict
  for (const key in locationParamsDict) {
    if (stateSchema.definitions[objecttype]?.properties?.[key]) {
      columnsParamDict[key] = `eq.${locationParamsDict[key]}`;
    }
  }

  if (
    objectsDict[objecttype] &&
    stateSchema?.definitions[objecttype]?.properties["client_id"] &&
    session_client_id &&
    objecttype !== "contentuploads" &&
    objecttype !== "users"
  ) {
    columnsFilterStr += `${
      columnsFilterStr !== "" ? "," : ""
    }client_id.eq.${session_client_id}`;
  }
  if (
    objecttype === "clients" &&
    session_client_id &&
    !fromClientPickerLookup
  ) {
    //special check for `clients` view where `client_id` column is simply named `id`
    columnsFilterStr += `${
      columnsFilterStr !== "" ? "," : ""
    }id.eq.${session_client_id}`;
  }
  if (objecttype === "vendors" && session_vendor_id) {
    //special check for `vendors` view where `vendor_id` column is simply named `id`
    columnsFilterStr += `${
      columnsFilterStr !== "" ? "," : ""
    }id.eq.${session_vendor_id}`;
  }
  if (objecttype === "users" && session_client_id) {
    //special check for `clients` view where `client_ids` column is a ^-delimited list of client_ids
    columnsFilterStr += `${
      columnsFilterStr !== "" ? "," : ""
    }client_ids.ilike.${"%^" + session_client_id + "^%"}`;
  }
  if (
    objectsDict[objecttype] &&
    stateSchema?.definitions[objecttype]?.properties["vendor_id"] &&
    session_vendor_id &&
    objecttype !== "contentuploads"
  ) {
    //TODO: show null client/vendor_id content uploads, but hide ones from other clients
    columnsFilterStr += `${
      columnsFilterStr !== "" ? "," : ""
    }vendor_id.eq.${session_vendor_id}`;
  }
  if (queryParams?.columnsFilter?.length > 0) {
    queryParams.columnsFilter.forEach((colfilter) => {
      if (columnsFilterStr !== "" && colfilter.name.split(".").length <= 1) {
        columnsFilterStr += ",";
      }
      // allow postgrest filtering from !inner joins
      if (colfilter.operator && colfilter.operator === "in") {
        if (colfilter.name.split(".").length <= 1) {
          //Add handling for in list "or" null (does not work for !inner joined table columns)
          const colfilterList = colfilter.value.filter
            ? colfilter.value.filter((c) => c !== null)
            : colfilter.value;
          if (colfilterList.length === colfilter.value.length - 1) {
            columnsFilterStr += `or(${colfilter.name}.in.(${colfilterList}),${colfilter.name}.is.null)`;
          } else {
            columnsFilterStr += `${colfilter.name}.in.(${colfilterList})`;
          }
        } else {
          columnsParamDict[colfilter.name] = `in.(${colfilter.value})`;
        }
      } else if (colfilter.operator) {
        if (colfilter.name.split(".").length <= 1) {
          columnsFilterStr += `${colfilter.name}.${colfilter.operator}.${colfilter.value}`;
        } else {
          columnsParamDict[colfilter.name] =
            `${colfilter.operator}.${colfilter.value}`;
        }
      } else {
        if (colfilter.name.split(".").length <= 1) {
          columnsFilterStr += `${colfilter.name}.eq.${colfilter.value}`;
        } else {
          columnsParamDict[colfilter.name] = `eq.${colfilter.value}`;
        }
      }
    });
  }

  // Add handling for filtering by joined (resource embedded) columns
  // Using the approach PostGrest specifies here: https://github.com/PostgREST/postgrest/discussions/3165 (which provides a workaround for https://github.com/supabase/postgrest-js/issues/197#issuecomment-1012543331)
  const embeddedParams = {};

  //Loop over multiple (up to 6) space/comma/semicolon delimited terms as separate search terms to "or" together
  if (queryParams.searchText && queryParams.searchText !== "") {
    let searchText = queryParams.searchText;
    // Use a regexp group to pull out quoted strings from a concatenated string
    const quotedStrings = searchText.match(/"([^"]+)"|'([^']+)'/g);
    if (quotedStrings) {
      //Remove the quotes strings from the original searchText
      searchText = searchText.replaceAll(/"([^"]+)"|'([^']+)'/g, "");
      //Remove " character from quotesStrings list elements
      quotedStrings.forEach((quotedString, index) => {
        quotedStrings[index] = quotedString
          .replaceAll(/^["']/g, "")
          .replaceAll(/["']$/g, "");
      });
    }

    // Split search terms by space, comma, or semicolon, unless wrapped in single or double quotes, then do not split the quoted string
    const queryParamList = searchText
      .replaceAll(/[ ;'",+*]/g, ",")
      .replaceAll(/[,][,]*/g, ",")
      .split(`,`);

    // Add back any quoted strings to the queryParamList
    if (quotedStrings) {
      queryParamList.push(...quotedStrings);
    }
    for (let i = 0; i < queryParamList.length && i < 8; ++i) {
      const searchTerm = queryParamList[i];
      if (searchTerm === "") {
        continue;
      }
      let filterStr = "";
      let commaStr = "";
      filterStr += "(";
      for (const key in filterfields) {
        if (key.match(/^[A-z0-9_]+[.][A-z0-9_]+$/)) {
          //This is a joined/embedded column, need to check for null and add separate or parameter for it
          const keySplit = key.split(".");
          const newKey = `${keySplit[0]}_filter`;
          filterStr += `${commaStr}${newKey}.not.is.null`;
          commaStr = ",";
          // Add handling for filtering by joined (resource embedded) columns
          if (!embeddedParams?.[newKey]) {
            embeddedParams[newKey] = [];
          }
          if (filterfields[key] === "ilike") {
            embeddedParams[newKey].push(`${keySplit[1]}.ilike.*${searchTerm}*`);
          } else if (filterfields[key] === "eq") {
            embeddedParams[newKey].push(`${keySplit[1]}.eq.${searchTerm}`);
          } else if (filterfields[key] === "is") {
            // boolean true/false/null
            embeddedParams[newKey].push(`${keySplit[1]}.is.${searchTerm}`);
          } else if (
            filterfields[key] === "int" &&
            searchTerm.match(/^[-]?[0-9]+$/)
          ) {
            embeddedParams[newKey].push(`${keySplit[1]}.eq.${searchTerm}`);
          }
          continue;
        }
        if (filterfields[key] === "ilike") {
          filterStr += `${commaStr}${key}.ilike.*${searchTerm}*`;
          commaStr = ",";
        } else if (filterfields[key] === "eq") {
          filterStr += `${commaStr}${key}.eq.${searchTerm}`;
          commaStr = ",";
        } else if (filterfields[key] === "is") {
          // boolean true/false/null
          filterStr += `${commaStr}${key}.is.${searchTerm}`;
          commaStr = ",";
        } else if (
          filterfields[key] === "int" &&
          searchTerm.match(/^[-]?[0-9]+$/)
        ) {
          filterStr += `${commaStr}${key}.eq.${searchTerm}`;
          commaStr = ",";
        } else if (filterfields[key].match(/^in\(.+\)$/)) {
          const fieldList = filterfields[key].match(/^in\((.*[.].*)\)$/)
            ? enumTypes[
                filterfields[key].replaceAll(/^in\((.*[.].*)\)$/g, "$1")
              ]
            : filterfields[key]
                .replaceAll(/^in\(/g, "")
                .replaceAll(/\)/g, "")
                .split(",");
          const filteredFieldList = fieldList.filter((field) => {
            var re = new RegExp(`^.*${escapeRegExp(searchTerm)}.*$`, "g");
            return field.match(re);
          });
          if (filteredFieldList.length > 0) {
            filterStr += `${commaStr}${key}.in.(${filteredFieldList.reduce(
              (accum, field) => {
                return (accum += `${accum === "" ? "" : ","}${field}`);
              },
              ""
            )})`;
            commaStr = ",";
          }
        } else if (filterfields[key].match(/^jsonarraylike_.*_.*$/g)) {
          const jsonarraylike_params = filterfields[key].split("_");
          for (let x = 0; x < parseInt(jsonarraylike_params[2]); ++x) {
            filterStr += `${commaStr}${key}->${x}->>${jsonarraylike_params[1]}.ilike.*${searchTerm}*`;
            commaStr = ",";
          }
        }
      }
      filterStr += ")";
      filterStrList.push(filterStr);
    }
  }
  let finalFilterCombiner = "or";
  let finalFilterStr = "";
  if (filterStrList.length === 1) {
    finalFilterStr = filterStrList[0];
    finalFilterCombiner = "or";
  } else if (filterStrList.length > 1) {
    finalFilterStr = "(";
    for (let i = 0; i < filterStrList.length; ++i) {
      if (i > 0) {
        finalFilterStr += ",";
      }
      finalFilterStr += `or${filterStrList[i]}`;
    }
    finalFilterStr += ")";
  }
  if (finalFilterStr !== "" || columnsFilterStr !== "") {
    if (columnsFilterStr !== "" && finalFilterStr !== "") {
      queryParamsDict["and"] =
        `(${columnsFilterStr},${finalFilterCombiner}${finalFilterStr})`;
    } else if (columnsFilterStr !== "") {
      queryParamsDict["and"] = `(${columnsFilterStr})`;
    } else {
      queryParamsDict[finalFilterCombiner] = finalFilterStr;
    }
  }
  //Refactor join column filters to allow Postgrest-9.0 !inner filters
  const columnsParamKeys = Object.keys(columnsParamDict);
  for (let i = 0; i < columnsParamKeys.length; ++i) {
    queryParamsDict[columnsParamKeys[i]] =
      columnsParamDict[columnsParamKeys[i]];
  }

  //Add embedded column filters
  const embeddedKeys = Object.keys(embeddedParams);
  for (let i = 0; i < embeddedKeys.length; ++i) {
    queryParamsDict[`${embeddedKeys[i]}.or`] = `(${
      embeddedParams[embeddedKeys[i]]
    })`;
  }

  return axios.get(`${OBJECTS_URL}/${objecttype}`, {
    headers: {
      Prefer: "count=estimated",
      Range: `${(queryParams.pageNumber - 1) * queryParams.pageSize}-${
        queryParams.pageNumber * queryParams.pageSize - 1
      }`,
    },
    params: queryParamsDict,
    signal: axios_cancel_source?.signal,
  });
}

// UPDATE => PATCH: update the object on the server
export function updateObject(objecttype, object) {
  if (objectsDict[objecttype].initialFilter.selectFields) {
    objectsDict[objecttype].initialFilter.selectFields.forEach((field) => {
      if (field.match(/([A-z0-9]+)[:].*[(].*[)].*$/)) {
        const fieldToDelete = field.replace(
          /([A-z0-9]+)[:].*[(].*[)].*$/,
          "$1"
        );
        delete object[fieldToDelete];
      }
    });
  }

  const queryParamsDict = {};
  let selectStr = "";
  if (objectsDict[objecttype].initialFilter.selectFields) {
    objectsDict[objecttype].initialFilter.selectFields.forEach((field) => {
      selectStr += `${selectStr === "" ? "" : ","}${field}`;
    });
    if (selectStr.length > 0) {
      queryParamsDict["select"] = selectStr;
    }
  }
  //selectFields
  return axios.patch(`${OBJECTS_URL}/${objecttype}`, object, {
    headers: {
      Prefer: "return=representation",
      Accept: "application/vnd.pgrst.object+json",
    },
    params: { ...queryParamsDict, id: `eq.${object.id}` },
  });
}

// DELETE => delete the object from the server
export function deleteObject(objecttype, objectId) {
  return axios.delete(`${OBJECTS_URL}/${objecttype}`, {
    params: { id: `eq.${objectId}` },
  });
}

// DELETE Multiple Objects by ids
export function deleteObjects(objecttype, ids) {
  return axios.delete(`${OBJECTS_URL}/${objecttype}`, {
    params: { id: `in.(${ids})` },
  });
}
