/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const promptsTableMock = [
    {
        "id": 1,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Acceptance Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Is there an Acceptance clause with favorable terms for the software provider?",
        "vectormatchtext": "Software Acceptance. The Software will be deemed accepted by Licensee upon delivery of the Software and Documentation to Licensee. Licensee shall have a period of [Number] days from the date of delivery to report any material defects in the Software that prevent it from functioning in accordance with the Documentation.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:29:00+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 2,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Agreement Type",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What kind of document is this? Is it an \"Addendum\", an \"Amendment\", a \"Contractor Agreement\", a \"Financial Agreement\", a \"Letter\", a \"Master Agreement\", a \"Reference\", a \"Requisition Document\", a \"Sales Agreement\", a \"Service Agreement\", a \"Statement of Work\", or a \"Terms and Conditions\"?",
        "vectormatchtext": null,
        "prompt_type": "multiple-choice",
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-11T07:51:21+00:00",
        "updated_by": "cmahbubian@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 3,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Assignment Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an assignment clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Assignment. Neither party may assign this Agreement or any of its rights or obligations hereunder without the prior written consent of the other party, which consent shall not be unreasonably withheld. Any attempted assignment in violation of this Section shall be null and void. This Agreement shall be binding upon and inure to the benefit of the parties hereto and their respective permitted successors and assigns.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:28:16+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 4,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Automatic Renewal",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an Automatic Renewal clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Automatic Renewal. This Agreement shall be for an initial term of [Initial Term Length] (the \"Initial Term\") commencing on the Effective Date (as defined above).  Unless either party provides written notice of non-renewal to the other party at least [Notice Period] days before the expiration of the then-current term (including the Initial Term) (the \"Renewal Notice\"), this Agreement shall automatically renew for successive terms of [Renewal Term Length] (each, a \"Renewal Term\"). The fees for any Renewal Term will be the then-current fees for the Software, as communicated by Licensor to Licensee prior to the commencement of such Renewal Term.",
        "prompt_type": "multiple-choice",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:27:56+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 5,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Automatic Renewal Opt-Out (days)",
        "sysprompt": "",
        "precontext": "",
        "prompt": "How many days are there for Automatic Renewal Opt-Out?",
        "vectormatchtext": "Automatic Renewal. This Agreement shall be for an initial term of [Initial Term Length] (the \"Initial Term\") commencing on the Effective Date (as defined above).  Unless either party provides written notice of non-renewal to the other party at least [Notice Period] days before the expiration of the then-current term (including the Initial Term) (the \"Renewal Notice\"), this Agreement shall automatically renew for successive terms of [Renewal Term Length] (each, a \"Renewal Term\"). The fees for any Renewal Term will be the then-current fees for the Software, as communicated by Licensor to Licensee prior to the commencement of such Renewal Term.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:27:35+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 6,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Automatic Renewal Term (months)",
        "sysprompt": "",
        "precontext": "",
        "prompt": "How many months does the automatic renewal term cover?",
        "vectormatchtext": "Automatic Renewal. This Agreement shall be for an initial term of [Initial Term Length] (the \"Initial Term\") commencing on the Effective Date (as defined above).  Unless either party provides written notice of non-renewal to the other party at least [Notice Period] days before the expiration of the then-current term (including the Initial Term) (the \"Renewal Notice\"), this Agreement shall automatically renew for successive terms of [Renewal Term Length] (each, a \"Renewal Term\"). The fees for any Renewal Term will be the then-current fees for the Software, as communicated by Licensor to Licensee prior to the commencement of such Renewal Term.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:27:04+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 7,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Change of Control Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a change of control clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Option 1: Termination Right Upon Change of Control\n\nThis option allows you to terminate the agreement if there's a change of control.\n\nChange of Control.  For purposes of this Agreement, \"Change of Control\" shall mean any occurrence where:\n\nThere is a merger, consolidation, or sale of all or substantially all of the assets of Licensor.\nThere is a sale or other transfer of a majority of the voting equity interests of Licensor.\nIn the event of a Change of Control, Licensee shall have the right to terminate this Agreement upon written notice to Licensor within [Number] days of becoming aware of the Change of Control. Termination shall be effective [Number] days after such written notice is provided.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:26:39+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 8,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Commencement Date",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the commencement date of this agreement?",
        "vectormatchtext": "This option sets a specific date for the agreement to begin.\n\nCommencement Date. This Agreement shall be effective as of [Date] (the \"Commencement Date\").",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:26:26+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 9,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Contract Term (months)",
        "sysprompt": "",
        "precontext": "",
        "prompt": "How many months is the contract term length?",
        "vectormatchtext": "Term. This Agreement shall be for an initial term of 12 months (the \"Initial Term\") commencing on the Effective Date (as defined in Section 1.1).",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:25:59+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 10,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Customer Data Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a customer data clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Customer Data Ownership. Customer shall own all right, title, and interest in and to the underlying data it uploads to the Software (\"Source Data\"). Licensor shall own all right, title, and interest in and to the data generated by the Software through its analysis of Source Data (\"Analytical Data\"). Both parties agree to [describe how jointly owned data will be used, e.g., for product improvement, anonymized reporting].",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:25:38+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 11,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Data Breach Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a data breach clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Upon becoming aware of a Data Breach, Licensor shall promptly notify Customer in writing, within [Number] business days of becoming aware of the breach. The notification shall describe, to the extent known at the time, the nature and scope of the breach, the categories of Customer Data affected, and the steps Licensor has taken or will take to mitigate the breach and its potential effects.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:25:15+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 12,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Data Retention Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a data retention clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Data Retention.  Licensor shall retain Customer Data for so long as necessary to provide the Software and Services to Customer under this Agreement and in accordance with Customer's written instructions.  Customer may instruct Licensor to delete its Customer Data at any time.  Upon receipt of such written instruction, Licensor shall use commercially reasonable efforts to delete Customer Data in a secure manner within [Number] business days.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:24:58+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 13,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Data Security Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a data security clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Secure Storage. Licensor shall implement and maintain commercially reasonable administrative, physical, and technical safeguards to secure Customer Data throughout the retention period.  These safeguards shall be designed to prevent unauthorized access, destruction, disclosure, or modification of Customer Data.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:24:40+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 14,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Document Title",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the TITLE of this document?",
        "vectormatchtext": null,
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:22:50+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 15,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Effective Date",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the EFFECTIVE DATE of this agreement?",
        "vectormatchtext": "Effective Date. This Agreement shall be effective as of the later of (i) the date this Agreement is fully executed by both parties (the \"Execution Date\") or (ii) [Date] (the \"Effective Date\").",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:24:29+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 16,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Exclusivity Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an exclusivity clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Exclusivity. During the Term of this Agreement, Licensor grants to Licensee a non-transferable, exclusive right and license to use the Software within the Territory (as defined below).  \"Territory\" means [Specify geographic territory, e.g., United States of America and its territories].  Licensee agrees not to use, develop, license, or distribute any software product or service that competes with the Software within the Territory.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:23:46+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 17,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "File Name",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the FILE NAME of this document?",
        "vectormatchtext": null,
        "prompt_type": "multiple-choice",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:34:28+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 18,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Governing Law",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the governing law the document abides by?",
        "vectormatchtext": "Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the State of [State Name], without regard to its conflict of laws principles",
        "prompt_type": "discrete value",
        "created_by": "tcarr@harriscomputer.com",
        "created_at": "2024-06-17T15:24:41+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 19,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Indemnification Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an indemnification clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Indemnification. Licensee agrees to indemnify, defend, and hold harmless Licensor, its officers, directors, employees, agents, and affiliates (collectively, the \"Indemnified Parties\") from and against any and all claims, demands, losses, liabilities, costs, or expenses (including attorneys' fees) (collectively, \"Claims\") arising out of or relating to: (i) Licensee's use of the Software, (ii) any breach of this Agreement by Licensee, or (iii) any claim that Licensee's use of the Software infringes on the intellectual property rights of a third party.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:23:23+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 20,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Invoice Payment (days)",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an invoice payment clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Payment Terms. Licensor shall invoice Licensee for the Fees (as defined below) due hereunder. Payment for each invoice shall be due within [Number] days from the date of the invoice (the \"Due Date\").",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:23:07+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 21,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Limitation of Liability Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a limitation of liability clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Limitation of Liability.\n\nIN NO EVENT SHALL LICENSOR, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SUPPLIERS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, DATA, GOODWILL, USE, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES (EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR RELATING TO THE USE OR INABILITY TO USE THE SOFTWARE, OR FOR ANY OTHER CLAIM, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHER CAUSE OF ACTION, WHETHER OR NOT ARISING OUT OF OR IN CONNECTION WITH THE USE OR OTHER DEALINGS IN THE SOFTWARE.\n\nLICENSOR'S LIABILITY, AND THE LIABILITY OF ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND SUPPLIERS, TO YOU OR ANY THIRD PARTY UNDER ANY THEORY OF LIABILITY, SHALL BE LIMITED TO THE GREATER OF THE AMOUNT ACTUALLY PAID BY YOU FOR THE SOFTWARE OR [Dollar Amount] (REPLACE WITH A SPECIFIC DOLLAR AMOUNT).",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:29:11+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 22,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Main Parties",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Who are the MAIN PARTIES of this agreement?",
        "vectormatchtext": "Parties\n\nThis Agreement is made and entered into as of the Effective Date (as defined below) by and between:\n\n[Licensor Name], a [State of Incorporation] corporation, with its principal place of business at [Licensor Address] (\"Licensor\").\n[Licensee Name], a [State of Incorporation] [Type of Entity, e.g., corporation, sole proprietorship] with its principal place of business at [Licensee Address] (\"Licensee\").",
        "prompt_type": "multiple-choice",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:33:44+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 23,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Most Favored Nation Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a most favored nations clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Standard MFN Clause (Not Recommended for Most Software Contracts):\n\nThis grants the licensee the best terms offered to any other customer for the same software. However, this can be challenging due to:\n\nFocus on Specific Software: Licenses grant rights to a specific product, not a range. An MFN doesn't apply if there's only one product.\nPricing Variations: Discounts, customization, or features can affect pricing. An MFN clause might be difficult to manage fairly.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:29:24+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 24,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Non-Compete Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a non-compete clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Non-Compete\n\nDuring the Term (as defined below) and for a period of [Number] [Unit of Time] following the termination of this Agreement, Licensee agrees that it will not, directly or indirectly:\n\nDevelop, license, distribute, or provide any software product or service that competes with the functionality of the Software (as defined below).\nRecruit or solicit any employee or independent contractor of Licensor who has material knowledge of the Software.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:29:34+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 25,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Payment Terms Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a payment terms clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Payment Terms\n\nFees. Licensor shall invoice Licensee for the Fees (as defined below) due hereunder. \"Fees\" include:\nLicense Fees: The one-time or recurring fee for the right to use the Software.\n[List any additional fees, e.g., Support Fees, Implementation Fees, Training Fees].\nPayment Due Date. Payment for each invoice shall be due within [Number] days from the date of the invoice (the \"Due Date\").\nPayment Method. Payments shall be made in [Currency] by [Specify accepted payment methods, e.g., wire transfer, credit card]. Licensee shall be responsible for all bank charges and other applicable taxes associated with the payment.\nOptional Additions:\n\nLate Payment Fees: If you want to charge interest or late fees for overdue payments, you can add a sentence like: \"Payments not received by the Due Date will be subject to a late fee of [Percentage]% per month (or a fraction thereof) on the outstanding balance.\"\nPrepayment Option (if applicable): If you allow prepayment for certain fees (e.g., annual subscriptions), you can add a clause outlining the terms for prepayment.\nMilestone Payments (for complex implementations): For complex software implementations with defined milestones, you can structure the payment terms around achieving those milestones.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:31:00+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 26,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Price Change Notification",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a price change notification clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Price Change Notification. Licensor reserves the right to change its fees for the Software (the \"Fees\") upon [Triggering Event, e.g., renewal of the Term, annual basis].  Licensor shall provide Licensee with written notice of any such price change at least [Number] days prior to the effective date of the change (the \"Notice Period\"). The written notice shall describe the new Fees and the effective date of the change.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:30:31+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 27,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Price Increase (Indexed) Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have an INDEXED price increase clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Indexed Price Increase\n\nPrice Adjustments.  Licensor may adjust the Fees (as defined below) for the Software at the beginning of each Renewal Term (as defined below) based on the change in the [Index] (the \"Index\").  The adjusted Fee shall be the previous Fee multiplied by the Index Change Factor (as defined below).\n\nIndex. The Index shall be the [Name of Index], published by [Publishing Entity] (or a successor index with substantially similar methodology).",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:31:19+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 28,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Renewal Date",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a renewal date clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Renewal Term. This Agreement shall be automatically renewed for successive terms of [Number] [Unit of Time] (each, a \"Renewal Term\") unless earlier terminated as provided herein. The initial term of this Agreement shall be for the period from the Effective Date (as defined in Section 1.1) to the end of the first Renewal Term (the \"Initial Term\").",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:30:16+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 29,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Renewal Price Increase",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a renewal price increase clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Renewal Price Increase. The Fees (as defined below) for each Renewal Term (as defined below) shall be increased by [Percentage]% over the Fees for the prior term.  Licensor shall notify Licensee of the new Fees in writing at least [Number] days before the commencement of the Renewal Term.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:31:32+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 30,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Signature Date",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What date was this document signed?",
        "vectormatchtext": "IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date first written above.\n\n[Licensor Name]\n\nBy: [Name of Authorized Signatory]\n\nTitle: [Title of Authorized Signatory]\n\nDate: [SIGNATURE DATE]\n\n[Licensee Name]\n\nBy: [Name of Authorized Signatory]\n\nTitle: [Title of Authorized Signatory]\n\nDate: [SIGNATURE DATE]",
        "prompt_type": "discrete value",
        "created_by": "matchaadmin@email.com",
        "created_at": "2024-06-11T07:52:23+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 31,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Subcontracting Restrictions",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a subcontracting restrictions clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Subcontracting Restrictions\n\nLicensor's Right to Subcontract. Licensor may subcontract any of its obligations hereunder to a third party (\"Subcontractor\") upon prior written notice to Licensee.  Such notice shall identify the Subcontractor and the specific obligations to be subcontracted.  Licensor shall remain fully responsible for the performance of all obligations subcontracted hereunder.\n\nLicensee Restrictions. Licensee shall not subcontract or otherwise delegate any of its obligations hereunder without the prior written consent of Licensor, which consent may be withheld in Licensor's sole discretion.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:30:05+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 32,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Term Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a TERM clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "This option sets a specific timeframe for the agreement to be in effect.\n\nTerm\n\nThis Agreement shall be effective as of the Effective Date (as defined below) and shall remain in effect for a period of [Number] [Unit of Time] (the \"Initial Term\").  The Agreement shall automatically renew for successive terms of [Number] [Unit of Time] (each, a \"Renewal Term\") unless earlier terminated as provided herein.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:31:49+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 33,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Termination Clause",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a Termination clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Cause.\n\nBy Licensor. Licensor may terminate this Agreement for cause upon written notice to Licensee if Licensee:\nBreaches any material provision of this Agreement and fails to cure such breach within [Number] days' written notice from Licensor; or\nBecomes insolvent, bankrupt, or subject to similar proceedings.\nBy Licensee. Licensee may terminate this Agreement for cause upon written notice to Licensor if Licensor:\nBreaches any material provision of this Agreement and fails to cure such breach within [Number] days' written notice from Licensee; or\nCeases to offer the Software generally.\nConvenience (Optional).  If applicable to your agreement, you can include a clause for termination by either party upon written notice to the other party, without cause and without any requirement for breach.  Specify a reasonable notice period (e.g., 30 days).",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:32:01+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 34,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Termination Date",
        "sysprompt": "",
        "precontext": "",
        "prompt": "What is the termination date, if present?",
        "vectormatchtext": null,
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:32:13+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    },
    {
        "id": 35,
        "client_id": 5,
        "vendor_id": 1,
        "mission_id": 1,
        "title": "Third Party Software",
        "sysprompt": "",
        "precontext": "",
        "prompt": "Does this agreement have a Third Party Software clause? Are the terms favorable for the acquiring business?",
        "vectormatchtext": "Third-Party Software\n\nComponents. The Software may contain or rely upon components or libraries licensed from third-party vendors (\"Third-Party Software\").  A list of such Third-Party Software may be included in the accompanying documentation or made available on Licensor's website.\n\nThird-Party Licenses.  The Third-Party Software is subject to its own license terms (\"Third-Party Licenses\").  These Third-Party Licenses may be accessed through the documentation for the Third-Party Software or on the licensor's website.  Your use of the Software is also subject to the terms of such Third-Party Licenses.  In the event of any conflict between the terms of this Agreement and the Third-Party Licenses, the Third-Party Licenses shall prevail.\n\nNo Warranty for Third-Party Software. Licensor makes no warranties or representations with respect to the Third-Party Software, and hereby disclaims all warranties, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, title, and non-infringement. Licensor shall not be liable for any damages arising out of or related to the Third-Party Software.",
        "prompt_type": "discrete value",
        "created_by": "cmahbubian@harriscomputer.com",
        "created_at": "2024-06-19T07:32:26+00:00",
        "updated_by": "tcarr@harriscomputer.com",
        "updated_at": null,
        "client": {
            "id": 5,
            "name": "Matcha Test Files",
            "client_guid": "6b2260f1-fbda-4d6d-acd2-30dd1ff49282"
        },
        "vendor": {
            "id": 1,
            "name": "Harris",
            "vendor_guid": "dd32aa88-0ffd-4b85-9ece-415dc658e47d"
        },
        "mission": {
            "id": 1,
            "name": "M&A Contracts Analysis"
        }
    }
];