/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.role,
    }),
    shallowEqual
  );

  const getMenuItemActive = (url, hasSubmenu = false, hasQueue = false) => {
    if (
      checkIsActive(location, url) &&
      ((!hasQueue && location.pathname.indexOf("queue") === -1) ||
        (hasQueue && location.pathname.indexOf("queue") > -1))
    ) {
      return `${
        !hasSubmenu && "menu-item-active"
      } menu-item-open menu-item-not-hightlighted`;
    } else {
      return "";
    }
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* REPORTS (GRAPHS) NAVIGATION OPTION */}
        {
          <li
            className={`menu-item ${getMenuItemActive(
              "/matcha/objects/missions"
            )} ${getMenuItemActive("/matcha/objects/prompts")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/matcha/objects/missions">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">Missions</span>
            </NavLink>
          </li>
        }
        {
          <li
            className={`menu-item ${getMenuItemActive(
              "/matcha/objects/folders"
            )} ${getMenuItemActive("/matcha/objects/files")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/matcha/objects/folders">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">Folders</span>
            </NavLink>
          </li>
        }
        {
          <li
            className={`menu-item ${getMenuItemActive(
              "/chatbot"
            )} ${getMenuItemActive("/chatbot")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/chatbot">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">Chatbot</span>
            </NavLink>
          </li>
        }

        {/* Components */}

        {/* Documentation */}

        {/* Settings */}
        {/*begin::1 Level*/}
        {["matchaadmin", "vendoradmin", "webadmin"].indexOf(role) >= 0 && ( //don't show to customerservicereps
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/settings",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
                />
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/objects/vendors"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/objects/vendors">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Vendors</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/objects/clients"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/objects/clients">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Clients</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/objects/available_llms"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/settings/objects/available_llms"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">LLMs</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/objects/available_embedding_models"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/settings/objects/available_embedding_models"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Embedding Models</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/objects/users"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/objects/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Users</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
