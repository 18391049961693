import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  VerifyEmail: "[VerifyEmail] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetClient: "[Set Client] Action",
  SetVendor: "[Set Vendor] Action",
  setShowTimeoutModal: "[Set] Show Timeout Modal",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  showTimeoutModal: false,
  intendedUrl: undefined,
  intendedUrlSearch: undefined,
  client_id: undefined,
  client_name: undefined,
  client_guid: undefined,
  vendor_id: undefined,
  vendor_name: undefined,
  vendor_guid: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, intendedUrl, intendedUrlSearch } = action.payload;

        return {
          authToken,
          showTimeoutModal: false,
          intendedUrl: intendedUrl.match(/\/(logout|login){0,1}\/{0,1}$/g)
            ? undefined
            : intendedUrl,
          intendedUrlSearch: intendedUrlSearch,
          user: undefined,
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.VerifyEmail: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        // set temp values to match what has come in from /rpc/me me.sql function
        const user = action?.payload?.user?.me;
        const authToken = action?.payload?.user?.token;
        let client_id = action?.payload?.user?.me?.client_id ?? state.client_id;
        let client_guid =
          action?.payload?.user?.me?.client_guid ?? state.client_guid;
        let client_name =
          action?.payload?.user?.me?.client_name ?? state.client_name;
        let vendor_id = action?.payload?.user?.me?.vendor_id ?? state.vendor_id;
        let vendor_name =
          action?.payload?.user?.me?.vendor_name ?? state.vendor_name;

        // If new client_id isn't the same as the one in the state,
        // but it is one of the valid client_ids for the client, keep the old client_id and related info
        // This makes it so users don't have their chosen client_id reset on them
        if (
          client_id !== state.client_id &&
          user?.client_ids?.search(new RegExp(`\\^${state.client_id}\\^`)) > -1
        ) {
          client_id = state.client_id;
          client_name = state.client_name;
          client_guid = state.client_guid;
          vendor_id = state.vendor_id;
          vendor_name = state.vendor_name;
        }

        return {
          ...state,
          user,
          authToken,
          showTimeoutModal: false,
          client_id,
          client_name,
          client_guid,
          vendor_id,
          vendor_name,
        };
      }

      // client: {id: #, name: #, client_guid: #} -  vendor: {id: #, name: #, vendor_guid: #}
      case actionTypes.SetClient: {
        const client_id = action?.payload?.client?.id;
        const client_name = action?.payload?.client?.name;
        const client_guid = action?.payload?.client?.client_guid;
        const vendor_id = action?.payload?.vendor?.id;
        const vendor_name = action?.payload?.vendor?.name;
        const vendor_guid = action?.payload?.vendor?.vendor_guid;
        // console.log(`authRedux SetClient sees: vendor_id = ${vendor_id}, vendor_name = ${vendor_name}`)
        return {
          ...state,
          client_id,
          client_name,
          client_guid,
          vendor_id,
          vendor_name,
          vendor_guid,
        };
      }

      case actionTypes.SetVendor: {
        const vendor_id = action?.payload?.vendor?.id;
        const vendor_name = action?.payload?.vendor?.name;
        const vendor_guid = action?.payload?.vendor?.vendor_guid;
        return {
          ...state,
          vendor_id,
          vendor_name,
          vendor_guid,
          client_id: null,
          client_name: null,
          client_guid: null,
        };
      }

      case actionTypes.setShowTimeoutModal: {
        return {
          ...state,
          showTimeoutModal: action.payload.shouldShow,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, intendedUrl, intendedUrlSearch) => ({
    type: actionTypes.Login,
    payload: { authToken, intendedUrl, intendedUrlSearch },
  }),
  verifyEmail: () => ({ type: actionTypes.Logout }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setClient: (client, vendor) => ({
    type: actionTypes.SetClient,
    payload: { client, vendor },
  }),
  setVendor: (vendor) => ({ type: actionTypes.SetVendor, payload: { vendor } }),
  setShowTimeoutModal: (shouldShow) => ({
    type: actionTypes.setShowTimeoutModal,
    payload: { shouldShow },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
