Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _const = require("./const");

var _const2 = _interopRequireDefault(_const);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
} /* eslint no-mixed-operators: 0 */

exports.default = function (ExtendBase) {
  return (function (_ExtendBase) {
    _inherits(PageResolver, _ExtendBase);

    function PageResolver() {
      _classCallCheck(this, PageResolver);

      return _possibleConstructorReturn(
        this,
        (PageResolver.__proto__ || Object.getPrototypeOf(PageResolver)).apply(
          this,
          arguments
        )
      );
    }

    _createClass(PageResolver, [
      {
        key: "backToPrevPage",
        value: function backToPrevPage() {
          var _props = this.props,
            currPage = _props.currPage,
            pageStartIndex = _props.pageStartIndex;

          return currPage - 1 < pageStartIndex ? pageStartIndex : currPage - 1;
        },
      },
      {
        key: "initialState",
        value: function initialState() {
          var totalPages = this.calculateTotalPage();
          var lastPage = this.calculateLastPage(totalPages);
          return { totalPages: totalPages, lastPage: lastPage };
        },
      },
      {
        key: "calculateTotalPage",
        value: function calculateTotalPage() {
          var sizePerPage =
            arguments.length > 0 && arguments[0] !== undefined
              ? arguments[0]
              : this.props.currSizePerPage;
          var dataSize =
            arguments.length > 1 && arguments[1] !== undefined
              ? arguments[1]
              : this.props.dataSize;

          return Math.ceil(dataSize / sizePerPage);
        },
      },
      {
        key: "calculateLastPage",
        value: function calculateLastPage(totalPages) {
          var pageStartIndex = this.props.pageStartIndex;

          return pageStartIndex + totalPages - 1;
        },
      },
      {
        key: "calculateFromTo",
        value: function calculateFromTo() {
          var _props2 = this.props,
            dataSize = _props2.dataSize,
            currPage = _props2.currPage,
            currSizePerPage = _props2.currSizePerPage,
            pageStartIndex = _props2.pageStartIndex;

          var offset = Math.abs(
            _const2.default.PAGE_START_INDEX - pageStartIndex
          );

          var from = (currPage - pageStartIndex) * currSizePerPage;
          from = dataSize === 0 ? 0 : from + 1;
          var to = Math.min(currSizePerPage * (currPage + offset), dataSize);
          if (to > dataSize) to = dataSize;

          return [from, to];
        },
      },
      {
        key: "calculatePages",
        value: function calculatePages(totalPages, lastPage) {
          var _props3 = this.props,
            currPage = _props3.currPage,
            paginationSize = _props3.paginationSize,
            pageStartIndex = _props3.pageStartIndex,
            withFirstAndLast = _props3.withFirstAndLast,
            firstPageText = _props3.firstPageText,
            prePageText = _props3.prePageText,
            nextPageText = _props3.nextPageText,
            lastPageText = _props3.lastPageText,
            alwaysShowAllBtns = _props3.alwaysShowAllBtns;

          var pages = [];
          var endPage = totalPages;
          if (endPage <= 0) return [];

          var startPage = Math.max(
            currPage - Math.floor(paginationSize / 2),
            pageStartIndex
          );
          endPage = startPage + paginationSize - 1;

          if (endPage > lastPage) {
            endPage = lastPage;
            startPage = endPage - paginationSize + 1;
          }

          if (alwaysShowAllBtns) {
            if (withFirstAndLast) {
              pages = [firstPageText, prePageText];
            } else {
              pages = [prePageText];
            }
          }

          if (
            startPage !== pageStartIndex &&
            totalPages > paginationSize &&
            withFirstAndLast &&
            pages.length === 0
          ) {
            pages = [firstPageText, prePageText];
          } else if (totalPages > 1 && pages.length === 0) {
            pages = [prePageText];
          }

          for (var i = startPage; i <= endPage; i += 1) {
            if (i >= pageStartIndex) pages.push(i);
          }

          if (alwaysShowAllBtns || (endPage <= lastPage && pages.length > 1)) {
            pages.push(nextPageText);
          }
          if (
            (endPage !== lastPage && withFirstAndLast) ||
            (withFirstAndLast && alwaysShowAllBtns)
          ) {
            pages.push(lastPageText);
          }

          // if ((endPage <= lastPage && pages.length > 1) || alwaysShowAllBtns) {
          //   pages.push(nextPageText);
          // }
          // if (endPage !== lastPage && withFirstAndLast) {
          //   pages.push(lastPageText);
          // }

          return pages;
        },
      },
      {
        key: "calculatePageStatus",
        value: function calculatePageStatus() {
          var pages =
            arguments.length > 0 && arguments[0] !== undefined
              ? arguments[0]
              : [];

          var _this2 = this;

          var lastPage = arguments[1];
          var disablePageTitle =
            arguments.length > 2 && arguments[2] !== undefined
              ? arguments[2]
              : false;
          var _props4 = this.props,
            currPage = _props4.currPage,
            pageStartIndex = _props4.pageStartIndex,
            firstPageText = _props4.firstPageText,
            prePageText = _props4.prePageText,
            nextPageText = _props4.nextPageText,
            lastPageText = _props4.lastPageText,
            alwaysShowAllBtns = _props4.alwaysShowAllBtns;

          var isStart = function isStart(page) {
            return (
              currPage === pageStartIndex &&
              (page === firstPageText || page === prePageText)
            );
          };
          var isEnd = function isEnd(page) {
            return (
              currPage === lastPage &&
              (page === nextPageText || page === lastPageText)
            );
          };

          return pages
            .filter(function (page) {
              if (alwaysShowAllBtns) {
                return true;
              }
              return !(isStart(page) || isEnd(page));
            })
            .map(function (page) {
              var title = void 0;
              var active = page === currPage;
              var disabled = isStart(page) || isEnd(page);

              if (page === nextPageText) {
                title = _this2.props.nextPageTitle;
              } else if (page === prePageText) {
                title = _this2.props.prePageTitle;
              } else if (page === firstPageText) {
                title = _this2.props.firstPageTitle;
              } else if (page === lastPageText) {
                title = _this2.props.lastPageTitle;
              } else {
                title = "" + page;
              }

              var pageResult = {
                page: page,
                active: active,
                disabled: disabled,
              };
              if (!disablePageTitle) {
                pageResult.title = title;
              }
              return pageResult;
            });
        },
      },
      {
        key: "calculateSizePerPageStatus",
        value: function calculateSizePerPageStatus() {
          var sizePerPageList = this.props.sizePerPageList;

          return sizePerPageList.map(function (_sizePerPage) {
            var pageText =
              typeof _sizePerPage.text !== "undefined"
                ? _sizePerPage.text
                : _sizePerPage;
            var pageNumber =
              typeof _sizePerPage.value !== "undefined"
                ? _sizePerPage.value
                : _sizePerPage;
            return {
              text: "" + pageText,
              page: pageNumber,
            };
          });
        },
      },
    ]);

    return PageResolver;
  })(ExtendBase);
};
