import React, { Suspense, lazy, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";

const MatchaPage = lazy(() => import("./modules/Matcha/pages/MatchaPage"));
const ChatBotPage = lazy(() =>
  import("./modules/Matcha/pages/chatBotComponents/ChatBotPage")
);

export default function BasePage() {
  const { intendedUrl, intendedUrlSearch, role } = useSelector(
    (state) => ({
      intendedUrl: state.auth.intendedUrl,
      intendedUrlSearch: state.auth.intendedUrlSearch,
      role: state.auth.user.role,
    }),
    shallowEqual
  );
  const navigate = useNavigate();
  const location = useLocation();

  const from = intendedUrl; // retrieves the URL set on the PrivateRoute component above
  const fromSearch = intendedUrlSearch || ""; // retrieves the URL search parameters set on the PrivateRoute component above
  useEffect(() => {
    if (from && from !== "/" && from !== "") {
      navigate(from + (fromSearch ?? ""));
    } else if (
      role &&
      (location.pathname === "/auth/login" ||
        location.pathname === "/" ||
        location.pathname === "")
    ) {
      navigate("/matcha/objects/missions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] - is required if you need only one call
  //https://reactjs.org/docs/hooks-reference.html#useeffect
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Routes>
        <Route path="/settings/*" element={<MatchaPage />} />
        <Route path="/matcha/*" element={<MatchaPage />} />
        <Route path="/chatbot" element={<ChatBotPage />} />
      </Routes>
    </Suspense>
  );
}
