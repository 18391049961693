/**
 * Clients: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Xavier Clark(2021)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { Input } from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import copy from "clipboard-copy";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { objectsSlice } from "../../../_redux/objects/objectsSlice";
import axios from "axios";

import SelectField from "../../../partials/SelectField";
import { Checkbox } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const objecttype = "clients";

/**
 * clients: An object that contains information about a client.
 *
 * @constructor
 */
const clients = {
  objecttype: objecttype,
  title: "Clients",
  singular: "Client",
  nameField: "name",
  initObject: { status: "demo" },
  actions: [],
  privileges: {
    //Hardcoded
    create: ["matchaadmin", "vendoradmin"],
    copy: ["matchaadmin", "vendoradmin"],
    update: ["matchaadmin", "vendoradmin", "webadmin"],
    delete: ["matchaadmin", "vendoradmin"],
  },
  initialFilter: {
    selectFields: [
      "*",
      "vendor:vendors(id,name,vendor_guid)",
      "missions:missions(id,name)",
      "folders:folders(id,name,mission_id)",
    ],
    sortOrder: "asc", // asc||desc
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: ["id", "name", "client_guid", "created_at", "status"],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: clientSaveObject,
  getValidateForm: getValidateForm,
};

/**
 * Defines the client object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(_props) {
  return [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "in(data.client_status)",
    },
  ];
}

/**
 * Saves the object provided.
 *
 * If object has a "missionsToCopy" or a "foldersToCopy" field, saves it using a specific sql function. Otherwise use the default.
 *
 * Also set PendingHide to "true" so that when a response comes back from database after the dispatch we can either:
 *  Successfully submit (exit form)
 *  or Fail (don't exit form)
 *
 * @param {*} object the new formik created object to dispatch to the database.
 * @param {String} objecttype the objectType of the object you'd like to save
 * @param {*} user current user
 * @param {*} schema the object schema info to determine if it has created_by/updated_by columns
 * @param {*} dispatch react redux useDispatch object
 */
function clientSaveObject(object, objecttype, user, schema, dispatch) {
  if (!object?.foldersToCopy && !object?.missionsToCopy) {
    saveObject(object, objecttype, user, schema, dispatch);
  } else {
    // force all mission and folder IDs to number since formik stores as string
    // must make copy to keep prompts selected if error
    let objCopy = _.cloneDeep(object);
    objCopy.foldersToCopy = object.foldersToCopy.map((i) => {
      return Number(i);
    });

    objCopy.missionsToCopy = object.missionsToCopy.map((i) => {
      return Number(i);
    });

    //Add email for created_by columns
    objCopy.created_by = user.email;

    dispatch(objectsSlice.actions.startCallSave()); //set saveLoading to true
    axios({
      method: "post",
      url: "/rest/rpc/clientcopywizard",
      data: { p_object: objCopy },
    })
      .then((response) => {
        dispatch(objectsSlice.actions.endCallSave({ objecttype })); //set saveLoading to false - should cause the form to close
        dispatch(objectsSlice.actions.expireLookup({ objecttype }));
        objCopy["id"] = response.data.status.newClientID;
        delete objCopy.foldersToCopy;
        delete objCopy.missionsToCopy;
        dispatch(objectsSlice.actions.pushEntities({ object: objCopy }));
        dispatch(objectsSlice.actions.expireAllLookups());
      })
      .catch((error) => {
        dispatch(objectsSlice.actions.catchErrorSave({ error }));
      });
  }
}

/**
 * Creates the form to used to edit the client object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  _id,
  formikProps,
  setInitialValues,
  copyMode,
  copiedObjectID,
}) {
  const { user, lookups, stateEnums } = useSelector(
    (state) => ({
      user: state.auth.user,
      lookups: state.objects.lookups,
      stateEnums: state.objects.enumTypes,
    }),
    shallowEqual
  );

  const [showFeedback, setShowFeedback] = useState(false);
  const [allMissionIDs, setAllMissionIDs] = useState([]);
  const [allFolderIDs, setAllFolderIDs] = useState([]);

  /**
   * Copy Selected client GUID to clipboard
   * @param {*} e - event from onClick
   */
  const copyClientGUID = (e) => {
    e.preventDefault();

    let client_guid = formikProps.values.client_guid;
    copy(client_guid).then(() => {
      setShowFeedback(true);
    });

    e.stopPropagation();
  };

  // force select all missions and folders by default if currently making a copy
  // Make sure the missions and folders to copy have not been set yet to avoid overwriting
  // also sets copy_files value to true by default
  useEffect(() => {
    if (copyMode && copiedObjectID) {
      if (
        formikProps?.values?.missions?.length >= 0 &&
        !formikProps?.values?.missionsToCopy
      ) {
        // get all valid mission IDs for client
        let allMIDs = formikProps?.values?.missions.reduce((prev, curr) => {
          prev.push(`${curr.id}`);
          return prev;
        }, []);
        setAllMissionIDs(allMIDs);
        formikProps.setFieldValue("missionsToCopy", allMIDs);
      }
      if (
        formikProps?.values?.folders?.length >= 0 &&
        !formikProps?.values?.foldersToCopy
      ) {
        formikProps.setFieldValue("copy_files", true); // folders exist, set "copy_files" to true
        // get all valid Folder IDs for client
        let allFIDs = formikProps?.values?.folders.reduce((prev, curr) => {
          prev.push(`${curr.id}`);
          return prev;
        }, []);
        setAllFolderIDs(allFIDs);

        //set all Folder to selected on load
        formikProps.setFieldValue("foldersToCopy", allFIDs);
      }
    }
  }, [formikProps, copyMode, copiedObjectID]);

  /**
   * Handle the "Select All Missions and Folders" checkbox.
   * Puts all IDs into selected values if "all missions and all folders" selected,
   * deletes that array if unselected
   */
  function handleAllChecked() {
    if (
      _.isEqual(
        _.sortBy(allMissionIDs),
        _.sortBy(formikProps?.values?.missionsToCopy)
      ) &&
      _.isEqual(
        _.sortBy(allFolderIDs),
        _.sortBy(formikProps?.values?.foldersToCopy)
      )
    ) {
      formikProps.setFieldValue("foldersToCopy", []); // used to force reset
      formikProps.setFieldValue("missionsToCopy", []); // used to force reset
    } else {
      formikProps.setFieldValue("foldersToCopy", allFolderIDs);
      formikProps.setFieldValue("missionsToCopy", allMissionIDs);
    }
  }

  /**
   * custom handleChange wrapper to also change the folders associated with each mission
   * @param {Event} e the check event
   */
  function handleMissionChecked(e) {
    formikProps.handleChange(e);
    if (e.target.checked) {
      // now true, re-add all folders underneath
      // convert list of folders with matching mission id to array of strings
      let foldersToChange = formikProps.values.folders
        .filter((x) => x.mission_id === Number(e.target.value))
        .map((x) => `${x.id}`);

      // remove those IDs from the current list incase they're already added (dedup)
      const stripped = formikProps?.values?.foldersToCopy?.filter?.(
        (x) => !foldersToChange.includes(x)
      );

      // combine arrays
      formikProps.setFieldValue("foldersToCopy", [
        ...foldersToChange,
        ...stripped,
      ]);
    } else {
      // now false, remove all folders underneath
      let foldersToChange = formikProps.values.folders
        .filter((x) => x.mission_id === Number(e.target.value))
        .map((x) => `${x.id}`);

      formikProps.setFieldValue(
        "foldersToCopy",
        formikProps?.values?.foldersToCopy?.filter?.(
          (x) => !foldersToChange.includes(x)
        )
      );
    }
  }

  return (
    <Form className="form form-label-right">
      <div className="form-group row">
        {/* Vendor Id */}
        {clients.privileges.create.indexOf(user.role) > -1 ? (
          <div className="col-lg-4">
            <label>Vendor</label>
            <Field
              component={SelectField}
              id="client_vendor_id"
              name="vendor_id"
              isDisabled={
                clients.privileges.create.indexOf(user.role) > -1 ? false : true
              }
              options={lookups["vendors"]?.reduce((prev, curr) => {
                prev.push({ value: curr.id, label: curr.name });
                return prev;
              }, [])}
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          </div>
        ) : null}
        {/* Client Name */}
        <div className="col-lg-4">
          <Field
            name="name"
            className={`form-control ${formikProps?.errors?.name ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Client Name"
            label="Client Name"
            disabled={
              clients.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Description */}
        <div className="col-lg-4">
          <Field
            name="description"
            className={`form-control ${formikProps?.errors?.description ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Description"
            label="Description"
            disabled={
              clients.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Email */}
        <div className="col-lg-4">
          <Field
            name="email"
            className={`form-control ${formikProps?.errors?.email ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Contact E-mail"
            label="Contact E-mail"
            disabled={
              clients.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Phone */}
        <div className="col-lg-4">
          <Field
            name="phonenumber"
            className={`form-control ${formikProps?.errors?.phonenumber ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Contact Phone"
            label="Contact Phone"
            disabled={
              clients.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Client GUID */}
        {clients.privileges.update.indexOf(user.role) > -1 ? (
          <div className="col-lg-4">
            <label htmlFor="client_guid">
              Client GUID
              {/* Only show copy button if there is something to copy */}
              {formikProps.values.client_guid ? (
                <span
                  className="inline-copy"
                  title="Copy Client GUID"
                  onClick={copyClientGUID}
                />
              ) : null}
            </label>
            <Field
              name="client_guid"
              className={`form-control ${formikProps?.errors?.client_guid ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Client GUID generates automatically"
              disabled={true}
            />
          </div>
        ) : null}
        {/* Client Status */}
        <div className="col-lg-4">
          <label>Status</label>
          <Field
            component={SelectField}
            id="client_status"
            name="status"
            isDisabled={
              clients.privileges.create.indexOf(user.role) > -1 ? false : true
            }
            options={stateEnums["data.client_status"].reduce((prev, curr) => {
              prev.push({ value: curr, label: curr });
              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          ></Field>
        </div>
        {/* Client Timezone */}
        <div className="col-lg-4">
          <label>Timezone</label>
          {lookups?.["timezones"]?.length > 0 ? (
            <Field
              component={SelectField}
              id="client_timezone"
              name="timezone"
              isDisabled={
                clients.privileges.create.indexOf(user.role) > -1 ? false : true
              }
              options={lookups["timezones"]?.reduce((prev, curr) => {
                prev.push({ value: curr.name, label: curr.name });
                return prev;
              }, [])}
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          ) : null}
        </div>
        {copyMode && allMissionIDs.length > 0 ? (
          <fieldset className="col-12 pt-5">
            <legend style={{ fontSize: "1rem" }}>Missions to Copy</legend>
            <label>
              <Checkbox
                id={`allChecked_checkbox`}
                key={`allChecked_checkbox`}
                onChange={handleAllChecked}
                checked={
                  _.isEqual(
                    _.sortBy(allMissionIDs),
                    _.sortBy(formikProps?.values?.missionsToCopy)
                  ) &&
                  _.isEqual(
                    _.sortBy(allFolderIDs),
                    _.sortBy(formikProps?.values?.foldersToCopy)
                  )
                }
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <strong>Select All Missions and Folders</strong>
            </label>
            <br />
            <label>
              <Checkbox
                id="copy_files"
                key="copy_files"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps?.values?.copy_files}
                checked={!!formikProps?.values?.copy_files}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <strong>Copy files with folders</strong>
            </label>
            <div
              style={{
                padding: "1em",
                maxHeight: "15rem",
                border: "solid lightgrey",
                borderRadius: "5px",
                overflowY: "auto", // to allow scrolling
              }}
            >
              <ul
                id="missionChoices"
                style={{
                  listStyleType: "none",
                  padding: 0,
                }}
              >
                {formikProps?.values?.missions.map((r) => {
                  return (
                    <React.Fragment key={"frag_" + r.id}>
                      <label key={"missions_" + r.id} className="w-100 mb-0">
                        <Checkbox
                          name="missionsToCopy"
                          value={r.id}
                          checked={
                            !!formikProps?.values?.missionsToCopy?.includes(
                              `${r.id}`
                            )
                          }
                          onChange={handleMissionChecked}
                          onBlur={formikProps.handleBlur}
                          key={"missions_check_" + r.id}
                        />
                        {r.name}
                      </label>
                      {formikProps?.values?.folders
                        ?.filter((x) => x?.mission_id === r?.id)
                        .map((f) => {
                          return (
                            <label
                              key={"folders_" + f.id}
                              style={{ paddingLeft: "3rem" }}
                              className="w-100"
                            >
                              <Checkbox
                                name="foldersToCopy"
                                key={"folders_check_" + f.id}
                                value={f.id}
                                checked={
                                  !!formikProps?.values?.foldersToCopy?.includes(
                                    `${f.id}`
                                  )
                                }
                                disabled={
                                  !formikProps?.values?.missionsToCopy?.includes(
                                    `${f.mission_id}`
                                  )
                                }
                                className="p-0 pr-2"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                              />
                              {f.name}
                            </label>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </fieldset>
        ) : null}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
        message={<span id="message-id">Client GUID copied to clipboard!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Form>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [showIdFeedback, setShowIdFeedback] = useState(false);
  const can_see_id = clients.privileges.update.indexOf(user.role) > -1;
  const client_id = formikProps.values.id;
  const title_text = title + (can_see_id && client_id ? " - " + client_id : "");
  const title_style = { display: "inline-block" };
  /**
   * Copy Selected client ID to clipboard
   * @param {*} e - event from onClick
   */
  const copyClientId = (e) => {
    e.preventDefault();

    let copied_id = formikProps.values.id;
    copy(copied_id).then(() => {
      setShowIdFeedback(true);
    });

    e.stopPropagation();
  };

  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text}
        {can_see_id && client_id ? (
          <span
            className="inline-copy"
            title="Copy Client ID"
            onClick={copyClientId}
          />
        ) : null}
      </Modal.Title>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showIdFeedback}
        onClose={() => setShowIdFeedback(false)}
        message={<span id="id-message-id">Client ID copied to clipboard!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowIdFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      vendor_id: Yup.string().required("Vendor is required"),
      name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Client name is required"),
      description: Yup.string()
        .min(3, "Minimum 3 symbols")
        //.max(50, "Maximum 50 symbols")
        .required("Description is required"),
      email: Yup.string()
        .min(3, "Minimum 3 symbols")
        .matches(
          /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$/,
          "Incorrect email format"
        )
        .required("Email is required"),
      phonenumber: Yup.string().matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        "Incorrect Phone Number Format"
      ),
    });
  };
}

export default clients;
