import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { objectsDict } from "./ObjectsUIHelpers";

const ObjectsUIContext = createContext();

export function useObjectsUIContext() {
  return useContext(ObjectsUIContext);
}

export const ObjectsUIConsumer = ObjectsUIContext.Consumer;

export function ObjectsUIProvider({ objectsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState({});
  const [ids, setIds] = useState([]);
  const [objecttype, setObjecttypeBase] = useState("");
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        //TODO: IS this necessary? Why have the wrapper around the useStates at all?
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const setObjecttype = useCallback((nextObjecttype) => {
    setObjecttypeBase((prevObjecttype) => {
      if (isFunction(nextObjecttype)) {
        nextObjecttype = nextObjecttype(prevObjecttype);
      }

      if (isEqual(prevObjecttype, nextObjecttype)) {
        return prevObjecttype;
      }

      if (nextObjecttype && nextObjecttype in objectsDict) {
        setQueryParamsBase(objectsDict[nextObjecttype].initialFilter);
      }

      return nextObjecttype;
    });
  }, []);

  const initObject = {};

  const value = {
    objecttype,
    setObjecttype,
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initObject,
    newObjectButtonClick: objectsUIEvents.newObjectButtonClick,
    openEditObjectDialog: objectsUIEvents.openEditObjectDialog,
    openCopyObjectDialog: objectsUIEvents.openCopyObjectDialog,
    openDeleteObjectDialog: objectsUIEvents.openDeleteObjectDialog,
    openDeleteObjectsDialog: objectsUIEvents.openDeleteObjectsDialog,
  };

  return (
    <ObjectsUIContext.Provider value={value}>
      {children}
    </ObjectsUIContext.Provider>
  );
}
