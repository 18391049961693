/**
 * Files: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr (2024)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import FileDisplayModal from "../../../partials/FileDisplayModal";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getBreadcrumb } from "../../partials/getBreadcrumb";
// import * as actions from "../../../_redux/objects/objectsActions";
import ExcelFileAnalysesExport from "../ExcelExportHelpers";

import { Input } from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";

import SelectField from "../../../partials/SelectField";
import _ from "lodash";
import { FileNameColumnFormatter } from "../objects-table/column-formatters";
import AnalysisCorrectionModal from "../../../partials/AnalysisCorrectionModal";
import { CorrectionDeleteModal } from "../../../partials/AnalysisCorrectionModal";

import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../../../_metronic/_partials/dropdowns/DropdownCustomToggler";

// import { objectsSlice } from "../../../_redux/objects/objectsSlice";
// const { actions: slice } = objectsSlice; //necessary for some reason

const objecttype = "files";

/**
 * files: An object that contains information about a file.
 *
 * @constructor
 */
const files = {
  objecttype: objecttype,
  title: getDynamicTitle,
  singular: "File",
  nameField: "filename",
  initObject: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFolderId = parseInt(urlParams.get("folder_id"));
    const client_id = parseInt(urlParams.get("client_id"));
    const vendor_id = parseInt(urlParams.get("vendor_id"));
    return {
      analysis: {},
      status: "loaded",
      client_id: client_id,
      vendor_id: vendor_id,
      folder_id: urlFolderId,
    };
  },
  actions: [
    {
      title: "Open Chat with this File",
      color: "primary",
      icon: "/media/svg/icons/Communication/Chat3.svg",
      displayable: () => "admin",
      onClick: (row, navigate, location, dispatch) => {
        const searchParams = new URLSearchParams(location.search);
        navigate({
          pathname: `/chatbot`,
          search: `?mission_id=${
            row?.folder?.mission?.id
          }&mission_name=${encodeURIComponent(
            row?.folder?.mission?.name
          )}&folder_id=${row?.folder?.id}&folder_name=${encodeURIComponent(
            row?.folder?.name
          )}&file_id=${row.id}&file_name=${encodeURIComponent(
            row.filename
          )}&client_id=${row.client_id}&vendor_id=${row.vendor_id}${
            searchParams.get("s")
              ? "&fis=" + encodeURIComponent(searchParams.get("s"))
              : ""
          }${
            searchParams.get("fs")
              ? "&fs=" + encodeURIComponent(searchParams.get("fs"))
              : ""
          }${
            searchParams.get("ms")
              ? "&ms=" + encodeURIComponent(searchParams.get("ms"))
              : ""
          }`,
        });
      },
    },
  ],
  privileges: {
    //Hardcoded
    create: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    copy: [],
    update: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    delete: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
  },
  initialFilter: {
    selectFields: [
      "id",
      " client_id",
      " vendor_id",
      " folder_id",
      " filename",
      " analysis",
      " status",
      " created_by",
      " created_at",
      " updated_by",
      " updated_at",
      "vendor:vendors(id,name,vendor_guid)",
      "client:clients(id,name,client_guid)",
      "folder:folders(id,name,mission:missions(id,name))",
      "folder_filter:folders(id,name)", // TODO: Should ObjectsCrud be smart enought to automatically add the _filter version if the embedded table.column is used as a filter?
    ],
    sortOrder: "asc", // asc||desc
    sortField: "filename",
    pageNumber: 1,
    pageSize: 100,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [
    "id",
    "filename",
    "analysis",
    "status",
    "created_at",
    "created_by",
    "updated_at",
    "updated_by",
  ],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: fileSaveObject,
  getValidateForm: getValidateForm,
};

/**
 * getDynamicTitle: Gets the title for the file object
 * @param {*} lookups
 * @param {*} navigate
 * @returns JSX with Breadcrumb link, and ReactSelect to allow Mission change within Prompts screen
 */
function getDynamicTitle() {
  return getBreadcrumb("files");
}

/**
 * Defines the file object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(props) {
  return [
    {
      dataField: "filename",
      text: "File Name",
      sort: true,
      formatter: FileNameColumnFormatter,
      formatExtraData: {
        setCurrentFileId: props.setCurrentFileId,
        setShowFileModal: props.setShowFileModal,
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => {
        let statusSpinner = "";
        if (
          row.status === "analyzing" ||
          row.status === "indexing" ||
          row.status === "loaded"
        ) {
          statusSpinner = <div className="spinner spinner-primary ml-2"></div>;
        }
        return (
          // <span className={getLabelCssClasses()}>
          <div style={{ display: "flex" }}>
            <div>{row.status}</div>
            {statusSpinner}
          </div>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "in(data.file_status)",
    },
    // hidden rows added to pass in client_id into row object
    {
      dataField: "client_id",
      text: "Client ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
  ];
}

/**
 * Does nothing, as files are saved asynchronously from upload, just ensures dialog hides
 */
function fileSaveObject(object, objecttype, user, schema, dispatch) {
  //Do nothing, as files are saved asynchronously from upload, just ensure dialog hides
  // dispatch(actions.objectCreated({ objecttype, object }));
}

/**
 * Creates the form to used to edit the file object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  id,
  formikProps,
  setInitialValues,
  copyMode,
  copiedObjectID,
}) {
  const { user, lookups, stateEnums, stateSchema } = useSelector(
    (state) => ({
      user: state.auth.user,
      lookups: state.objects.lookups,
      stateEnums: state.objects.enumTypes,
      stateSchema: state.objects.schema,
    }),
    shallowEqual
  );

  const urlParams = new URLSearchParams(window.location.search);

  const fileButton = useRef();
  const fileInput = useRef();
  const fileTimeout = useRef();
  const [showFileModal, setShowFileModal] = useState(false);
  const [showAnalysisCorrectionModal, setShowAnalysisCorrectionModal] =
    useState(false);
  const [showCorrectionDeleteModal, setShowCorrectionDeleteModal] =
    useState(false);
  const [currentFileId, setCurrentFileId] = useState(-1);
  const [currentFilePage, setCurrentFilePage] = useState(-1);
  const [currentFileSection, setCurrentFileSection] = useState("");

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [showAdvancedPanel, setShowAdvancedPanel] = useState(false);
  const [jsonRecord, setJsonRecord] = useState({}); // Maintain state of formikProps.values.analysis JSON, adding visual expand/collapse feature
  const [selectedAnalysisJson, setSelectedAnalysisJson] = useState({});
  const [selectedAnalysisJsonKey, setSelectedAnalysisJsonKey] = useState("");
  const [selectedAnalysisFileId, setSelectedAnalysisFileId] = useState(-1);

  // only need to re-render if:
  // - more analysis data comes (viewing screen while "analyzing" is still running")
  // - or if the expand/collapse triangles are clicked.
  useEffect(() => {
    if (formikProps?.values?.analysis) {
      setJsonRecord(formikProps.values.analysis);
    }
  }, [formikProps.values.analysis, jsonRecord]);

  useEffect(() => {
    const axios_cancel_source = new AbortController();
    // console.log("useEffect fired", id, formikProps?.values?.status);
    try {
      if (id && formikProps?.values?.status === "analyzing") {
        setTimeout(() => {
          // console.log("Refreshing analyzing/indexing file: ", refreshCounter);
          axios
            .get(`${process.env.REACT_APP_API_URL}/files/${id}`, {
              signal: axios_cancel_source?.signal,
            })
            .then((resp) => {
              formikProps.setValues(resp.data);
              setJsonRecord(resp.data?.analysis);
              if (
                resp.data.status === "analyzing" ||
                resp.data.status === "indexing"
              ) {
                setRefreshCounter(refreshCounter + 1);
              }
            })
            .catch((ex) => {
              if (!axios_cancel_source?.signal?.aborted) {
                console.error(ex);
              }
            });
        }, 3000);
      }
    } catch (ex) {
      if (!axios_cancel_source?.signal?.aborted) {
        console.error(ex);
      }
    }
    return function cleanUp() {
      axios_cancel_source.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const dispatch = useDispatch();

  /**
   * Returns the file you choose when you click on "choose file"
   * TODO: Fix the depreciated btoa string stuff
   *
   * @param {*} event the event that brings up the choose file dialog
   */
  const fileChosen = function (event) {
    if (event.target.files.length > 0) {
      event.persist();
      // loop over files in event.target.files and warn if a file is over the size limit
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];

        if (file?.size > 204857600) {
          /*100MB file limit*/
          alert(`${file.size} is over 100MB!`);
          return;
        }
      }

      // loop over files in event.target.files
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const objectToSave = _.cloneDeep(formikProps.values);

        if (file) {
          fileButton.current.innerText = file.name;
          formikProps.setFieldValue("filename", file.name);
          objectToSave["filename"] = file.name;

          const reader = new FileReader();

          reader.onload = async function (e) {
            const binaryString = e.target.result;
            formikProps.setFieldValue("contents", btoa(binaryString));
            objectToSave["contents"] = btoa(binaryString);
            saveObject(objectToSave, objecttype, user, stateSchema, dispatch);
          };

          reader.readAsBinaryString(file);
        }
      }
    } else {
      //no file chosen, reset fields
      fileButton.current.innerText = "Choose File";
      formikProps.setFieldValue("filename", "");
    }
  };

  // use setTimeout to click on the file input button as soon as form loads (in "new" mode)
  useEffect(() => {
    if (fileTimeout.current) {
      clearTimeout(fileTimeout.current);
    }

    if (!id && fileInput.current) {
      fileTimeout.current = setTimeout(() => {
        if (fileInput.current?.click) {
          fileInput.current.click();
        }
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Updates the analysis correction for the specified prompt
   * @param {*} jsonKey
   * @param {*} correctedAnswer
   * @param {*} correctedDetails
   */
  function updateAnalysisCorrection(
    jsonKey,
    correctedAnswer,
    correctedDetails
  ) {
    setJsonRecord((j) => {
      if (!correctedAnswer) {
        if (j[jsonKey]["correction"]) {
          delete j[jsonKey]["correction"];
        }
      } else {
        j[jsonKey]["correction"] = {};
        j[jsonKey]["correction"]["answer"] = correctedAnswer;
        j[jsonKey]["correction"]["supporting details"] = correctedDetails ?? "";
      }

      delete j[jsonKey]["show"];
      delete j[jsonKey]["showAnalysis"];
      return j;
    });
    setRefreshCounter(refreshCounter + 1);
  }

  return (
    <>
      <Form className="form form-label-right">
        {id ? (
          ""
        ) : (
          <div className="form-group row">
            {/* File Upload input */}
            <div className="col-lg-8">
              <div className="custom-file">
                <input
                  ref={fileInput}
                  type="file"
                  name="fileupload"
                  multiple={true}
                  className="custom-file-input"
                  id="customFile"
                  onChange={fileChosen}
                  accept={`.pdf,.md`} // ,.htm,.html,.docx,.pptx,.xlsx,.txt,.md,.xml,.json`}
                />
                <label
                  ref={fileButton}
                  className="custom-file-label text-truncate"
                  htmlFor="customFile"
                >
                  Choose file
                </label>
              </div>
            </div>
          </div>
        )}
        {/* File FileName */}
        {id && (
          <div className="form-group row">
            <div className="col-lg-8">
              <Field
                name="filename"
                className={`form-control ${
                  formikProps?.errors?.filename ? "is-invalid" : ""
                }`}
                component={Input}
                placeholder="filename"
                label="Filename"
                disabled={true}
              />
            </div>
            {/* File Status */}
            <div className="col-lg-4">
              <label>Status</label>
              <Field
                component={SelectField}
                id="file_status"
                name="status"
                isDisabled={true}
                options={stateEnums["data.file_status"].reduce((prev, curr) => {
                  prev.push({ value: curr, label: curr });
                  return prev;
                }, [])}
                setInitialValues={(val) => {
                  setInitialValues(val);
                }}
              ></Field>
            </div>
          </div>
        )}
        <div className="form-group row" style={{ display: "none" }}>
          {/* Client Id */}
          <div className="col-lg-4">
            <label>Client</label>
            <Field
              component={SelectField}
              id="file_client_id"
              name="client_id"
              isDisabled={
                files.privileges.create.indexOf(user.role) > -1 ? false : true
              }
              options={lookups["clients"]?.reduce((prev, curr) => {
                prev.push({ value: curr.id, label: curr.name });
                return prev;
              }, [])}
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          </div>
        </div>
        <div
          className="form-group row"
          style={{
            display: "none",
          }}
        >
          {/* Folder */}
          <div className="col-lg-4">
            <label>Folder</label>
            {lookups?.["folders"]?.length > 1 && (
              <Field
                component={SelectField}
                id="folder_id"
                name="folder_id"
                isDisabled={true}
                options={lookups["folders"]?.reduce((prev, curr) => {
                  prev.push({ value: curr.id, label: curr.name });
                  return prev;
                }, [])}
                setInitialValues={(val) => {
                  setInitialValues(val);
                }}
              ></Field>
            )}
          </div>
        </div>
        {id && formikProps?.values?.status !== "indexing" && (
          <div className="form-group row">
            {/* Start Analysis */}
            <div className="col-lg-4">
              <button
                id="start_analysis_button"
                type="button"
                className="btn btn-warning btn-block"
                onClick={() => {
                  formikProps.setFieldValue("analysis", {});
                  axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/rpc/analyze_file`,
                      {
                        p_clientid: formikProps?.values?.client_id,
                        p_fileid: formikProps?.values?.id,
                        p_reanalyze: true,
                      },
                      { headers: { "Content-Type": "application/json" } }
                    )
                    .then((resp) => {
                      // console.log("Analysis Started response: ", resp);
                      setRefreshCounter(refreshCounter + 1);
                      formikProps.setFieldValue("status", "analyzing");
                    });
                }}
              >
                {formikProps?.values?.status === "analyzed"
                  ? "Reanalyze File"
                  : "Analyze File"}
              </button>
            </div>
            <div
              className="col-lg-8 mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <ExcelFileAnalysesExport
                folder_id={formikProps?.values?.folder_id}
                folder_name={urlParams.get("folder_name")}
                file_id={formikProps?.values?.id}
                file_name={formikProps?.values?.filename}
              />
            </div>
          </div>
        )}
        {(formikProps?.values?.status === "analyzing" ||
          formikProps?.values?.status === "indexing" ||
          formikProps?.values?.status === "analyzed") && (
          <div className="form-group row">
            {/* Analysis Summary */}
            <div className="col-lg-12">
              {formikProps?.values?.status === "analyzing" && (
                <div className="d-flex">
                  <div className="spinner spinner-primary mr-10"></div>
                  <div>Analyzing...</div>
                </div>
              )}
              {formikProps?.values?.status === "indexing" && (
                <div className="d-flex">
                  <div className="spinner spinner-primary mr-10"></div>
                  <div>Indexing...</div>
                </div>
              )}
              {(formikProps?.values?.status === "analyzed" ||
                formikProps?.values?.status === "analyzing") &&
                formikProps?.values?.analysis &&
                Object.keys(jsonRecord)
                  .sort()
                  .map((item, index) => {
                    if (jsonRecord?.[item]?.llm_response) {
                      return (
                        <div
                          key={index}
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <div
                            style={{
                              border: "1px solid #e4e4e4",
                              borderBottom: "none",
                              padding: "5px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer", width: "25%" }}
                              title={`Click to ${
                                (jsonRecord?.[item]?.show ?? false)
                                  ? "hide"
                                  : "show"
                              } ${item} sources and supporting details`}
                              onClick={() => {
                                let isShowing =
                                  jsonRecord?.[item]?.show ?? false;
                                // expand the jsonRecord to include the "show" property
                                jsonRecord[item]["show"] = !isShowing;
                                setJsonRecord({ ...jsonRecord });
                              }}
                            >
                              {(jsonRecord?.[item]?.show ?? false)
                                ? "▼ "
                                : "▶ "}
                              <b>{item}</b>:{" "}
                              {jsonRecord?.[item]?.correction?.answer && (
                                <b>*</b>
                              )}
                            </div>
                            <div style={{ width: "75%" }}>
                              {`${jsonRecord?.[item]?.correction?.answer ? jsonRecord?.[item]?.correction?.answer : jsonRecord?.[item]?.llm_response?.answer}`}
                            </div>

                            <Dropdown className="dropdown-inline" align="end">
                              <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                variant="transparent"
                                id="dropdown-toggle-top"
                                as={DropdownCustomToggler}
                              >
                                <i className="ki ki-bold-more-hor" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <>
                                  {/*begin::Navigation*/}
                                  <ul
                                    className="navi navi-hover"
                                    style={{ padding: 0 }}
                                  >
                                    <li
                                      className="navi-header font-weight-bold px-2 py-2"
                                      title={`${jsonRecord?.[item]?.correction?.answer ? "Edit the correction" : "Correct the answer"} of this prompt`}
                                      onClick={() => {
                                        if (
                                          selectedAnalysisFileId !==
                                          formikProps?.values?.id
                                        ) {
                                          setSelectedAnalysisFileId(
                                            formikProps?.values?.id
                                          );
                                        }
                                        setSelectedAnalysisJson(
                                          jsonRecord?.[item]
                                        );
                                        setSelectedAnalysisJsonKey(item);
                                        setShowAnalysisCorrectionModal(true);
                                      }}
                                      hidden={user.role === "webuser"}
                                    >
                                      <span
                                        className="font-size-sm btn btn-primary create-correction-btn"
                                        style={{ width: "100%" }}
                                      >
                                        {jsonRecord?.[item]?.correction?.answer
                                          ? "Edit Correction"
                                          : "Correct Analysis"}
                                      </span>
                                    </li>
                                    {jsonRecord?.[item]?.correction?.answer && (
                                      <li
                                        className="navi-header font-weight-bold px-2 py-2"
                                        title={`Delete the correction make to this prompt`}
                                        onClick={() => {
                                          if (
                                            selectedAnalysisFileId !==
                                            formikProps?.values?.id
                                          ) {
                                            setSelectedAnalysisFileId(
                                              formikProps?.values?.id
                                            );
                                          }
                                          setSelectedAnalysisJson(
                                            jsonRecord?.[item]
                                          );
                                          setSelectedAnalysisJsonKey(item);
                                          setShowCorrectionDeleteModal(true);
                                        }}
                                        hidden={user.role === "webuser"}
                                      >
                                        <span
                                          className="font-size-sm btn btn-danger delete-correction-btn"
                                          style={{ width: "100%" }}
                                        >
                                          Remove Correction
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {(jsonRecord?.[item]?.show ?? false) && (
                            <div
                              style={{
                                border: "1px solid #e4e4e4",
                                borderBottom: "none",
                                padding: "5px",
                                backgroundColor: "#f9f9f9",
                              }}
                            >
                              {jsonRecord?.[item]?.correction?.answer && (
                                <>
                                  <div className="mb-2 font-italic">
                                    * This answer was manually corrected
                                  </div>
                                  <div className="ml-4 font-italic">
                                    (
                                    {`${jsonRecord?.[item]?.correction?.["supporting details"]}` ??
                                      ""}
                                    )
                                  </div>
                                  <hr />
                                  <div className="ml-4">
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        width: "25%",
                                      }}
                                      title={`Click to ${
                                        (jsonRecord?.[item]?.showAnalysis ??
                                        false)
                                          ? "hide"
                                          : "show"
                                      } details from the original generated analysis`}
                                      onClick={() => {
                                        let isShowing =
                                          jsonRecord?.[item]?.showAnalysis ??
                                          false;
                                        // expand the jsonRecord to include the "show" property
                                        jsonRecord[item]["showAnalysis"] =
                                          !isShowing;
                                        setJsonRecord({ ...jsonRecord });
                                      }}
                                    >
                                      <span style={{ color: "#ffa800" }}>
                                        {(jsonRecord?.[item]?.showAnalysis ??
                                        false)
                                          ? "▼ "
                                          : "▶ "}
                                      </span>
                                      <strong style={{ fontWeight: "bold" }}>
                                        Original Generated Analysis:
                                      </strong>
                                    </div>
                                  </div>
                                </>
                              )}
                              {jsonRecord?.[item]?.correction?.answer &&
                              (jsonRecord?.[item]?.showAnalysis ?? false) ? (
                                <>
                                  <div className="ml-4">
                                    {jsonRecord?.[item]?.llm_response?.answer}
                                  </div>
                                  <br />
                                </>
                              ) : (
                                ""
                              )}
                              {((jsonRecord?.[item]?.showAnalysis ?? false) ||
                                !jsonRecord?.[item]?.correction?.answer) && (
                                <>
                                  <div className="ml-4 font-italic">
                                    (
                                    {`${jsonRecord?.[item]?.llm_response?.["supporting details"]}`}
                                    )
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {jsonRecord?.[item]?.vector_db_context?.map(
                                      (source, source_index) => {
                                        if (
                                          !source?.skip &&
                                          (!source?.relevanceScore ||
                                            source?.relevanceScore > 50)
                                        ) {
                                          return (
                                            <div
                                              title={
                                                source?._source?.metadata
                                                  ?.original_contents
                                              }
                                              key={`chunk_${index}_source_${source_index}`}
                                              style={{
                                                borderRadius: "5px",
                                                border: "1px solid #e4e4e4",
                                                backgroundColor: "#ffffff",
                                                padding: "5px",
                                                margin: "5px",
                                                width: "250px",
                                              }}
                                            >
                                              <small
                                                onClick={() => {
                                                  if (
                                                    currentFileId !==
                                                    source?._source?.file_id
                                                  ) {
                                                    setCurrentFileId(
                                                      source?._source?.file_id
                                                    );
                                                  }
                                                  if (
                                                    currentFilePage !==
                                                    source?._source?.metadata
                                                      ?.page_number
                                                  ) {
                                                    setCurrentFilePage(
                                                      source?._source?.metadata
                                                        ?.page_number
                                                    );
                                                  }
                                                  if (
                                                    currentFileSection !==
                                                    source?._source?.metadata
                                                      ?.section_title
                                                  ) {
                                                    setCurrentFileSection(
                                                      source?._source?.metadata
                                                        ?.section_title
                                                    );
                                                  }
                                                  setShowFileModal(true);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Page #
                                                {
                                                  source?._source?.metadata
                                                    ?.page_number
                                                }
                                                {" - "}"
                                                {
                                                  source?._source?.metadata
                                                    ?.section_title
                                                }
                                                "
                                              </small>
                                              <br />
                                              <span
                                                style={{
                                                  width: "100%",
                                                  display: "block",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                <small>
                                                  {
                                                    source?._source?.metadata
                                                      ?.original_contents
                                                  }
                                                </small>
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          // Return empty span
                                          return (
                                            <span
                                              key={`chunk_${index}_source_${source_index}`}
                                            ></span>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      return ""; // Return empty string for non-llm_response items
                    }
                  })}
            </div>
          </div>
        )}
        {formikProps?.values && (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setShowAdvancedPanel(!showAdvancedPanel);
            }}
            id="showMoreButton"
          >
            {showAdvancedPanel ? "▼ " : "▶ "}{" "}
            <strong>Show Debugging Information</strong>
          </span>
        )}
        {showAdvancedPanel && formikProps?.values && (
          <div className="form-group row">
            {/* JSON Analysis */}
            <div className="col-lg-12" id="fileEditorWrapper">
              <label>JSON Analysis for File</label>
              {/* expand all button is hidden by style in _variables.demo.scss */}
              <Editor
                sortObjectKeys={true}
                value={formikProps?.values?.analysis ?? {}}
                onChange={(data) => {
                  formikProps.setFieldValue("analysis", data);
                }}
                htmlElementProps={{ style: { minHeight: "100px" } }}
                allowedModes={
                  files.privileges.update.indexOf(user.role) > -1
                    ? ["view", "text"]
                    : ["view"]
                }
                mode={"view"}
                search={false}
              />
            </div>
          </div>
        )}
      </Form>
      <FileDisplayModal
        showFileModal={showFileModal}
        setShowFileModal={setShowFileModal}
        fileId={currentFileId}
        filePage={currentFilePage}
        fileSection={currentFileSection}
      />
      <AnalysisCorrectionModal
        showAnalysisModal={showAnalysisCorrectionModal}
        setShowAnalysisModal={setShowAnalysisCorrectionModal}
        analysisJson={selectedAnalysisJson}
        analysisJsonKey={selectedAnalysisJsonKey}
        fileId={selectedAnalysisFileId}
        fileName={formikProps?.values?.filename}
        addAnalysisCorrection={updateAnalysisCorrection}
      />
      <CorrectionDeleteModal
        show={showCorrectionDeleteModal}
        onHide={() => {
          setShowCorrectionDeleteModal(false);
        }}
        analysisJson={selectedAnalysisJson}
        analysisJsonKey={selectedAnalysisJsonKey}
        fileId={selectedAnalysisFileId}
        addAnalysisCorrection={updateAnalysisCorrection}
      />
    </>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const folder_title_name = new URLSearchParams(useLocation().search).get(
    "folder_name"
  );
  const title_text = title;
  const title_style = { display: "inline-block" };
  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text
          .replace(
            /(^.*File ['])([^']+)(['])/,
            `${folder_title_name ? folder_title_name + " / " : ""}$2 Report`
          )
          .replace(
            /(.*File$)/,
            `${folder_title_name ? folder_title_name + " / " : ""}$1`
          )}
      </Modal.Title>
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      filename: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(150, "Maximum 150 symbols")
        .required("File name is required"),
      folder_id: Yup.string().required("Folder is required"),
    });
  };
}

export default files;
