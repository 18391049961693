/**
 * objectsActions: Actions related server requests. Directly tied to objectsSlice.js
 *
 * @authors  Tyler Carr(), Xavier Clark (Nov 2021)
 */
import * as requestFromServer from "./objectsCrud";
import { objectsSlice } from "./objectsSlice";
import { shallowEqual, useSelector } from "react-redux";
import { addClientTimezoneOffset } from "../util/timezoneOffsets";

const { actions } = objectsSlice;

/**
 * loadPostgRESTapi: Loads the PostgREST API "dictionary" of what tables, functions, and data types exist in the database
 * - Especially useful for ENUM DB types like user status (pending, active, locked, etc) to populate drop-down selects in object editor form
 */
export const loadPostgRESTapi = () => (dispatch) => {
  const { stateSchema } = useSelector(
    (state) => ({ stateSchema: state.objects.schema }),
    shallowEqual
  );
  if (stateSchema) {
    return stateSchema;
  } else {
    return requestFromServer.getPostgRESTapi().then((response) => {
      const schema = response.data;
      dispatch(actions.setRestSchema({ schema }));
    });
  }
};

/**
 * fetchObjects: Fetch a list of objects of a certain type.
 * Needs client / vendor id to authorize user can actually see the stuff they want to.
 * @param {*} objecttype - Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} queryParams - Query filters (range number of rows, where clause to restrict search, etc)
 * @param {*} stateEnums - loadPostgRESTapi result's ENUM data types to handle filtering pattern matching for ENUM columns in where clause
 * @param {*} stateSchema - loadPostgRESTapi result's table column data to allow checking objecttype table's client_id/vendor_id column existence
 * @param {*} session_vendor_id - Matcha vendor id associated with logged-in user session (to filter data rows to expose only appropriate clients' data)
 * @param {*} session_client_id - Matcha client id associated with logged-in user session (to filter data rows to expose only the specified clients' data)
 * @param {*} axios_cancel_source - Axios cancel token source to allow for cancelling in-flight requests
 * @param {*} locationSearchParams - URL search parameters to allow for filtering data based on URL query string
 */
export const fetchObjects =
  (
    objecttype,
    queryParams,
    stateEnums,
    stateSchema,
    session_vendor_id,
    session_client_id,
    axios_cancel_source,
    locationSearchParams,
    user
  ) =>
  (dispatch) => {
    dispatch(actions.startCallList());
    return requestFromServer
      .findObjects(
        objecttype,
        queryParams,
        stateEnums,
        stateSchema,
        session_vendor_id,
        session_client_id,
        axios_cancel_source,
        locationSearchParams,
        user,
        true
      )
      .then((response) => {
        // console.log(`console debugging for mockObjects.js population`)
        // console.log(response.headers['content-range'])
        // console.log(JSON.stringify(response.data,null,2))
        const totalCount = parseInt(
          response.headers["content-range"].replace(/^.*[/]/, "")
        );
        const entities = response.data;
        // Check if the search text is set
        const isFiltered = !!queryParams.searchText;
        dispatch(actions.objectsFetched({ totalCount, entities, isFiltered }));
      })
      .catch((error) => {
        if (!axios_cancel_source?.signal?.aborted) {
          error.clientMessage = `Can't find ${objecttype} objects`;
          dispatch(actions.catchErrorList({ error }));
        }
      });
  };

/**
 * appendLookup saves a round-trip call to the server every time an object is inserted into a look-up list
 * - primary use case is adding keyword tags to contentuploads, keyword is added to redux lookupList in session memory before data is persisted/saved to backend tables
 * @param {*} objecttype the objectType for which you'd like to append a lookuptables entry
 * @param {*} lookups the current state of the look-up list specific to the objectType
 * @param {*} lookupitem the new lookup item entry (key/value pair) to insert/splice into the look-up list (insert to preserve alphabetical order)
 * @param {*} strKey the lookup list entry's key identifier (i.e. "keywordchoice")
 * @returns
 */
export const appendLookup =
  (objecttype, lookups, lookupitem, strKey) => (dispatch) => {
    if (lookups && lookups[objecttype]) {
      const newLookupList = [...lookups[objecttype]];
      for (let i = 0; i < newLookupList.length; ++i) {
        if (
          newLookupList[i] &&
          newLookupList[i][strKey] &&
          newLookupList[i][strKey] > lookupitem[strKey]
        ) {
          newLookupList.splice(i, 0, lookupitem);
          dispatch(
            actions.lookupFetched({ objecttype, entities: newLookupList })
          );
          break;
        } else if (
          newLookupList[i] &&
          newLookupList[i][strKey] &&
          newLookupList[i][strKey] === lookupitem[strKey]
        ) {
          break;
        }
      }
    }
  };

/**
 * fetchLookup: fetch a list of all of the attributes for a certain objectType.
 * Doesn't make a server request if you already have the lookups for that objectType
 * @param {*} objecttype the objectType for which you'd like to append a lookuptables entry
 * @param {*} queryParams Query filters (range number of rows, where clause to restrict search, etc)
 * @param {*} stateEnums loadPostgRESTapi result's ENUM data types to handle filtering pattern matching for ENUM columns in where clause
 * @param {*} lookups the current state of the look-up list specific to the objectType
 * @param {*} stateSchema loadPostgRESTapi result's table column data to allow checking objecttype table's client_id/vendor_id column existence
 * @param {*} session_vendor_id Matcha vendor id associated with logged-in user session (to filter data rows to expose only appropriate clients' data)
 * @param {*} session_client_id Matcha client id associated with logged-in user session (to filter data rows to expose only the specified clients' data)
 * @param {*} user the user from the redux store
 * @param {*} axios_cancel_source Axios cancel token source to allow for cancelling in-flight requests
 * @param {boolean} fromClientPickerLookup whether the lookup is from the client picker
 * @returns
 */
export const fetchLookup =
  (
    objecttype,
    queryParams,
    stateEnums,
    lookups,
    stateSchema,
    session_vendor_id,
    session_client_id,
    user,
    axios_cancel_source,
    fromClientPickerLookup = false
  ) =>
  (dispatch) => {
    if (!stateSchema) {
      //No state schema loaded yet in redux, cannot query looks until schema has loaded
      return null;
    }
    if (lookups && lookups[objecttype]) {
      return lookups[objecttype];
    } else {
      dispatch(actions.lookupLoading({ objecttype }));
      return requestFromServer
        .findObjects(
          objecttype,
          queryParams,
          stateEnums,
          stateSchema,
          session_vendor_id,
          session_client_id,
          axios_cancel_source,
          undefined,
          user,
          fromClientPickerLookup
        )
        .then((response) => {
          const entities = response.data;
          dispatch(actions.lookupFetched({ objecttype, entities }));
        })
        .catch((error) => {
          error.clientMessage = `Can't find ${objecttype} objects`;
          dispatch(actions.catchErrorList({ error }));
        });
    }
  };

/**
 * fetchObject: fetch the data for a specific object
 * if no id, return an undefined object
 * @param {*} objecttype Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} id the id for the object you'd like to fetch
 */
export const fetchObject = (objecttype, id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.objectFetched({ objectForEdit: {} }));
  }

  dispatch(actions.startCallAction());
  return requestFromServer
    .getObjectById(objecttype, id)
    .then((response) => {
      const object = response.data;
      //Filter out date/time fields
      for (const itemkey in response.data) {
        if (
          String(response.data[itemkey]).match(
            /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0-9]{2}[:][0-9]{2}[:][0-9]{2}[.][0-9]{0,6}$/g
          )
        ) {
          //Clean up YYYY-MM-DDTHH:mm:ss (without timezone) -> YYYY-MM-DDtHH:mm
          response.data[itemkey] = String(response.data[itemkey]).replaceAll(
            /^([0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0-9]{2}[:][0-9]{2}[:][0-9]{2})[.][0-9]{0,6}$/g,
            "$1"
          );
        }
        if (
          String(response.data[itemkey]).match(
            /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0-9]{2}[:][0-9]{2}[:][0-9]{2}([.][0-9]{0,6})*[ ]*[+-][0-9]{2}[:][0-9]{2}$/g
          )
        ) {
          //Clean up YYYY-MM-DDTHH:mm:ss(.ssssss)+00:00 (with timezone) -> YYYY-MM-DDtHH:mm (in local time of client!)
          response.data[itemkey] = new Date(response.data[itemkey])
            .toLocaleString("sv")
            .replaceAll(" ", "T"); // Sweden formats dates like ISO 8601 YYYY-MM-DDTHH:mm
        }
      }
      dispatch(actions.objectFetched({ objectForEdit: object }));
    })
    .catch((error) => {
      error.clientMessage = `Can't find ${objecttype} object`;
      dispatch(actions.catchErrorAction({ error }));
    });
};

/**
 * deleteObject: delete an object from the database
 * @param {*} objecttype Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} id the id for that object you'd like to delete
 */
export const deleteObject = (objecttype, id) => (dispatch) => {
  dispatch(actions.startCallSave());
  return requestFromServer
    .deleteObject(objecttype, id)
    .then((response) => {
      dispatch(actions.objectDeleted({ objecttype, id }));
      dispatch(actions.expireAllLookups());
    })
    .catch((error) => {
      error.clientMessage = `Can't delete ${objecttype} object`;
      dispatch(actions.catchErrorSave({ error }));
    });
};

/**
 * deleteObjects: delete multiple objects with the same objectType from the database
 * @param {*} objecttype Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} ids the list of IDs for those objects you'd like to delete
 */
export const deleteObjects = (objecttype, ids) => (dispatch) => {
  dispatch(actions.startCallSave());
  return requestFromServer
    .deleteObjects(objecttype, ids)
    .then(() => {
      dispatch(actions.objectsDeleted({ objecttype, ids }));
      dispatch(actions.expireAllLookups());
    })
    .catch((error) => {
      error.clientMessage = `Can't delete ${objecttype} objects`;
      dispatch(actions.catchErrorSave({ error }));
    });
};

/**
 * objectCreated: call to close dialog when object passed is null
 * @param {*} objecttype - Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} object  - the object you'd like to create (but null in this case)
 */
export const objectCreated = (objecttype, object) => (dispatch) => {
  dispatch(actions.objectCreated({ objecttype, object }));
};

/**
 * createObject: create a new object of a certain type and store it in the database.
 * @param {*} objecttype Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} objectForCreation the object you'd like to create (store in the database)
 * @returns
 */
export const createObject =
  (objecttype, objectForCreation, skipEntitiesUpdate = false) =>
  (dispatch) => {
    dispatch(actions.startCallSave());
    for (const itemkey in objectForCreation) {
      if (
        String(objectForCreation[itemkey]).match(
          /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0-9]{2}[:][0-9]{2}[^+-]*$/g
        )
      ) {
        //Add the clients current timezone to timestamp fields
        objectForCreation[itemkey] = addClientTimezoneOffset(
          objectForCreation[itemkey]
        );
      }
    }
    return requestFromServer
      .createObject(objecttype, objectForCreation)
      .then((response) => {
        const object = response.data[0];
        dispatch(actions.objectCreated({ objecttype, object }));
        if (!skipEntitiesUpdate) {
          dispatch(actions.pushEntities({ object }));
        }
        dispatch(actions.expireAllLookups());
        return object;
      })
      .catch((error) => {
        error.clientMessage = `Can't create ${objecttype} object`;
        dispatch(actions.catchErrorSave({ error }));
        return error;
      });
  };

/**
 * updateObject: update the content of a certain object, such as changing the name of an existing client.
 * @param {*} objecttype Valid object type defined in ObjectUIHelpers.js' objectsDict
 * @param {*} object the specific object you'd like tp update
 */
export const updateObject = (objecttype, object) => (dispatch) => {
  dispatch(actions.startCallSave());
  for (const itemkey in object) {
    if (
      String(object[itemkey]).match(
        /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0-9]{2}[:][0-9]{2}[^+-]*$/g
      )
    ) {
      //Add the clients current timezone to timestamp fields
      object[itemkey] = addClientTimezoneOffset(object[itemkey]);
    }
  }

  return requestFromServer
    .updateObject(objecttype, object)
    .then((res) => {
      dispatch(actions.objectUpdated({ objecttype, object: res?.data }));
      dispatch(actions.expireLookup({ objecttype }));
      return res?.data;
    })
    .catch((error) => {
      error.clientMessage = `Can't update ${objecttype} object`;
      dispatch(actions.catchErrorSave({ error }));
      return error;
    });
};

/**
 * resetObjectErrors: Reset any errors that occured during a server request.
 * Usually done when a user clicks "ok" on an error message.
 */
export const resetObjectsErrors = () => (dispatch) => {
  dispatch(actions.resetErrors());
};

/**
 * resetHideDialog: reset the "hide dialog" redux flag, used for signalling when a form should close (such as after submitting/saving an object)
 * This function resets that flag so it can be used again later
 */
export const resetHideDialog = () => (dispatch) => {
  dispatch(actions.resetHideDialog());
};
