/**
 * Dropdown for "filter objects by client/vendor" in the topbar
 * Also uses sessionStorage to maintain filter on refresh
 * (code for removal on login in Login.js, line ~78)
 * @author Xavier Clark (2022), Tyler Carr (2021)
 */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";

import lookupFetch from "../../../../app/modules/Matcha/pages/objects/object-edit-dialog/lookupFetch";
import * as authredux from "../../../../app/modules/Auth/_redux/authRedux";
import { objectsSlice } from "../../../../app/modules/Matcha/_redux/objects/objectsSlice.js";
const { actions: slice } = objectsSlice; //necessary for some reason

export function ClientPicker() {
  const {
    vendor_name,
    client_name,
    lookups,
    client_ids,
    stateEnums,
    schema,
    session_vendor_id,
    session_client_id,
    user,
  } = useSelector(
    (state) => ({
      vendor_name: state.auth.vendor_name,
      client_name: state.auth.client_name,
      lookups: state.objects.lookups,
      client_ids: state.auth.user.client_ids,
      stateEnums: state.objects.enumTypes,
      schema: state.objects.schema,
      session_vendor_id: state.auth.vendor_id,
      session_client_id: state.auth.client_id,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectRef = useRef(); //ref for Select component, used to highlight text box in select
  const [show, setShow] = useState(false); //show the menu for the dropdown
  const [clientList, setClientList] = useState([]); //list of clients to filter by

  useEffect(() => {
    const axios_cancel_source = new AbortController();
    const lookup_picker_clients = async () => {
      // Manually call axios lookup on ALL clients
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/clients`,

          {
            params: {
              order: "name.asc",
              select:
                "id,name,client_guid,vendor_id,vendor:vendors(id,name,vendor_guid),timezone",
            },
            headers: { "Content-Type": "application/json" },
            signal: axios_cancel_source?.signal,
          }
        )
        .then((resp) => {
          if (resp?.data?.length > 0) {
            const updatedClientList = resp.data.reduce((acc, cur) => {
              acc.push({
                label: cur.name,
                value: cur,
              });
              return acc;
            }, []);
            updatedClientList.unshift({ label: "View All", value: -1 });
            setClientList(updatedClientList);
          }
        })
        .catch((err) => {
          if (!axios_cancel_source?.signal?.aborted) {
            console.error("Client Picker client lookup error: ", err);
          }
        });
    };
    lookup_picker_clients();
    lookupFetch(
      dispatch,
      lookups,
      stateEnums,
      schema,
      session_vendor_id,
      session_client_id,
      user,
      undefined, //axios_cancel_source, // Do not cancel look-ups on unmount
      false // Set fromLookup to false, because objectsCrud needs it that way to reload all lookups correctly, including folders/prompts mission_id column
    );
    return function cleanUp() {
      axios_cancel_source.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, session_vendor_id, session_client_id, lookups]);

  //Reset client / vendor filter on refresh
  useEffect(() => {
    const sessionFilter = JSON.parse(sessionStorage.getItem("sessionFilter"));
    if (sessionFilter) {
      selectFilter(sessionFilter, false);
    } else if (!client_ids?.match(/^\^[0-9]+\^$/)) {
      // if the user has a client_ids (web*), then set the default filter to "View All"
      selectFilter(-1, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_name, vendor_name]); // honestly this maybe should be different props now

  /**
   * Changes the vendor or client the user is filtering by based on which option they clicked in the dropdown.
   *
   * @param {json} row The option clicked on within the react-select
   */
  function selectFilter(row, redirectToMission) {
    const searchParams = new URLSearchParams(location.search);
    // Update client_id in URL search params if present
    if (searchParams.get("client_id")) {
      if (row?.value?.id >= 0) {
        searchParams.set("client_id", row.value.id);
      }
      //Update client ID in searchParams, and if changing client in Matcha context (not admin settings), return user to missions page
      if (
        !location.pathname.includes("/settings/objects/") &&
        redirectToMission
      ) {
        navigate("/matcha/objects/missions", {
          // cannot keep searchParams, otherwise the missions will still be filtered by client
          replace: true,
        });
      } else {
        navigate("?" + searchParams.toString(), { replace: true });
      }
    }
    sessionStorage.setItem("sessionFilter", JSON.stringify(row));
    setShow(false);
    if (row?.value?.vendor_id) {
      dispatch(authredux.actions.setClient(row.value, row.value.vendor));
    } else {
      dispatch(authredux.actions.setVendor(row.value));
    }
    dispatch(slice.expireAllLookups());
  }

  return (
    <>
      {!client_ids?.match(/^\^[0-9]+\^$/) ? (
        <>
          <Dropdown
            className="d-flex align-items-center"
            style={{ marginRight: "3px" }}
            show={show}
            onBlur={(e) => {
              //to make sure action isn't coming from child before hiding
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setShow(false);
              }
            }}
          >
            <Dropdown.Toggle
              variant="light"
              size="md"
              className=""
              id="kt_dashboard_clientpicker"
              onClick={() => {
                //toggle
                setShow(!show);
                setTimeout(() => selectRef?.current?.focus(), 10);
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: "0.5px",
                  paddingBottom: "0.5px",
                }}
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                  }}
                >
                  <SVG
                    src={toAbsoluteUrl(`/media/svg/icons/Text/Filter.svg`)}
                  />
                </div>
                {/* client_name set to null by authRedux.js setVendor(), must have chosen a vendor */}
                <div style={{ whiteSpace: "nowrap" }}>
                  {client_name ?? vendor_name ?? "Viewing All"}
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu renderOnMount>
              <Select
                ref={selectRef}
                name="client_filter"
                id="client_filter_selector"
                className={"react-select-cursor"}
                autoFocus
                menuIsOpen
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                placeholder="Search..."
                tabSelectsValue={false}
                components={{
                  DropdownIndicator: null,
                  IndicatorSeparator: null,
                }}
                options={clientList.reduce((acc, cur) => {
                  if (
                    (!cur.value?.id && session_client_id === null) || // don't show view all as a clickable option if already selected
                    cur.value?.id === session_client_id // don't show current client as a clickable option
                  ) {
                    acc.push({ ...cur, isDisabled: true });
                  } else {
                    acc.push(cur);
                  }
                  return acc;
                }, [])}
                onChange={(val) => selectFilter(val, true)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minWidth: 240,
                    margin: 8,
                  }),
                  menu: () => ({
                    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
                  }),
                }}
              ></Select>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{ marginRight: "3px", pointerEvents: "none" }}
        >
          <div className="btn btn-light btn-md text-primary font-weight-bold">
            <div
              style={{
                display: "inline-block",
                position: "relative",
                width: "25px",
                height: "20px",
              }}
            >
              <SVG
                style={{ position: "absolute", top: "2px", left: "0px" }}
                src={toAbsoluteUrl(`/media/svg/icons/Home/Building.svg`)}
              />
            </div>
            <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
              {client_name}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
