/**
 * Users: Information regarding users (individuals recieving bills)
 *
 * @author Xavier Clark(2021)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

import { Input } from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";

import "jsoneditor-react/es/editor.min.css";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import { requestPassword } from "../../../../Auth/_redux/authCrud";
import SelectField from "../../../partials/SelectField";

const objecttype = "users";

/**
 * users: An object that contains information about a user.
 *
 * @constructor
 */
const users = {
  objecttype: objecttype,
  title: "Users",
  singular: "User",
  nameField: "email",
  initObject: {
    status: "pending",
    password: "ResetPassword123!",
    client_ids: [],
    vendor_id: undefined,
    phone: undefined,
  },
  privileges: {
    create: ["matchaadmin", "vendoradmin", "webadmin"],
    copy: [],
    update: ["matchaadmin", "vendoradmin", "webadmin"],
    delete: ["matchaadmin", "vendoradmin"],
  },
  initialFilter: {
    selectFields: [
      //The columns you'd like to get for each row inside objectTable. Like SQL "select"
      "*",
    ],
    sortOrder: "asc", // asc||desc
    sortField: "email",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [
    "id",
    "email",
    "status",
    "password",
    "phone",
    "firstname",
    "lastname",
    "created_at",
  ],
  GetForm: GetForm,
  beforeSave: beforeSave,
  saveObject: saveObject,
  getValidateForm: getValidateForm,
};

/**
 * Defines the user object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(_props) {
  return [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "in(data.user_status)",
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "in(data.user_role)",
    },
    {
      dataField: "firstname",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
  ];
}

function beforeSave(formikProps, videoState) {
  if (formikProps.values.client_ids) {
    formikProps.setFieldValue(
      "client_ids",
      formikProps.values.client_ids.reduce((prev, curr) => {
        if (prev === "") {
          prev = "^";
        }

        prev += curr.value + "^";
        return prev;
      }, "")
    );
  } else {
    formikProps.setFieldValue("client_ids", "");
  }
}

/**
 * Creates the form to view/edit the User object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetForm({
  id, //unused for now but added in getform call
  formikProps,
  setInitialValues,
}) {
  const { user, stateEnums, lookups } = useSelector(
    (state) => ({
      user: state.auth.user,
      stateEnums: state.objects.enumTypes,
      lookups: state.objects.lookups,
    }),
    shallowEqual()
  );
  const [clientsValues, setClientsValues] = useState([]);
  const vendorRef = useRef();
  const clientsRef = useRef();

  /**
   * Turns all client ids into an list that can be handled by react-select <Select> elements
   *
   * @param {*} clientArray the client ids you want in a list
   */
  const clientsToValue = useCallback((clientArray, clientOptionArray) => {
    let clientValueList = [];
    if (clientArray) {
      if (Array.isArray(clientArray)) {
        if (typeof clientArray[0] === "number") {
          clientArray.forEach((clientid) => {
            let clientOption = clientOptionArray?.find(
              (element) =>
                Number.parseInt(element.value, 10) ===
                Number.parseInt(clientid, 10)
            );
            if (clientOption) {
              clientValueList.push(clientOption);
            }
          });
        } else {
          clientArray.forEach((clientid) => {
            let clientOption = clientOptionArray?.find(
              (element) =>
                Number.parseInt(element.value, 10) ===
                Number.parseInt(clientid.value, 10)
            );
            if (clientOption) {
              clientValueList.push(clientOption);
            }
          });
        }
      } else if (typeof clientArray === "string") {
        let splitString = clientArray?.split("^")?.filter((el) => el !== "");
        if (splitString) {
          splitString.forEach((clientid) => {
            let clientOption = clientOptionArray?.find(
              (element) =>
                Number.parseInt(element.value, 10) ===
                Number.parseInt(clientid, 10)
            );
            if (clientOption) {
              clientValueList.push(clientOption);
            }
          });
        }
      }
    }

    return clientValueList;
  }, []);

  // On initial load, convert the array of integers into objects for react-select
  useEffect(() => {
    if (formikProps && formikProps.values && formikProps.values.client_ids) {
      setInitialValues({
        ...formikProps.initialValues,
        client_ids: clientsToValue(
          formikProps.values.client_ids,
          clientOptions
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showResetWarning, setShowResetWarning] = useState(false);
  const [clientOptions, setClientOptions] = useState(
    lookups["clients"]?.reduce((prev, curr) => {
      prev.push({
        value: Number.parseInt(curr.id),
        label: curr.name,
      });
      return prev;
    }, [])
  );
  const [vendorOptions, setVendorOptions] = useState(
    lookups["vendors"]?.reduce((prev, curr) => {
      prev.push({
        value: Number.parseInt(curr.id),
        label: curr.name,
      });
      return prev;
    }, [])
  );

  /**
   * A function to prevent dirtying the form in initial setting
   */
  const formikFieldSet = useCallback(
    (fieldname, value) => {
      console.log(fieldname, value);
      if (!formikProps.dirty) {
        setInitialValues({
          ...formikProps.initialValues,
          [fieldname]: value,
        });
      } else {
        formikProps.setFieldValue(fieldname, value);
      }
    },
    [formikProps, setInitialValues]
  );

  /**
   * Handle role changes to enable/display vendor or client select drop-down
   */
  useEffect(() => {
    if (typeof formikProps.values.client_ids !== "string")
      if (formikProps.values.role === "matchaadmin") {
        // prevent overwriting client_ids
        formikFieldSet("vendor_id"); // delete vendoradmin
        formikFieldSet("client_ids", []);
      } else if (formikProps.values.role === "vendoradmin") {
        formikFieldSet("client_ids", []);
      } else if (formikProps?.values?.role) {
        // ^^ make sure role exists & has been changed
        // not matcha or vendor admin
        formikFieldSet("vendor_id"); // delete vendor_admin
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values.role]);

  // Set the client options for the select dropdown
  useEffect(() => {
    let clientOptionArray = lookups["clients"]?.reduce((prev, curr) => {
      prev.push({
        value: Number.parseInt(curr.id),
        label: curr.name,
      });
      return prev;
    }, []);
    setClientOptions(clientOptionArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookups["clients"]]);

  // Set the vendor options for the select dropdown
  useEffect(() => {
    let vendorOptionArray = lookups["vendors"]?.reduce((prev, curr) => {
      prev.push({
        value: Number.parseInt(curr.id),
        label: curr.name,
      });
      return prev;
    }, []);
    setVendorOptions(vendorOptionArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookups["vendors"]]);

  // Set the client values for the select dropdown
  useEffect(() => {
    let clientValueArray = formikProps?.values?.client_ids
      ? clientsToValue(formikProps.values.client_ids, clientOptions)
      : [];
    setClientsValues(clientValueArray);
  }, [formikProps.values.client_ids, clientsToValue, clientOptions]);

  /**
   * Requests a reset of the chosen user's password when called.
   * Also clears the form back to it's initial state, and
   */
  const resetPasswordHandler = function () {
    if (formikProps?.initialValues?.email) {
      requestPassword(formikProps.initialValues.email);
    } else {
      console.error("No initial email exists, password not reset.");
    }
    formikProps.resetForm(formikProps?.initialValues);
    formikProps.setFieldValue("status", "reset");
    formikProps.handleSubmit();
  };

  /**
   * Return a list of valid roles to set a user to given current user's role
   * @returns list of users for select dropdown
   */
  const validRoles = () => {
    let roles = [
      {
        value: "webuser",
        label: "Web User",
      },
      {
        value: "webwriter",
        label: "Web Writer",
      },
      {
        value: "webadmin",
        label: "Web Admin",
      },
    ];
    if (user.role === "matchaadmin") {
      roles.push({
        value: "matchaadmin",
        label: "Matcha Admin",
      });
    }
    if (user.role === "vendoradmin" || user.role === "matchaadmin") {
      roles.push({
        value: "vendoradmin",
        label: "Vendor Admin",
      });
    }
    return roles;
  };

  return (
    <>
      <Form className="form form-label-right">
        <div className="form-group row">
          {/* Role */}
          <div className="col-lg-6">
            <label>Role</label>
            <Field
              component={SelectField}
              name="role"
              options={validRoles()}
              isDisabled={
                users.privileges.update.indexOf(user.role) > -1 ? false : true
              }
              setInitialValues={(val) => {
                setInitialValues(val);
              }}
            ></Field>
          </div>
          {/* Client Id */}
          {["webuser", "webwriter", "webadmin"].indexOf(
            formikProps.values.role
          ) > -1 && (
            <div className="col-lg-6">
              <label>Clients</label>
              <Field
                component={SelectField}
                innerRef={clientsRef}
                value={clientsValues}
                name="client_ids"
                options={clientOptions}
                isDisabled={
                  users.privileges.create.indexOf(user.role) > -1 ? false : true
                }
                setInitialValues={(val) => {
                  setInitialValues(val);
                }}
                isMulti={true}
              ></Field>
            </div>
          )}
          {/* Vendor Id */}
          {["vendoradmin"].indexOf(formikProps.values.role) > -1 && (
            <div className="col-lg-6">
              <label>Vendor</label>
              <Field
                component={SelectField}
                innerRef={vendorRef}
                name="vendor_id"
                isDisabled={
                  users.privileges.update.indexOf(user.role) > -1 &&
                  (user.role === "matchaadmin" ||
                    user.role === "vendoradmin") &&
                  formikProps.values.role === "vendoradmin"
                    ? false
                    : true
                } // only show if you are able to set a vendor & are setting a vendor
                options={vendorOptions}
                setInitialValues={(val) => {
                  setInitialValues(val);
                }}
              ></Field>
            </div>
          )}
        </div>
        <div className="form-group row">
          {/* Firstname */}
          <div className="col-lg-6">
            <Field
              name="firstname"
              className={`form-control ${formikProps?.errors?.firstname ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Firstname"
              label="First Name"
              disabled={
                users.privileges.update.indexOf(user.role) > -1 ? false : true
              }
            />
          </div>
          {/* Lastname */}
          <div className="col-lg-6">
            <Field
              name="lastname"
              className={`form-control ${formikProps?.errors?.lastname ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Lastname"
              label="Last Name"
              disabled={
                users.privileges.update.indexOf(user.role) > -1 ? false : true
              }
            />
          </div>
        </div>
        <div className="form-group row">
          {/* Email */}
          <div className="col-lg-6">
            <Field
              name="email"
              className={`form-control ${formikProps?.errors?.email ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Email"
              label="Email"
              disabled={
                users.privileges.update.indexOf(user.role) > -1 ? false : true
              }
            />
          </div>
          {/* Phone */}
          <div className="col-lg-6">
            <Field
              name="phone"
              className={`form-control ${formikProps?.errors?.phone ? "is-invalid" : ""}`}
              component={Input}
              placeholder="555-555-1234"
              label="Phonenumber"
              disabled={
                users.privileges.update.indexOf(user.role) > -1 ? false : true
              }
            />
          </div>
        </div>
        {id ? ( // if ID exists (ie you're editing an existing user)
          <>
            <div className="form-group row">
              {/* Status */}
              <div className="col-lg-4">
                <label>Status</label>
                <Field
                  component={SelectField}
                  name="status"
                  options={stateEnums["data.user_status"]?.reduce(
                    (prev, curr) => {
                      prev.push({ value: curr, label: curr });
                      return prev;
                    },
                    []
                  )}
                  isDisabled={
                    users.privileges.update.indexOf(user.role) > -1
                      ? false
                      : true
                  }
                  setInitialValues={(val) => {
                    setInitialValues(val);
                  }}
                ></Field>
              </div>
              {/* Bad Login Count */}
              <div className="col-lg-4">
                <Field
                  name="bad_login_count"
                  className={`form-control ${formikProps?.errors?.bad_login_count ? "is-invalid" : ""}`}
                  component={Input}
                  placeholder="0"
                  type="number"
                  label="# Bad Login Attempts"
                  disabled={
                    users.privileges.update.indexOf(user.role) > -1
                      ? false
                      : true
                  }
                />
              </div>
              {/* Last bad login timestamp */}
              <div className="col-lg-4">
                <Field
                  name="last_bad_login"
                  type="datetime-local"
                  className={`form-control ${formikProps?.errors?.last_bad_login ? "is-invalid" : ""}`}
                  component={Input}
                  placeholder=""
                  label="Last Bad Login"
                  disabled={true}
                />
              </div>
              {/* last login */}
              <div className="col-lg-4">
                <Field
                  name="last_login"
                  type="datetime-local"
                  className={`form-control ${formikProps?.errors?.last_login ? "is-invalid" : ""}`}
                  component={Input}
                  placeholder="Last Login"
                  label="Last Login"
                  disabled={true}
                />
              </div>
              {/* Created At */}
              <div className="col-lg-4">
                <Field
                  name="created_at"
                  type="datetime-local"
                  className={`form-control ${formikProps?.errors?.created_at ? "is-invalid" : ""}`}
                  component={Input}
                  placeholder=""
                  label="Created At"
                  disabled={true}
                />
              </div>
              {/* Updated At */}
              <div className="col-lg-4">
                <Field
                  name="updated_at"
                  type="datetime-local"
                  className={`form-control ${formikProps?.errors?.updated_at ? "is-invalid" : ""}`}
                  component={Input}
                  placeholder=""
                  label="Updated At"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                {formikProps?.values?.id &&
                formikProps?.values?.status === "pending" &&
                users.privileges.update.indexOf(user.role) > -1 ? (
                  <button
                    id="activation_copy_button"
                    type="button"
                    className="btn btn-warning btn-block"
                    onClick={() => {
                      axios
                        .get(
                          `${process.env.REACT_APP_API_URL}/rpc/create_user_verify_link?p_userid=${formikProps?.values?.id}&p_useremail=${formikProps?.values?.email}`,
                          { headers: { "Content-Type": "application/json" } }
                        )
                        .then((resp) => {
                          navigator.clipboard.writeText(resp.data); //copy to clipboard
                          document.getElementById(
                            "activation_copy_button"
                          ).innerHTML = "Copied to Clipboard!";
                          setTimeout(() => {
                            document.getElementById(
                              "activation_copy_button"
                            ).innerHTML = "Copy Activation Link";
                          }, "2000");
                        });
                    }}
                  >
                    Copy Activation Link
                  </button>
                ) : (
                  ""
                )}
                {formikProps?.values?.id &&
                formikProps?.values?.status === "reset" &&
                users.privileges.update.indexOf(user.role) > -1 ? (
                  <button
                    id="activation_copy_button"
                    type="button"
                    className="btn btn-warning btn-block"
                    onClick={() => {
                      axios
                        .get(
                          `${process.env.REACT_APP_API_URL}/rpc/create_user_verify_link?p_userid=${formikProps?.values?.id}&p_useremail=${formikProps?.values?.email}`,
                          { headers: { "Content-Type": "application/json" } }
                        )
                        .then((resp) => {
                          navigator.clipboard.writeText(resp.data); //copy to clipboard
                          document.getElementById(
                            "activation_copy_button"
                          ).innerHTML = "Copied to Clipboard!";
                          setTimeout(() => {
                            document.getElementById(
                              "activation_copy_button"
                            ).innerHTML = "Copy Reset Password Link";
                          }, "2000");
                        });
                    }}
                  >
                    Copy Reset Password Link
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-warning btn-block"
                  onClick={() => {
                    setShowResetWarning(true);
                  }}
                  disabled={
                    formikProps?.values?.status !== "pending" &&
                    users.privileges.update.indexOf(user.role) > -1
                      ? false
                      : true
                  }
                  style={{
                    pointerEvents:
                      formikProps?.values?.status === "pending"
                        ? "none"
                        : "auto",
                  }}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </Form>
      <Modal show={showResetWarning}>
        <Modal.Header>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to reset this user's password?</p>
          <p>
            A reset password email will be sent to{" "}
            <strong>{formikProps?.initialValues?.email}</strong>. They will not
            be able to sign in until they reset their password.
          </p>
          <br />
          <p>
            <strong>
              This action will lose any changes you have made to the form.
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={resetPasswordHandler}>
            Reset Password
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowResetWarning(false);
            }}
          >
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    // phone, role, status, bad_login_count, last_bad_login, last_login, created_at, updated_at
    return Yup.object().shape({
      vendor_id: Yup.number()
        .nullable(true)
        .when("role", {
          is: "vendoradmin",
          then: (schema) =>
            schema.nullable(false).required("Vendor Id is required"),
        }),
      status: Yup.string().required("User status is required"),
      role: Yup.string().required("User role is required"),
      firstname: Yup.string()
        .min(1, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("First name/initial is required"),
      lastname: Yup.string()
        .min(1, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Last name/initial is required"),
      email: Yup.string()
        .min(3, "Minimum 3 symbols")
        .matches(
          /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$/,
          "Incorrect email format"
        )
        .required("Email is required"),
      phone: Yup.string()
        .nullable(true)
        .min(3, "Minimum 3 symbols")
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          "Incorrect Phone Number Format"
        ),
      bad_login_count: Yup.string().matches(
        /^[0-9]+$/,
        "# Bad Login Attempts must be a positive number"
      ),
      client_ids: Yup.array()
        .nullable(true)
        .when("role", {
          is: (val) => ["vendoradmin", "matchaadmin"].indexOf(val) === -1,
          then: (schema) =>
            schema.nullable(false).min(1, "Clients are required"),
        }),
    });
  };
}

export default users;
