/**
 * LLMs: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr (2024)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";

import { Input } from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import copy from "clipboard-copy";

import { Snackbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// import { objectsSlice } from "../../../_redux/objects/objectsSlice";
// const { actions: slice } = objectsSlice; //necessary for some reason

const objecttype = "available_llms";

/**
 * available_llms: An object that contains information about n available_llm.
 *
 * @constructor
 */
const available_llms = {
  objecttype: objecttype,
  title: "LLMs",
  singular: "LLM",
  nameField: "name",
  initObject: { character_limit: 12000 },
  actions: [],
  privileges: {
    //Hardcoded
    create: ["matchaadmin"],
    copy: [],
    update: ["matchaadmin"],
    delete: ["matchaadmin"],
  },
  initialFilter: {
    selectFields: ["*"],
    sortOrder: "asc", // asc||desc
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: saveObject,
  getValidateForm: getValidateForm,
};

/**
 * Defines the available_llm object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display (names and emails)
 */
function getColumns(_props) {
  return [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
  ];
}

/**
 * Creates the form to used to edit the available_llm object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  id,
  formikProps,
  setInitialValues,
  copyMode,
  copiedObjectID,
}) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  return (
    <Form className="form form-label-right">
      <div className="form-group row">
        {/* LLM Name */}
        <div className="col-lg-4">
          <Field
            name="name"
            className={`form-control ${formikProps?.errors?.name ? "is-invalid" : ""}`}
            component={Input}
            placeholder="name"
            label="Name"
            disabled={
              available_llms.privileges.update.indexOf(user.role) > -1
                ? false
                : true
            }
          />
        </div>
        {/* LLM Name */}
        <div className="col-lg-4">
          <Field
            name="url"
            className={`form-control ${formikProps?.errors?.url ? "is-invalid" : ""}`}
            component={Input}
            placeholder="url"
            label="LLM URL"
            disabled={
              available_llms.privileges.update.indexOf(user.role) > -1
                ? false
                : true
            }
          />
        </div>
        {/* LLM Prompt Type */}
        <div className="col-lg-4">
          <Field
            name="prompt_type"
            className={`form-control ${formikProps?.errors?.prompt_type ? "is-invalid" : ""}`}
            component={Input}
            placeholder="prompt type"
            label="Prompt Type"
            disabled={
              available_llms.privileges.update.indexOf(user.role) > -1
                ? false
                : true
            }
          />
        </div>
        {/* LLM Context Window Size */}
        <div className="col-lg-4">
          <Field
            name="character_limit"
            className={`form-control ${formikProps?.errors?.prompt_type ? "is-invalid" : ""}`}
            component={Input}
            placeholder="character limit"
            label="Character Limit"
            disabled={
              available_llms.privileges.update.indexOf(user.role) > -1
                ? false
                : true
            }
          />
        </div>
      </div>
    </Form>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [showIdFeedback, setShowIdFeedback] = useState(false);
  const can_see_id = available_llms.privileges.update.indexOf(user.role) > -1;
  const available_llm_id = formikProps.values.id;
  const title_text =
    title + (can_see_id && available_llm_id ? " - " + available_llm_id : "");
  const title_style = { display: "inline-block" };
  /**
   * Copy Selected available_llm ID to clipboard
   * @param {*} e - event from onClick
   */
  const copyLLMId = (e) => {
    e.preventDefault();

    let copied_id = formikProps.values.id;
    copy(copied_id).then(() => {
      setShowIdFeedback(true);
    });

    e.stopPropagation();
  };

  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text}
        {can_see_id && available_llm_id ? (
          <span
            className="inline-copy"
            title="Copy LLM ID"
            onClick={copyLLMId}
          />
        ) : null}
      </Modal.Title>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showIdFeedback}
        onClose={() => setShowIdFeedback(false)}
        message={<span id="id-message-id">LLM ID copied to clipboard!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowIdFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      url: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(150, "Maximum 150 symbols")
        .required("LLM name is required"),
    });
  };
}

export default available_llms;
