/**
 * Missions: A type of object included in objectDict inside ObjectsUIHelpers.js
 * Has it's own validation function
 *
 * @author: Tyler Carr (2024)
 */

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import folders from "./Folders";

import {
  Input,
  TextAreaInput,
} from "../../../../../../_metronic/_partials/controls";
import { Form, Field } from "formik";
import * as Yup from "yup";
import { ObjectsFilter } from "../objects-filter/ObjectsFilter";
import saveObject from "./SaveObject";
import copy from "clipboard-copy";
import { Modal } from "react-bootstrap";

import SelectField from "../../../partials/SelectField";
import { Snackbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Checkbox } from "@material-ui/core";

import axios from "axios";
import _ from "lodash";
import { objectsSlice } from "../../../_redux/objects/objectsSlice";
import {
  ChatBotAISettings,
  getInitialChatSettings,
} from "../../partials/ChatAISettings";
import { MissionDeleteDialog } from "../object-delete-dialog/MissionDeleteDialog";

const objecttype = "missions";

/**
 * missions: An object that contains information about a mission.
 *
 * @constructor
 */
const missions = {
  objecttype: objecttype,
  title: getDynamicTitle,
  singular: "Mission",
  nameField: "name",
  initObject: {
    chat_settings: getInitialChatSettings(),
    add_folder: false,
  },
  actions: [
    {
      title: "Edit Mission Context",
      color: "primary",
      icon: "/media/svg/icons/Design/AndroidBulb.svg",
      displayable: () => "before",
      onClick: (row, navigate, location, dispatch) => {
        navigate({
          pathname: `/matcha/objects/missions/${row.id}/edit`,
          search: location.search,
        });
      },
    },
    {
      title: "Edit Mission Prompts",
      color: "primary",
      icon: "/media/svg/icons/Design/TripleArrow.svg",
      displayable: () => "before",
      onClick: (row, navigate, location, dispatch) => {
        const searchParams = new URLSearchParams(location.search);
        navigate({
          pathname: `/matcha/objects/prompts`,
          search: `?mission_id=${row.id}&mission_name=${encodeURIComponent(
            row.name
          )}&client_id=${row.client_id}&vendor_id=${row.vendor_id}${
            searchParams.get("s")
              ? "&ms=" + encodeURIComponent(searchParams.get("s"))
              : ""
          }`,
        });
      },
    },
  ],
  privileges: {
    //Hardcoded
    create: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    copy: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    update: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
    delete: ["matchaadmin", "vendoradmin", "webadmin", "webwriter"],
  },
  initialFilter: {
    selectFields: [
      "*",
      "vendor:vendors(id,name,vendor_guid)",
      "client:clients(id,name,client_guid)",
      "client_filter:clients(id,name,client_guid)",
      "prompt:prompts(id,title)",
      "folder:folders(id,name)",
    ],
    sortOrder: "asc", // asc||desc
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
    objectName: objecttype,
  },
  filterComponent: ObjectsFilter,
  getColumns: getColumns,
  clearCopyColumns: [
    "id",
    { name: "name", newSuffix: " (copy #)" },
    "created_at",
    "updated_at",
    "updated_by",
    "created_by",
  ],
  GetForm: GetForm,
  GetHeader: GetHeader,
  saveObject: missionSaveObject,
  getValidateForm: getValidateForm,
  ObjectDeleteDialog: MissionDeleteDialog,
};

/**
 * Navigates the user browser session away from the Missions table to the Files Table filtered for that Mission.
 *
 * @param {*} row the row of data you'd like to interact with
 * @param {*} navigate react router navigate function
 */
function openMissionFolders(row, navigate) {
  let prevSearch = new URLSearchParams(window.location.search).get("s");

  navigate({
    pathname: `/matcha/objects/folders`,
    search: `?mission_id=${row.id}&mission_name=${encodeURIComponent(
      row.name
    )}&client_id=${row.client_id}&vendor_id=${row.vendor_id}${
      prevSearch ? "&ms=" + encodeURIComponent(prevSearch) : ""
    }`,
  });
}

function getDynamicTitle() {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      <h2>Missions</h2>
    </div>
  );
}

/**
 * Saves the object provided.
 *
 * If object has a "prompts" field, saves it using a specific sql function. Otherwise use the default.
 *
 * Also set PendingHide to "true" so that when a response comes back from database after the dispatch we can either:
 *  Successfully submit (exit form)
 *  or Fail (don't exit form)
 *
 * @param {*} object the new formik created object to dispatch to the database.
 * @param {String} objecttype the objectType of the object you'd like to save
 * @param {*} user current user
 * @param {*} schema the object schema info to determine if it has created_by/updated_by columns
 * @param {*} dispatch react redux useDispatch object
 */
function missionSaveObject(object, objecttype, user, schema, dispatch) {
  // must make copy to keep prompts selected if error
  let objCopy = _.cloneDeep(object);
  let folderToAdd;

  // if folder values exist, create the object for the folder you will eventually create
  if (
    objCopy?.folder_name &&
    objCopy?.folder_embedding_model &&
    objCopy?.add_folder
  ) {
    folderToAdd = {
      name: objCopy.folder_name,
      description: objCopy?.folder_description ?? "",
      embedding_model: objCopy.folder_embedding_model,
      // mission_id: null,
      client_id: objCopy.client_id,
      vendor_id: objCopy.vendor_id,
    };
  }
  // remove folder information as to not mess up the mission insert
  delete objCopy.folder_name;
  delete objCopy.folder_description;
  delete objCopy.folder_embedding_model;
  delete objCopy.add_folder;

  if (!object?.prompts) {
    saveObject(objCopy, objecttype, user, schema, dispatch).then((r) => {
      // create folder as well
      if (folderToAdd) {
        folders.saveObject(
          { ...folderToAdd, mission_id: r.id },
          folders.objecttype,
          user,
          schema,
          dispatch,
          true
        );
      }
    });
  } else {
    // force all promptIDs to number since formik stores as string
    objCopy.prompts = object.prompts.map((i) => {
      return Number(i);
    });

    dispatch(objectsSlice.actions.startCallSave()); //set saveLoading to true
    axios({
      method: "post",
      url: "/rest/rpc/missioncopywizard",
      data: { p_object: objCopy },
    })
      .then((response) => {
        dispatch(objectsSlice.actions.endCallSave({ objecttype })); //set saveLoading to false - should cause the form to close
        dispatch(objectsSlice.actions.expireLookup({ objecttype }));
        objCopy["id"] = response.data.status.newMissionID;
        // get rid of custom created field
        delete objCopy.prompts;
        // replace prompt array (from selectfields) with new prompt_id's from missionCopyWizard
        // NOTE: if you change the selectfields but don't change this / what missionCopyWizard returns
        // you may get weird errors when trying to edit/delete a mission immediately after copy (no refresh)
        objCopy.prompt = response.data.status?.prompts ?? [];
        objCopy.created_at = new Date().toISOString(); // this probably doesn't cause issues for users in different time zones

        dispatch(objectsSlice.actions.pushEntities({ object: objCopy }));

        // create folder as well
        if (folderToAdd) {
          folders.saveObject(
            { ...folderToAdd, mission_id: response.data.status.newMissionID },
            folders.objecttype,
            user,
            schema,
            dispatch,
            true
          );
        }
      })
      .catch((error) => {
        dispatch(objectsSlice.actions.catchErrorSave({ error }));
      });
  }
}

/**
 * Formatter for the MissionNameColumn, to make the mission name clickable to open to the mission's prompts
 * @param {*} _cellContent unused in this formatter
 * @param {*} row information about the row being formatted
 * @param {*} _rowIndex unused in this formatter
 * @param {*} props additional props to pass to the formatter, passed in via getColumn
 * @returns a clickable link in place of the usual name text
 */
function MissionNameColumnFormatter(
  _cellContent,
  row,
  _rowIndex,
  { navigate }
) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        data-tip
        data-for={`viewPosition${row.id}`}
        key={`view_mission_${row.id}`}
        title={"View Mission Folders"}
        style={{ color: "var(--primary)", textDecoration: "underline" }}
        onClick={() => {
          openMissionFolders(row, navigate); // Load folder once analyze starts
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={`svg-icon svg-icon-md mr-1 svg-icon-primary`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Design/TargetArrow.svg")}
            />
          </span>
          {row.name}
        </div>
      </a>
    </>
  );
}

/**
 * Defines the mission object columns to display in the searchable table UX
 *
 * @returns returns the list (properties) of columns to display
 */
function getColumns(props) {
  return [
    {
      dataField: "client.name",
      text: "Client",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
      displayable: () => (props?.session_client_id ?? -1) < 0,
    },
    {
      dataField: "name",
      text: "Mission Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: MissionNameColumnFormatter,
      formatExtraData: {
        navigate: props.navigate,
      },
      filterType: "ilike",
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filterType: "ilike",
    },
    // hidden rows added to pass in client_id and vendor_id into row object
    {
      dataField: "client_id",
      text: "Client ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
    {
      dataField: "vendor_id",
      text: "Client ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      displayable: () => false,
    },
  ];
}

/**
 * Creates the form to used to edit the mission object
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 * @param {*} setInitialValues a function to set the initial values for the formik form without dirtying the form
 * @param {*} copyMode whether or not the object is currently being copied from another object
 * @param {number} copiedObjectID the id for the object that is being copied from (if there is one)
 *
 * @returns the form to be edited
 */
function GetForm({
  id,
  formikProps,
  setInitialValues,
  copyMode,
  copiedObjectID,
}) {
  const { user, lookups } = useSelector(
    (state) => ({
      user: state.auth.user,
      lookups: state.objects.lookups,
    }),
    shallowEqual
  );

  const [allPromptIDs, setAllPromptIDs] = useState([]);
  const [chatSettings, setChatSettings] = useState({});
  const [showAdvancedPanel, setShowAdvancedPanel] = useState(false);

  // force select all prompts by default if currently making a copy
  useEffect(() => {
    if (
      copyMode &&
      copiedObjectID &&
      formikProps?.values?.prompt?.length > 0 && // prompts were loaded from the mission select fields
      !formikProps?.values?.prompts // prompts to copy has not been set yet
    ) {
      // get all valid prompt IDs for mission
      let allPIDs = formikProps.values.prompt.reduce((prev, curr) => {
        prev.push(`${curr.id}`);
        return prev;
      }, []);

      //set all prompts to selected on load
      setAllPromptIDs(allPIDs);
      formikProps.setFieldValue("prompts", allPIDs);
    }
  }, [copyMode, copiedObjectID, formikProps]);

  // force chat settings into a formik field
  useEffect(() => {
    // if chatSettings is empty, populate it
    if (_.isEqual(chatSettings, {})) {
      let tempChatSettings = {
        ...getInitialChatSettings(),
        ...formikProps.values.chat_settings,
      };

      setChatSettings(tempChatSettings);
      // if the form hasn't been changed yet, make sure the initial
      // chat_settings matches the ones set in tempChatSettings
      if (!formikProps.dirty) {
        setInitialValues({
          ...formikProps.initialValues,
          chat_settings: tempChatSettings,
        });
      }
    } else if (!_.isEqual(chatSettings, formikProps.values.chat_settings)) {
      formikProps.setFieldValue("chat_settings", { ...chatSettings });
    }
  }, [chatSettings, formikProps, setInitialValues]);

  /**
   * Handle the "Select All Prompts" checkbox.
   * Puts all IDs into selected values if "all prompts" selected,
   * deletes that array if unselected
   */
  function handleAllChecked() {
    if (
      _.isEqual(_.sortBy(allPromptIDs), _.sortBy(formikProps?.values?.prompts))
    ) {
      formikProps.setFieldValue("prompts", []); // used to force reset
    } else {
      formikProps.setFieldValue("prompts", allPromptIDs);
    }
  }

  return (
    <Form className="form form-label-right">
      <div
        className="form-group row"
        style={{
          display: lookups?.["clients"]?.length > 1 ? "block" : "none",
        }}
      >
        {/* Client Id */}
        <div className="col-lg-4">
          <label>Client</label>
          <Field
            component={SelectField}
            id="mission_client_id"
            name="client_id"
            isDisabled={
              missions.privileges.create.indexOf(user.role) > -1 ? false : true
            }
            options={lookups["clients"]?.reduce((prev, curr) => {
              prev.push({ value: curr.id, label: curr.name });
              return prev;
            }, [])}
            setInitialValues={(val) => {
              setInitialValues(val);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        {/* Mission Name */}
        <div className="col-lg-4">
          <Field
            name="name"
            className={`form-control ${formikProps?.errors?.name ? "is-invalid" : ""}`}
            component={Input}
            placeholder="Mission Name"
            label="Mission Name"
            disabled={
              missions.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
      </div>
      <div className="form-group row">
        {/* Description */}
        <div className="col-lg-6">
          <Field
            rows="4"
            name="description"
            className={`form-control ${formikProps?.errors?.description ? "is-invalid" : ""}`}
            component={TextAreaInput}
            placeholder="Description"
            label="Description"
            disabled={
              missions.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
        {/* Sys Context */}
        <div className="col-lg-6">
          <Field
            rows="4"
            name="syscontext"
            className={`form-control ${formikProps?.errors?.syscontext ? "is-invalid" : ""}`}
            component={TextAreaInput}
            placeholder="Context for LLM about this Mission"
            label="System Context"
            disabled={
              missions.privileges.update.indexOf(user.role) > -1 ? false : true
            }
          />
        </div>
      </div>
      <div className="form-group row align-items-end">
        {(copyMode || !id) && !formikProps?.values?.add_folder && (
          <div className="col-lg-4">
            <button
              id="addFolderButton"
              className="btn btn-light"
              type="button"
              onClick={() => {
                formikProps.setFieldValue("add_folder", true);
                // set the embedding_model to default to mixedbread
                formikProps.setFieldValue("folder_embedding_model", 3);
              }}
              title="Create a folder that will be tied to this mission"
            >
              Add a folder{" "}
              <span className="svg-icon svg-icon-md svg-icon-primary mr-0">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder.svg")} />
              </span>
            </button>
          </div>
        )}
      </div>
      {/** only show if in new or copy mode **/}
      {(copyMode || !id) && formikProps?.values?.add_folder && (
        <div className="form-group row align-items-end">
          <div className="col-md-3">
            <Field
              name="folder_name"
              className={`form-control ${formikProps?.errors?.folder_name ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Folder Name"
              label="Folder Name"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="folder_description"
              className={`form-control ${formikProps?.errors?.folder_description ? "is-invalid" : ""}`}
              component={Input}
              placeholder="Folder Description"
              label="Folder Description"
            />
          </div>
          <div
            className="col-lg-2"
            style={{ display: user.role === "matchaadmin" ? "block" : "none" }}
          >
            <label>Embedding Model</label>
            {lookups?.["available_embedding_models"]?.length > 0 && (
              <Field
                component={SelectField}
                id="folder_embedding_model"
                name="folder_embedding_model"
                options={lookups["available_embedding_models"]?.reduce(
                  (prev, curr) => {
                    prev.push({ value: curr.id, label: curr.name });
                    return prev;
                  },
                  []
                )}
                setInitialValues={(val) => {
                  setInitialValues(val);
                }}
              />
            )}
          </div>
          <div className="col-md-1">
            <button
              id="undo_add_folder"
              className="btn btn-sm btn-danger"
              key="undo_add_folder"
              aria-label="Undo Add Folder"
              title="Undo Add Folder"
              type="button"
              onClick={() => {
                // remove the new folder props from props.value
                formikProps.setFieldValue("folder_name");
                formikProps.setFieldValue("folder_description");
                formikProps.setFieldValue("folder_embedding_model");
                // hide the new folder area
                formikProps.setFieldValue("add_folder", false);
              }}
            >
              <span className="svg-icon svg-icon-light mr-0 svg-icon-md">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </button>
          </div>
        </div>
      )}
      {formikProps?.values && user.role !== "webuser" && (
        <span
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setShowAdvancedPanel(!showAdvancedPanel);
          }}
          id="showMoreButton"
        >
          {showAdvancedPanel ? "▼ " : "▶ "} <strong>Advanced Options</strong>
        </span>
      )}
      {showAdvancedPanel && (
        <div className="form-group row pt-5">
          <div className="col-lg-12">
            <ChatBotAISettings
              chatSettings={chatSettings}
              setChatSettings={setChatSettings}
              enableBreakPoints
            />
          </div>
        </div>
      )}
      {copyMode && allPromptIDs.length > 0 ? (
        <fieldset>
          <div>
            <legend style={{ fontSize: "1rem" }}>Prompts to Copy</legend>
            <Checkbox
              id={`allChecked_checkbox`}
              key={`allChecked_checkbox`}
              onChange={handleAllChecked}
              checked={_.isEqual(
                _.sortBy(allPromptIDs),
                _.sortBy(formikProps?.values?.prompts)
              )}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <strong>All Prompts</strong>
          </div>
          <div
            style={{
              padding: "1em",
              maxHeight: "12.5rem",
              border: "solid lightgrey",
              borderRadius: "5px",
              overflowY: "auto", // to allow scrolling
            }}
          >
            <ul
              id="promptChoices"
              style={{
                listStyleType: "none",
                padding: 0,
                margins: 0,
                columns: 2,
              }}
            >
              {formikProps.values.prompt.map((r) => {
                return (
                  <label key={"prompts_" + r.id} className="w-100">
                    <Checkbox
                      name="prompts"
                      value={r.id}
                      checked={
                        !!formikProps?.values?.prompts?.includes(`${r.id}`)
                      }
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      key={"prompts_check_" + r.id}
                    />
                    {r.title}
                  </label>
                );
              })}
            </ul>
          </div>
        </fieldset>
      ) : (
        ""
      )}
    </Form>
  );
}

/**
 * Custom component to be included in the header of the edit modal
 * @constructor
 *
 * @param {*} formikProps the properties for the formik form
 *
 * @returns the form to be edited
 */
function GetHeader({ _id, formikProps, _setInitialValues, title }) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [showIdFeedback, setShowIdFeedback] = useState(false);
  const can_see_id = missions.privileges.update.indexOf(user.role) > -1;
  const mission_id = formikProps.values.id;
  const title_text =
    title + (can_see_id && mission_id ? " - " + mission_id : "");
  const title_style = { display: "inline-block" };
  /**
   * Copy Selected mission ID to clipboard
   * @param {*} e - event from onClick
   */
  const copyMissionId = (e) => {
    e.preventDefault();

    let copied_id = formikProps.values.id;
    copy(copied_id).then(() => {
      setShowIdFeedback(true);
    });

    e.stopPropagation();
  };

  return (
    <span>
      <Modal.Title id="example-modal-sizes-title-lg" style={title_style}>
        {title_text}
        {can_see_id && mission_id ? (
          <span
            className="inline-copy"
            title="Copy Mission ID"
            onClick={copyMissionId}
          />
        ) : null}
      </Modal.Title>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showIdFeedback}
        onClose={() => setShowIdFeedback(false)}
        message={
          <span id="id-message-id">Mission ID copied to clipboard!</span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowIdFeedback(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </span>
  );
}

/**
 * Validates that the form is correct using Yup
 * (see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10)
 *
 * @returns a Yup object that ensures the edited form is valid
 */
function getValidateForm(_user, _lookups) {
  return function () {
    return Yup.object().shape({
      name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Mission name is required"),
      description: Yup.string()
        .min(3, "Minimum 3 symbols")
        .required("Description is required"),
      client_id: Yup.number().required("Client is required"),
      folder_name: Yup.string()
        .nullable(true)
        .when("add_folder", {
          is: (val) => !!val,
          then: (schema) =>
            schema
              .nullable(false)
              .min(3, "Minimum 3 symbols")
              .max(50, "Maximum 50 symbols")
              .required("Folder name is required"),
        }),
    });
  };
}

export default missions;
