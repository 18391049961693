import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, shallowEqual } from "react-redux";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const initialChatSettings = {
  responseLLM: 1,
  detectFollowupLLM: 1,
  refinePromptPronounsLLM: 1,
  convertPromptToSampleLLM: -1,
  lookupContextReranker: 1,
  suggestFollowupsLLM: 1,
  allowFolderHallucination: false,
  allowLessRelevance: true,
  temperature: 0.1,
  topK: 40,
  topP: 0.75,
  repetitionPenalty: 1.07,
  maxNewTokens: 1024,
  seed: 1,
  maxHits: 10,
  rerankMaxHits: 3,
};

// This is for the info icon
const InfoIcon = () => (
  <span
    className="svg-icon svg-icon-primary"
    style={{ marginRight: "3px", height: "20px" }}
  >
    <SVG
      style={{ height: "17px", width: "17px" }}
      src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
    />
  </span>
);

// This is for styling the default mui slider bar to match the --primary color
const ChatSlider = withStyles({
  root: {
    color: "var(--primary)",
    height: 8,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "var(--primary)",
    border: "2px solid currentColor",
    marginTop: -3.5,
    marginLeft: -3.5,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

/**
 * Default chat settings, used to reset chat settings to default values + add new values
 * Mainly used in other components to get the default chat settings (aka missions editor)
 * @returns an object containing the default chat settings
 */
export function getInitialChatSettings() {
  return { ...initialChatSettings };
}

/**
 * AI settings for chatbot, settings should match what will be stored in missions table
 * Split out from ChatBotSidebar so we can use it in missions editor
 * @param {*} props chatSettings state variable that contains all chatbot settings
 * @returns
 */

export function ChatBotAISettings({
  chatSettings,
  setChatSettings,
  enableBreakPoints = false,
}) {
  const { lookups, user } = useSelector(
    (state) => ({
      lookups: state.objects.lookups,
      user: state.auth.user,
    }),
    shallowEqual
  );

  let rowStyle = enableBreakPoints ? "col-lg-4 col-sm-6 col-12" : "col-12";
  // set context reranker options when lookups are loaded
  useEffect(() => {
    if (lookups?.["available_llms"]?.length > 0) {
      const rerankerList = lookups["available_llms"].reduce(
        (prev, curr) => {
          prev.push({ value: curr.id, label: curr.name });
          return prev;
        },
        [{ value: -1, label: "Skip" }]
      );
      if (user?.role === "matchaadmin") {
        rerankerList.push({ value: 0, label: "Cohere (Public)" });
      }
      setContextRerankerOptions(rerankerList);
    }
  }, [lookups, user]);

  const [contextRerankerOptions, setContextRerankerOptions] = useState([]);

  return (
    <div className={"container-fluid " + enableBreakPoints ? "" : "px-0"}>
      <h4>AI Parameters</h4>
      <div className="row">
        {(user?.role === "matchaadmin" ||
          user?.role === "webadmin" ||
          user?.role === "vendoradmin") && (
          <div className={rowStyle}>
            <label
              id="allow_hallucination_label"
              htmlFor="allow_hallucination"
              title="Do not restrict LLM to focus-mode documents only"
              className="form-check-label"
            >
              <InfoIcon />
              Experimental: Override Focus Mode
              <input
                className="form-check-input"
                id="allow_hallucination"
                name="allow_hallucination"
                type="checkbox"
                style={{ marginLeft: "3px" }}
                checked={chatSettings.allowFolderHallucination}
                onChange={() => {
                  setChatSettings((ps) => ({
                    ...ps,
                    allowFolderHallucination: !ps.allowFolderHallucination,
                  }));
                }}
              />
            </label>
          </div>
        )}
        <div className={rowStyle}>
          <label
            id="allow_less_relevance_label"
            htmlFor="allow_less_relevance"
            title="If none of the documents' relevance scores are above the 50% threshold, use less relevant documents to formulate the response"
            className="form-check-label"
          >
            <InfoIcon />
            Allow Less Relevant Documents
            <input
              className="form-check-input"
              id="allow_less_relevance"
              name="allow_less_relevance"
              type="checkbox"
              style={{ marginLeft: "3px" }}
              checked={chatSettings.allowLessRelevance}
              onChange={() => {
                setChatSettings((ps) => ({
                  ...ps,
                  allowLessRelevance: !ps.allowLessRelevance,
                }));
              }}
            />
          </label>
        </div>
        <div className={rowStyle}>
          <label
            id="max_hits_label"
            title="# of matching results to look up before re-rank/filtering"
          >
            <InfoIcon />
            Initial Search Limit: {chatSettings.maxHits}
          </label>
          <br />

          <ChatSlider
            className="w-100"
            name="maxHits"
            type="range"
            min={1}
            max={25}
            step={1}
            value={chatSettings.maxHits}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                maxHits: val,
              }));
            }}
          />
        </div>

        <div className={rowStyle}>
          <label
            id="rerankMaxHits_label"
            title="# of matching results to filter down to before summarizing with LLM model"
          >
            <InfoIcon />
            Final Search Limit: {chatSettings.rerankMaxHits}
          </label>
          <br />

          <ChatSlider
            className="w-100"
            name="rerankMaxHits"
            type="range"
            min={1}
            max={10}
            step={1}
            value={chatSettings.rerankMaxHits}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                rerankMaxHits: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label id="temp_label" title="Factual to creative scale">
            <InfoIcon />
            Temperature: {chatSettings.temperature}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="temperature"
            type="range"
            decimal={2}
            min={0.01}
            max={1}
            step={0.01}
            value={chatSettings.temperature}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                temperature: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label id="topk_label" title="Number of top tokens to consider">
            <InfoIcon />
            Top-K: {chatSettings.topK}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="topK"
            type="range"
            min={1}
            max={100}
            step={1}
            value={chatSettings.topK}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                topK: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label id="topp_label" title="Top-P nucleus sampling">
            <InfoIcon />
            Top-P: {chatSettings.topP}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="topP"
            type="range"
            min={0.01}
            max={1}
            step={0.01}
            value={chatSettings.topP}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                topP: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label
            id="repetition_penalty_label"
            title="Penalty for repeating tokens"
          >
            <InfoIcon />
            Repetition Penalty: {chatSettings.repetitionPenalty}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="repetitionPenalty"
            type="range"
            min={0.01}
            max={2}
            step={0.01}
            value={chatSettings.repetitionPenalty}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                repetitionPenalty: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label
            id="max_new_tokens_label"
            title="Maximum number of new tokens to generate"
          >
            <InfoIcon />
            Max New Tokens: {chatSettings.maxNewTokens}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="maxNewTokens"
            type="range"
            min={1}
            max={4096}
            step={1}
            value={chatSettings.maxNewTokens}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                maxNewTokens: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label id="seed_label" title="Random seed for reproducibility">
            <InfoIcon />
            Seed: {chatSettings.seed}
          </label>
          <br />
          <ChatSlider
            className="w-100"
            name="seed"
            type="range"
            min={0}
            max={4096}
            step={1}
            value={chatSettings.seed}
            onChange={(_e, val) => {
              setChatSettings((ps) => ({
                ...ps,
                seed: val,
              }));
            }}
          />
        </div>
        <div className={rowStyle}>
          <label
            id="reranker_label"
            title="Search Ranking Algorithm to refine results"
          >
            <InfoIcon />
            Search Ranking Algorithm
          </label>
          <br />
          <Select
            name="reranker"
            value={contextRerankerOptions.find(
              (x) => x.value === chatSettings.lookupContextReranker
            )}
            className={"react-select-cursor"}
            menuPlacement="top"
            onChange={(e) => {
              setChatSettings((ps) => ({
                ...ps,
                lookupContextReranker: e.value,
              }));
            }}
            options={contextRerankerOptions}
          ></Select>
        </div>
      </div>
      <hr />
      <h4>Optimizations</h4>
      <div className="row px-0 form-group">
        {lookups?.["available_llms"]?.length > 0 && (
          <>
            <div className={rowStyle}>
              <label
                title="Select the LLM model to use for generating responses"
                htmlFor="response_model"
                className="form-check-label"
              >
                <InfoIcon />
                Chat Response LLM
              </label>
              <br />
              <Select
                name="response_model"
                value={{
                  value: chatSettings?.responseLLM ?? 1,
                  label:
                    lookups?.["available_llms"].find(
                      (val) => val?.id === (chatSettings?.responseLLM ?? 1)
                    )?.name ?? "Skip",
                }}
                className={"react-select-cursor"}
                menuPlacement="top"
                onChange={(e) => {
                  setChatSettings((ps) => ({
                    ...ps,
                    responseLLM: e.value,
                  }));
                }}
                options={lookups["available_llms"]?.reduce((prev, curr) => {
                  prev.push({ value: curr.id, label: curr.name });
                  return prev;
                }, [])}
              ></Select>
            </div>
            <div className={rowStyle}>
              <label
                title="Detect if message is a new question or a follow-up question in the message thread"
                htmlFor="detect_followup_model"
                className="form-check-label"
              >
                <InfoIcon />
                Detect change of topics
              </label>
              <br />
              <Select
                name="detect_followup_model"
                value={{
                  value: chatSettings?.detectFollowupLLM ?? 5,
                  label:
                    lookups?.["available_llms"].find(
                      (val) =>
                        val?.id === (chatSettings?.detectFollowupLLM ?? 5)
                    )?.name ?? "Skip",
                }}
                className={"react-select-cursor"}
                menuPlacement="top"
                onChange={(e) => {
                  setChatSettings((ps) => ({
                    ...ps,
                    detectFollowupLLM: e.value,
                  }));
                }}
                options={lookups["available_llms"]?.reduce(
                  (prev, curr) => {
                    prev.push({ value: curr.id, label: curr.name });
                    return prev;
                  },
                  [{ value: -1, label: "Skip" }]
                )}
              ></Select>
            </div>
            <div className={rowStyle}>
              <label
                title="Fix pronouns in the prompt"
                htmlFor="refine_Prompt_Pronouns_model"
                className="form-check-label"
              >
                <InfoIcon />
                Fix pronouns in the prompt
              </label>
              <br />
              <Select
                name="refine_Prompt_Pronouns_model"
                value={{
                  value: chatSettings?.refinePromptPronounsLLM ?? 5,
                  label:
                    lookups?.["available_llms"].find(
                      (val) =>
                        val?.id === (chatSettings?.refinePromptPronounsLLM ?? 5)
                    )?.name ?? "Skip",
                }}
                className={"react-select-cursor"}
                menuPlacement="top"
                onChange={(e) => {
                  setChatSettings((ps) => ({
                    ...ps,
                    refinePromptPronounsLLM: e.value,
                  }));
                }}
                options={lookups["available_llms"]?.reduce(
                  (prev, curr) => {
                    prev.push({ value: curr.id, label: curr.name });
                    return prev;
                  },
                  [{ value: -1, label: "Skip" }]
                )}
              ></Select>
            </div>
            <div className={rowStyle}>
              <label
                title="Convert prompt question into sample text to match with results"
                htmlFor="refine_Prompt_To_Sample_model"
                className="form-check-label"
              >
                <InfoIcon />
                Convert prompt questions to example matches
              </label>
              <Select
                name="refine_Prompt_To_Sample_model"
                value={{
                  value: chatSettings?.convertPromptToSampleLLM ?? 5,
                  label:
                    lookups?.["available_llms"].find(
                      (val) =>
                        val?.id ===
                        (chatSettings?.convertPromptToSampleLLM ?? 5)
                    )?.name ?? "Skip",
                }}
                className={"react-select-cursor"}
                menuPlacement="top"
                onChange={(e) => {
                  setChatSettings((ps) => ({
                    ...ps,
                    convertPromptToSampleLLM: e.value,
                  }));
                }}
                options={lookups["available_llms"]?.reduce(
                  (prev, curr) => {
                    prev.push({ value: curr.id, label: curr.name });
                    return prev;
                  },
                  [{ value: -1, label: "Skip" }]
                )}
              ></Select>
            </div>
            <div className={rowStyle}>
              <label
                title="Automatically suggest 3 follow-up questions after each response"
                htmlFor="suggest_Followups_model"
                className="form-check-label"
              >
                <InfoIcon />
                Auto-suggest next three questions
              </label>
              <Select
                name="suggest_Followups_model"
                value={{
                  value: chatSettings?.suggestFollowupsLLM ?? 5,
                  label:
                    lookups?.["available_llms"].find(
                      (val) =>
                        val?.id === (chatSettings?.suggestFollowupsLLM ?? 5)
                    )?.name ?? "Skip",
                }}
                menuPlacement="top"
                className={"react-select-cursor"}
                onChange={(e) => {
                  setChatSettings((ps) => ({
                    ...ps,
                    suggestFollowupsLLM: e.value,
                  }));
                }}
                options={lookups["available_llms"]?.reduce(
                  (prev, curr) => {
                    prev.push({ value: curr.id, label: curr.name });
                    return prev;
                  },
                  [{ value: -1, label: "Skip" }]
                )}
              ></Select>
            </div>
            {/* NOTE: MAKE SURE YOU ADD `menuplacement="top"` TO ANY REACT-SELECTS THAT YOU */}
            {/* ADD TO THE BOTTOM OF THIS COMPONENT, OTHERWISE THE CONTENT WILL BE CUT OFF*/}
          </>
        )}
      </div>
    </div>
  );
}
