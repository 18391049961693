/**
 * useSaveObject: used to save or update an object in the database.
 * Usually called by objectEditForm
 * @author Xavier Clark (2022)
 */
import * as actions from "../../../_redux/objects/objectsActions";
import _ from "lodash";

/**
 *
 * @param {*} object the object you'd like to save.
 * @param {String} objecttype the objectType of the object you'd like to save
 * @param {*} user the user information from the current session
 * @param {*} schema the object schema info to determine if it has created_by/updated_by columns
 * @param {*} dispatch react redux useDispatch object
 * @param {Boolean} skipEntitiesUpdate whether or not the saved object should be added to the
 *                                     object list, shown in objectTables
 *
 * @returns {Promise} the dispatch to the DB, which will return with an error or a copy of now saved object
 */
export default function saveObject(
  submittedObject,
  objecttype,
  user,
  schema,
  dispatch,
  skipEntitiesUpdate = false
) {
  let object = _.cloneDeep(submittedObject); //fixes rare errors with adding values to read_only object

  if (!object.id) {
    //new object, just save it
    // server request for creating object
    var newObject = { ...object };

    //Check for created_by column, and if it exists, inject the username
    if (schema?.definitions[objecttype]?.properties["created_by"]) {
      newObject["created_by"] = user.email;
    }
    return dispatch(
      actions.createObject(objecttype, newObject, skipEntitiesUpdate)
    );
  } else {
    //editing an existing object, update it
    //Check for updated_by column, and if it exists, inject the username
    if (schema?.definitions[objecttype]?.properties["updated_by"]) {
      object["updated_by"] = user.email;
    }
    // server request for updating object
    return dispatch(actions.updateObject(objecttype, object));
  }
}
