/**
 * fetchLookups: Returns all lookups for the application, either from cache if available or from server async calls
 *
 *
 * @author Tyler Carr (2024)
 */
import * as actions from "../../../_redux/objects/objectsActions";

/**
 * Fetches ALL lookups for application
 *
 * @param {*} dispatch the dispatch function from the redux store
 * @param {*} lookups the lookups from the redux store
 * @param {*} stateEnums the stateEnums from the redux store
 * @param {*} schema the schema from the redux store
 * @param {*} session_vendor_id the session_vendor_id from the redux store
 * @param {*} session_client_id the session_client_id from the redux store
 * @param {*} user the user from the redux store
 * @param {*} axios_cancel_source the axios cancel source AbortController
 * @param {boolean} fromClientPickerLookup whether the lookup is from the client picker
 */
export default function lookupFetch(
  dispatch,
  lookups,
  stateEnums,
  schema,
  session_vendor_id,
  session_client_id,
  user,
  axios_cancel_source,
  fromClientPickerLookup = false
) {
  // Moved from objectType-specific tables to hardcoded list of ALL lookups needed by the application
  const lookupTables = {
    available_llms: {
      key: "id",
      value: "name",
      url: "url",
      prompt_type: "prompt_type",
      character_limit: "character_limit",
    },
    available_embedding_models: {
      key: "id",
      value: "name",
      model_name: "model_name",
    },
    prompts: {
      key: "id",
      value: "title",
      mission_id: "mission_id",
    },
    clients: {
      key: "id",
      value: "name",
      guid: "client_guid",
      vendor_id: "vendor_id",
      timezone: "timezone",
    },
    folders: {
      key: "id",
      value: "name",
      mission_id: "mission_id",
      client_id: "client_id",
      files_count: "files_count",
    },
    missions: {
      key: "id",
      value: "name",
      syscontext: "syscontext",
      chat_settings: "chat_settings",
      client_id: "client_id",
    },
    timezones: { key: "name", value: "name" },
    vendors: { key: "id", value: "name", guid: "vendor_guid" },
  };
  for (const tableName in lookupTables) {
    //Loop over lookupTables lookups requested
    const selectFields = [];
    for (const k in lookupTables[tableName]) {
      selectFields.push(lookupTables[tableName][k]);
    }
    dispatch(
      actions.fetchLookup(
        tableName,
        {
          selectFields,
          //value is field to sort by, probably should break into it's own key and
          //make lookupsTable a regular array
          sortField: lookupTables[tableName]["value"],
          sortOrder: "asc",
        },
        stateEnums,
        lookups,
        schema,
        session_vendor_id,
        session_client_id,
        user,
        axios_cancel_source,
        fromClientPickerLookup
      )
    );
  }
}
