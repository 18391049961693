import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { objectsSlice } from "../app/modules/Matcha/_redux/objects/objectsSlice";
import { clientSlice } from "../app/modules/Matcha/_redux/client/clientSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  objects: objectsSlice.reducer,
  client: clientSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
