import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/rpc/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/rpc/requestpassword`;
export const VERIFYTOKEN_URL = `${process.env.REACT_APP_API_URL}/rpc/verifytoken`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/rpc/resetpassword`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/rpc/me`;
export const LOGACTIVITY_URL = `${process.env.REACT_APP_API_URL}/rpc/log_activity`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email: email, password: password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function verifyToken(token) {
  return axios.post(VERIFYTOKEN_URL, { token: token });
}

export function resetPassword(token, password) {
  return axios.post(RESET_PASSWORD_URL, { token: token, password: password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function logActivity(description) {
  return axios
    .post(LOGACTIVITY_URL, {
      p_log_message: description,
    })
    .catch((error) => {
      if (error.status !== 409) {
        // ignore HTTP 409 error which happens a lot in dev where DB is reset, so browser session's session_history_id no longer exists
        console.error("Error: ", error);
      }
    });
}
