import { OBJECTS_URL } from "../_redux/objects/objectsCrud";
import { clientsTableMock } from "./clientsTableMock";
import { timezonesViewMock } from "./timezonesViewMock";
import { usersTableMock } from "./usersTableMock";
import { vendorsTableMock } from "./vendorsTableMock";
import { foldersTableMock } from "./foldersTableMock"
import { filesTableMock } from "./filesTableMock"
import { missionsTableMock } from "./missionsTableMock"
import { promptsTableMock } from "./promptsTableMock"
import { postgrestSchemaTableMock } from "./postgrestSchemaTableMock";
import { available_embedding_modelsTablesMock } from "./available_embedding_modelsTablesMock";
import { available_llmsTablesMock } from "./available_llmsTablesMock";
import { get_file_base64Mock } from "./get_file_base64Mock";
import { get_file_chunksMock } from "./get_file_chunksMock";

export default function mockObjects(mock) {
  const objectTypes =
    "(clients" +
    "|timezones" +
    "|users" +
    "|vendors" +
    "|folders" +
    "|files" +
    "|missions" +
    "|prompts" +
    "|available_embedding_models" +
    "|available_llms" +
    ")";

    const pgFunctionNames =
    "("+
    "get_file_base64" +
    "|get_file_chunks" +
    // "|report_getdeviceswatched" +
    ")";

  /**
   * This is the main mock function for the PostGresT Schema
   */
  mock.onGet(`${process.env.REACT_APP_API_URL}/`).reply((config) => {
    return [200, postgrestSchemaTableMock];
  });

  /**
   * This is the main mock function for all API calls to PostgreSQL PL/pgSQL functions
   */
  mock.onPost(new RegExp(`${OBJECTS_URL}/rpc/${pgFunctionNames}$`)).reply((config) => {
    // console.log("mock.onPost functions", config.url);
    const objectType = config.url.split("/").pop();
    const data = getFunctionData(objectType, config.data);
    
    return processMockRequest(objectType, data, config);
  });

  /**
   * This is the main mock function for all API calls to AMQP hosted APIs
   */
  mock.onPost(new RegExp(`${OBJECTS_URL}/api/${pgFunctionNames}$`)).reply((config) => {
    // console.log("mock.onPost functions", config.url);
    const objectType = config.url.split("/").pop();
    const data = getFunctionData(objectType, config.data);
    
    return processMockRequest(objectType, data, config);
  });

  /**
   * This is the main mock function for all API calls to PostgresT DB objects
   */
  mock.onGet(new RegExp(`${OBJECTS_URL}/${objectTypes}$`)).reply((config) => {
    // console.log("mock.onGet objectTypes", config.url);
    const objectType = config.url.split("/").pop();
    const data = getData(objectType, config.params);
    
    return processMockRequest(objectType, data, config);
  });

  function processMockRequest(objectType, data, config) {
    // if request is expecting a single object, return it here.
    if (config?.headers?.Accept === "application/vnd.pgrst.object+json") {
      return [200, data];
    }

    // if request is expecting an array of objects, build it here (even if getData returned a single object)
    let data_list = [];
    if (Array.isArray(data)) {
      data_list.push(...data);
    } else {
      data_list.push(data);
    }

    //Remove list items beyond first 10
    const returnListRange = [];
    let returnListStart =
      config?.headers?.Range && config?.headers?.Range?.match(/[0-9]+[-][0-9]+/)
        ? parseInt(config.headers.Range.replace(/[-].*$/, ""))
        : 0;
    const returnListLimit =
      config?.headers?.Range && config?.headers?.Range?.match(/[0-9]+[-][0-9]+/)
        ? parseInt(config.headers.Range.replace(/^.*[-]/, "")) + 1
        : data_list.length;
    // console.log("Return list start/limit", returnListStart, returnListLimit);
    for (
      let i = returnListStart;
      i < data_list.length && i < returnListLimit;
      ++i
    ) {
      returnListRange.push(data_list[i]);
    }
    // console.log("Getting " + objectType + " data")
    // console.log("returning ", returnListRange);
    return [
      200,
      returnListRange,
      { "content-range": `0-${returnListRange.length}/${data_list.length}}` },
    ];
  }

  mock.onPost(new RegExp(`${OBJECTS_URL}/${objectTypes}$`)).reply((config) => {
    // const objectType = config.url.split("/").pop();
    // console.log(`${objectType} POST config : `, config);

    return [200, { ...config.data, id: 9999 }, {}];
  });

}

function getData(objectType, params) {
  // console.log(`${objectType} getData params are:`, params)
  let returnList = [];
  switch (objectType) {
    case "clients":
      returnList = clientsTableMock;
      break;
    case "timezones":
      returnList = timezonesViewMock;
      break;
    case "users":
      returnList = usersTableMock;
      break;
    case "vendors":
      returnList = vendorsTableMock;
      break;
    case "folders":
      returnList = foldersTableMock;
      break;
    case "files":
      returnList = filesTableMock;
      break;
    case "missions":
      returnList = missionsTableMock;
      break;
    case "prompts":
      returnList = promptsTableMock;
      break;
    case "available_embedding_models":
      returnList = available_embedding_modelsTablesMock;
      break;
    case "available_llms":
      returnList = available_llmsTablesMock;
      break;
    default:
      returnList = [];
      break;
  }

  //Filter list by and
  if (params && params["and"]) {
    if (params["and"].match(/^.*client_id.eq.([0-9]+).*$/)) {
      const filter_client_id = parseInt(
        params["and"].replace(/^.*client_id\.eq\.([0-9]+).*$/, "$1")
      );
      returnList = returnList.filter((c) => {
        return c.client_id === filter_client_id;
      });
    }
    if (params["and"].match(/^.*vendor_id.eq.([0-9]+).*$/)) {
      const filter_vendor_id = parseInt(
        params["and"].replace(/^.*vendor_id\.eq\.([0-9]+).*$/, "$1")
      );
      returnList = returnList.filter((c) => {
        return c.vendor_id === filter_vendor_id;
      });
    }
  }

  if (params && params["folder_id"]) {
    const filter_folder_id = parseInt(
      params["folder_id"].replace(/^eq\.([0-9]+).*$/, "$1")
    );
    returnList = returnList.filter((c) => {
      return c.folder_id && c.folder_id === filter_folder_id;
    });
  }

  if (params && params["mission_id"]) {
    const filter_mission_id = parseInt(
      params["mission_id"].replace(/^eq\.([0-9]+).*$/, "$1")
    );
    returnList = returnList.filter((c) => {
      return c.mission_id && c.mission_id === filter_mission_id;
    });
  }
  
  //Return one object with matching 'id'
  if (params && params["id"]) {
    const matchId = parseInt(params["id"].replace(/^eq[.]/, ""));
    return returnList.find((i) => {
      return i.id === matchId;
    });
  }

  return returnList;
}

function getFunctionData(objectType, params) {
  // console.log(`${objectType} getFunctionData params are:`, params);
  const paramObj = JSON.parse(params);
  let returnList = [];
  switch (objectType) {
    case "get_file_base64":
      returnList = get_file_base64Mock;
      break;
    case "get_file_chunks":
      returnList = get_file_chunksMock;
      break;
    default:
      returnList = [];
      break;
  }

  if (paramObj && paramObj["p_client_id"]) {
    returnList = returnList.filter((c) => {
      return Number(c.client_id) === Number(paramObj["p_client_id"]);
    });
  }
  
  //Return one object with matching 'id'
  if (paramObj && paramObj["id"]) {
    const matchId = parseInt(paramObj["id"]);
    return returnList.find((i) => {
      return i.id === matchId;
    });
  }
  return returnList;
}