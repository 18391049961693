/**
 * ObjectUIHelpers: Helps validate form and validate various objects.
 *
 * ObjectsDict is the core of the Matcha Objects CRUD editing abstraction.
 * This reduces coding effort when making changes to the Matcha data-model
 * (like adding new columns to a view/table, or adding an entirely new view/table).
 *
 * This should enforce a fairly consistent user-experience (UX) because the Objects CRUD
 * editing interface is all running a single set of code/screens, populating based on
 * object-context-specific configurations in objectDict.
 *
 * @author: Tyler Carr(2021), Xavier Clark(2021)
 */
import "jsoneditor-react/es/editor.min.css";

import vendors from "./object-dict/Vendors.js";
import clients from "./object-dict/Clients.js";
import users from "./object-dict/Users";
import folders from "./object-dict/Folders.js";
import files from "./object-dict/Files.js";
import missions from "./object-dict/Missions.js";
import prompts from "./object-dict/Prompts.js";
import available_llms from "./object-dict/AvailableLLMs.js";
import available_embedding_models from "./object-dict/AvailableEmbeddingModels.js";

export const ObjectStatusCssClasses = ["danger", "success", "info", ""];
export const ObjectStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ObjectTypeCssClasses = ["success", "primary", ""];
export const ObjectTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

/**
 * ObjectsDict is keyed with a DB view name identifying an object
 * (accessible via Postgrest REST API on the server-side), it provides all necessary
 * data-properties and functions to render:
 *  * a searchable, paginated list of objects with role-based permissions to Create/Update/Read/Delete
 *  * a record editor form including input fields and their associated validations
 */
export const objectsDict = {
  [folders.objecttype]: folders,
  [files.objecttype]: files,
  [missions.objecttype]: missions,
  [prompts.objecttype]: prompts,
  [vendors.objecttype]: vendors,
  [clients.objecttype]: clients,
  [users.objecttype]: users,
  [available_llms.objecttype]: available_llms,
  [available_embedding_models.objecttype]: available_embedding_models,
};

/**
 * Helper function to pad numbers with leading zeros, defaulting to a minimum of two digits.
 * Ex. 7 -> "07", 77 -> "77", 777 = "777"
 * @param {*} n
 * @param {*} numDigits
 * @returns string of integer left padded with zeros if less than numDigits long
 */
export function padzeros(n, numDigits = 2) {
  return n.toLocaleString("en-US", {
    minimumIntegerDigits: numDigits,
    useGrouping: false,
  });
}

/**
 * Helper function to format a video content upload duration (in seconds) into a h:mm:ss formatted string (mm:ss if less than an hour).
 * Ex. {"duration": 123.5} -> "02:03", {"duration": 7354} -> "2:02:34"
 * @param {*} contentProperties
 * @returns formatted duration string in the form of h:mm:ss, or '00:00' if no duration is specified
 */
export function formatDuration(contentProperties) {
  if (
    !contentProperties ||
    !contentProperties.hasOwnProperty("duration") ||
    !contentProperties.duration ||
    contentProperties.duration < 1
  ) {
    return "00:00";
  }

  // helper function adapted from https://stackoverflow.com/a/37096512 to convert seconds to hours, minutes, seconds
  let d = Number(contentProperties.duration);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m > 0 ? padzeros(m) + ":" : "00:";
  var sDisplay = s > 0 ? padzeros(s) : "00";
  return hDisplay + mDisplay + sDisplay;
}
