Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _pageButton = require("./page-button");

var _pageButton2 = _interopRequireDefault(_pageButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var PaginatonList = function PaginatonList(props) {
  return _react2.default.createElement(
    "ul",
    { className: "pagination react-bootstrap-table-page-btns-ul" },
    props.pages.map(function (pageProps) {
      if (props.pageButtonRenderer) {
        return props.pageButtonRenderer(
          _extends({}, pageProps, {
            onPageChange: props.onPageChange,
          })
        );
      }
      return _react2.default.createElement(
        _pageButton2.default,
        _extends(
          {
            key: pageProps.page,
          },
          pageProps,
          {
            onPageChange: props.onPageChange,
          }
        )
      );
    })
  );
};

PaginatonList.propTypes = {
  pages: _propTypes2.default.arrayOf(
    _propTypes2.default.shape({
      page: _propTypes2.default.oneOfType([
        _propTypes2.default.node,
        _propTypes2.default.number,
        _propTypes2.default.string,
      ]),
      active: _propTypes2.default.bool,
      disable: _propTypes2.default.bool,
      title: _propTypes2.default.string,
    })
  ).isRequired,
  onPageChange: _propTypes2.default.func.isRequired,
  pageButtonRenderer: _propTypes2.default.func,
};

PaginatonList.defaultProps = {
  pageButtonRenderer: null,
};

exports.default = PaginatonList;
