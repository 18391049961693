/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Routes, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
// import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";

export function MetronicRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (
      !isAuthorized &&
      location.pathname !== "/auth/login" &&
      location.pathname !== "/auth/verify" &&
      location.pathname !== "/auth/forgot-password"
    ) {
      // Redirect unauthorized users to login page
      navigate("/auth/login", {
        state: {
          from: location.pathname !== "/logout" ? location.pathname : "",
          search: location.search,
        },
      });
    } else if (
      isAuthorized &&
      (location.pathname.startsWith("/auth") || location.pathname === "/")
    ) {
      // Redirect authorized users to the home page or their "from/search" target if available
      navigate(
        location?.state?.from ?? "/matcha/objects/missions", // Default logged in path is `/matcha/objects/missions`
        {
          search: "?" + location?.state?.search ?? "",
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  return (
    <Routes>
      {/*Render auth page when user at `/auth` and not authorized.*/}
      <Route path="/auth/*" element={<AuthPage />} />
      {/* <Route path="/error" element={ErrorsPage} /> */}
      <Route path="/logout" element={<Logout />} />
      {isAuthorized && <Route path="/*" element={<Layout />} />}
    </Routes>
  );
}
