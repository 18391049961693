import React, { useRef, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useObjectsUIContext } from "../ObjectsUIContext";
import { shallowEqual, useSelector } from "react-redux";

export function ObjectsFilter({ listLoading }) {
  // stuff for setting search filter into URL
  const navigate = useNavigate();
  const location = useLocation();
  const searchParamsRef = useRef(new URLSearchParams(location.search));

  // Objects UI Context
  const objectsUIContext = useObjectsUIContext();
  const objectsUIProps = useMemo(() => {
    return {
      queryParams: objectsUIContext.queryParams,
      setQueryParams: objectsUIContext.setQueryParams,
      setObjecttype: objectsUIContext.setObjecttype,
      objecttype: objectsUIContext.objecttype,
    };
  }, [objectsUIContext]);

  // Get the currentState and unfilteredTotalCount from Redux store
  const { currentState, unfilteredTotalCount } = useSelector(
    (state) => ({
      currentState: state.objects,
      unfilteredTotalCount: state.objects.unfilteredTotalCount,
    }),
    shallowEqual
  );
  // Destructure totalCount from currentState
  const { totalCount } = currentState || {};
  // Get the current search text
  const searchText = searchParamsRef.current.get("s") || "";
  // Show search bar if there are more than 10 records or if there is a search text
  const showSearchBar =
    (typeof unfilteredTotalCount === "number" && unfilteredTotalCount >= 10) ||
    searchText.length > 0;
  // focus search bar
  useEffect(() => {
    if (
      (typeof totalCount === "number" && totalCount >= 10) ||
      searchText.length > 0
    ) {
      document.getElementById("searchbar").focus({ preventScroll: false });
    }
  }, [totalCount, searchText]);

  // queryParams, setQueryParams,
  const applyFilter = useCallback(
    (values) => {
      const { searchText } = values;
      const newQueryParams = { ...objectsUIProps.queryParams };
      const newObjecttype = objectsUIProps.objecttype;
      if (searchText) {
        newQueryParams.searchText = searchText;
        searchParamsRef.current.set("s", searchText);
      } else {
        newQueryParams.searchText = null;

        // remove searchparam from url if exists
        if (searchParamsRef.current.get("s")) {
          searchParamsRef.current.delete("s");
        }
      }

      // overwright the URL by adding a search param
      navigate("?" + searchParamsRef.current.toString(), { replace: true });

      if (
        !isEqual(newQueryParams, objectsUIProps.queryParams) ||
        !isEqual(newObjecttype, objectsUIProps.objecttype)
      ) {
        newQueryParams.pageNumber = 1;
        // update list by queryParams
        objectsUIProps.setQueryParams(newQueryParams, newObjecttype);
      }
    },
    [navigate, objectsUIProps, searchParamsRef]
  );

  useEffect(() => {
    const searchText = searchParamsRef.current.get("s");
    // set the searchtext but wait until the queryParams loads for the correct objecttype
    if (
      objectsUIContext.objecttype === objectsUIProps.queryParams.objectName &&
      objectsUIProps.queryParams.searchText !== searchText // don't run if searchText already in queryparams
    ) {
      applyFilter({ searchText: searchText });
    }
  }, [
    objectsUIContext.objecttype,
    objectsUIProps.queryParams,
    searchParamsRef,
    applyFilter,
  ]);

  var timeOutId;

  return (
    <>
      {showSearchBar && (
        <Formik
          initialValues={{
            searchText: searchParamsRef.current.get("s") ?? "",
          }}
          onSubmit={(values) => {
            applyFilter(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    id="searchbar"
                    type="search"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      // handleSubmit();
                      if (timeOutId) {
                        clearTimeout(timeOutId);
                      }
                      timeOutId = setTimeout(() => handleSubmit(), 500);
                      // return () => clearTimeout(timeOutId);
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
