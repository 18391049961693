import { createSlice } from "@reduxjs/toolkit";

const initialClientState = {
  loading: false,
  error: null,
  hasError: false,
  lastError: null,
  clientMessage: null,
  client_id: null,
  clientRecord: undefined,
};

export const clientSlice = createSlice({
  name: "client",
  initialState: initialClientState,
  reducers: {
    catchError: (state, action) => {
      state.hasError = true;
      state.lastError = `${
        action?.payload?.error &&
        action?.payload?.error?.response &&
        action?.payload?.error?.response?.data &&
        action?.payload?.error?.response?.data?.message
          ? action.payload.error.response.data.message
          : ""
      }${
        action?.payload?.error?.response?.data?.message &&
        action?.payload?.error?.response?.data?.details
          ? " - "
          : ""
      }${
        action?.payload?.error?.response?.data?.details
          ? action.payload.error.response.data.details
          : ""
      }`;
      state.clientMessage = action.payload.error.clientMessage;
      state.loading = false;
    },
    resetErrors: (state, action) => {
      state.clientMessage = null;
      state.hasError = false;
      state.lastError = null;
      state.error = null;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
      if (action.payload.client_id) {
        state.client_id = action.payload.client_id;
      }
      state.clientRecord = null;
    },
    // getclientById
    clientFetched: (state, action) => {
      if (action?.payload?.clientForEdit?.length > 0) {
        state.client_id = action.payload.clientForEdit[0].id;
        state.clientRecord = action.payload.clientForEdit[0];
      } else {
        state.clientRecord = null;
      }
      state.loading = false;
      state.error = null;
    },
  },
});
