/** Auto-generated by smartvx-server/tests/rests/mock_schema_match.test.js */
        export const get_file_chunksMock = [
    {
        "chunk": 0,
        "contents": "Invoice number 7CC769D80001 Receipt number Date paid 24071112 April 30, 2024 Payment method Visa - 5318",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "NarrativeText",
            "chunk_id": 0,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        78.61351013183594,
                        195.92857360839844
                    ],
                    [
                        78.61351013183594,
                        337.1770324707031
                    ],
                    [
                        510.65141663952335,
                        337.1770324707031
                    ],
                    [
                        510.65141663952335,
                        195.92857360839844
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838887",
            "detection_class_prob": 0.419141560792923,
            "doc_hash": "8ff17466-d",
            "element_id": "cffc3ef5e842f751b0995e0ca66cda8f",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##Receipt \nInvoice number 7CC769D80001 Receipt number Date paid 24071112 April 30, 2024 Payment method Visa - 5318",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Invoice number 7CC769D80001 Receipt number Date pa",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 0,
            "page_number": 1,
            "parser": "UnstructuredPDFLoader",
            "section_title": "Receipt ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8388972
        }
    },
    {
        "chunk": 1,
        "contents": "Bill to Tyler 14 Terry Lane Williston, Vermont 05495 United States tcarr@harriscomputer.com",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "Table",
            "chunk_id": 1,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        101.28453063964844,
                        386.7490539550781
                    ],
                    [
                        101.28453063964844,
                        605.025146484375
                    ],
                    [
                        1122.5009765625,
                        605.025146484375
                    ],
                    [
                        1122.5009765625,
                        386.7490539550781
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838902",
            "detection_class_prob": 0.5552292466163635,
            "doc_hash": "8ff17466-d",
            "element_id": "ff8ac8c297b113ea42df1280701e7d1e",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##Receipt \nBill to Tyler 14 Terry Lane Williston, Vermont 05495 United States tcarr@harriscomputer.com",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Bill to Tyler 14 Terry Lane Williston, Vermont 054",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 1,
            "page_number": 1,
            "parser": "UnstructuredPDFLoader",
            "section_title": "Receipt ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389044
        }
    },
    {
        "chunk": 2,
        "contents": "Brooklyn, New York 11201 United States",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "Address",
            "chunk_id": 2,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        83.33332986111111,
                        501.8702390887395
                    ],
                    [
                        83.33332986111111,
                        564.3702364845726
                    ],
                    [
                        386.27320057194936,
                        564.3702364845726
                    ],
                    [
                        386.27320057194936,
                        501.8702390887395
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838905",
            "doc_hash": "8ff17466-d",
            "element_id": "2a5b0df5a039998f31378c8b25bddc00",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##Hugging Face Inc 20 Jay Street Suite 620 \nBrooklyn, New York 11201 United States",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Brooklyn, New York 11201 United States",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 2,
            "page_number": 1,
            "parser": "UnstructuredPDFLoader",
            "section_title": "Hugging Face Inc 20 Jay Street Suite 620 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389075
        }
    },
    {
        "chunk": 3,
        "contents": "billing@huggingface.co",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "EmailAddress",
            "chunk_id": 3,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        83.33332986111111,
                        576.8702359637393
                    ],
                    [
                        83.33332986111111,
                        601.8702349220727
                    ],
                    [
                        358.3977283167607,
                        601.8702349220727
                    ],
                    [
                        358.3977283167607,
                        576.8702359637393
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838908",
            "doc_hash": "8ff17466-d",
            "element_id": "d60c6c1f4e182a2910e354a395f7859a",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##Hugging Face Inc 20 Jay Street Suite 620 \nbilling@huggingface.co",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "billing@huggingface.co",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 3,
            "page_number": 1,
            "parser": "UnstructuredPDFLoader",
            "section_title": "Hugging Face Inc 20 Jay Street Suite 620 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389099
        }
    },
    {
        "chunk": 4,
        "contents": "Description Pro subscription Qty 1 Unit price $9.00 Amount $9.00",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "Table",
            "chunk_id": 4,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        81.98865509033203,
                        775.2178344726562
                    ],
                    [
                        81.98865509033203,
                        908.9894409179688
                    ],
                    [
                        1627.8043212890625,
                        908.9894409179688
                    ],
                    [
                        1627.8043212890625,
                        775.2178344726562
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838910",
            "detection_class_prob": 0.7025692462921143,
            "doc_hash": "8ff17466-d",
            "element_id": "1d5633b0a3f49c0db26fd79a1e74d063",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##$9.00 paid on April 30, 2024 \nDescription Pro subscription Qty 1 Unit price $9.00 Amount $9.00",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Description Pro subscription Qty 1 Unit price $9.0",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 4,
            "page_number": 1,
            "parent_id": "74ebbd8c9d5b6925b2099542d0e5053b",
            "parser": "UnstructuredPDFLoader",
            "section_title": "$9.00 paid on April 30, 2024 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.838912
        }
    },
    {
        "chunk": 5,
        "contents": "Apr 30 – May 30, 2024",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "UncategorizedText",
            "chunk_id": 5,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        83.33332986111111,
                        887.286889696378
                    ],
                    [
                        83.33332986111111,
                        912.2868886547112
                    ],
                    [
                        353.14215432740957,
                        912.2868886547112
                    ],
                    [
                        353.14215432740957,
                        887.286889696378
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838913",
            "doc_hash": "8ff17466-d",
            "element_id": "1c59486a8544e4f88ecb7168416bcae0",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##$9.00 paid on April 30, 2024 \nApr 30 – May 30, 2024",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Apr 30 – May 30, 2024",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 5,
            "page_number": 1,
            "parent_id": "74ebbd8c9d5b6925b2099542d0e5053b",
            "parser": "UnstructuredPDFLoader",
            "section_title": "$9.00 paid on April 30, 2024 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389149
        }
    },
    {
        "chunk": 6,
        "contents": "Subtotal Total Amount paid $9.00 $9.00 $9.00",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "Table",
            "chunk_id": 6,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        849.6134033203125,
                        965.5763549804688
                    ],
                    [
                        849.6134033203125,
                        1128.3109130859375
                    ],
                    [
                        1618.1121826171875,
                        1128.3109130859375
                    ],
                    [
                        1618.1121826171875,
                        965.5763549804688
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838916",
            "detection_class_prob": 0.3806964159011841,
            "doc_hash": "8ff17466-d",
            "element_id": "ed01004fcdfce05d34e006a43cea7d41",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##$9.00 paid on April 30, 2024 \nSubtotal Total Amount paid $9.00 $9.00 $9.00",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "Subtotal Total Amount paid $9.00 $9.00 $9.00",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 6,
            "page_number": 1,
            "parent_id": "74ebbd8c9d5b6925b2099542d0e5053b",
            "parser": "UnstructuredPDFLoader",
            "section_title": "$9.00 paid on April 30, 2024 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389175
        }
    },
    {
        "chunk": 7,
        "contents": "24071112 · $9.00 paid on April 30, 2024",
        "file_id": 1,
        "filename": "HuggingFaceApril2024Receipt.pdf",
        "folder_description": "Receipts group",
        "folder_id": 2,
        "folder_name": "Receipts",
        "metadata": {
            "category": "NarrativeText",
            "chunk_id": 7,
            "coordinates": {
                "layout_height": 2200,
                "layout_width": 1700,
                "points": [
                    [
                        82.83303833007812,
                        2076.5556640625
                    ],
                    [
                        82.83303833007812,
                        2099.4750496539714
                    ],
                    [
                        486.6239929199219,
                        2099.4750496539714
                    ],
                    [
                        486.6239929199219,
                        2076.5556640625
                    ]
                ],
                "system": "PixelSpace"
            },
            "date": "2024-09-11 19:26:58.838918",
            "detection_class_prob": 0.7173858284950256,
            "doc_hash": "8ff17466-d",
            "element_id": "62777943f8b6021d5756bcaef8876c28",
            "embed_content": "#HuggingFaceApril2024Receipt.pdf\n##$9.00 paid on April 30, 2024 \n24071112 · $9.00 paid on April 30, 2024",
            "file_directory": "/tmp/doc_5_2_1_",
            "file_id": 0,
            "filename": "HuggingFaceApril2024Receipt.pdf",
            "filetype": "application/pdf",
            "hashid": "6dbe44e42bd0dfabc5209567b111fcba",
            "head": "24071112 · $9.00 paid on April 30, 2024",
            "input_type": ".pdf",
            "languages": [
                "eng"
            ],
            "last_modified": "2024-09-11T19:26:54",
            "order_id": 7,
            "page_number": 1,
            "parent_id": "74ebbd8c9d5b6925b2099542d0e5053b",
            "parser": "UnstructuredPDFLoader",
            "section_title": "$9.00 paid on April 30, 2024 ",
            "source": "/tmp/doc_5_2_1_/HuggingFaceApril2024Receipt.pdf",
            "time": 1726082818.8389196
        }
    }
];