Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.default = {
  PAGINATION_SIZE: 5,
  PAGE_START_INDEX: 1,
  With_FIRST_AND_LAST: true,
  SHOW_ALL_PAGE_BTNS: false,
  SHOW_TOTAL: false,
  PAGINATION_TOTAL: null,
  FIRST_PAGE_TEXT: "<<",
  PRE_PAGE_TEXT: "<",
  NEXT_PAGE_TEXT: ">",
  LAST_PAGE_TEXT: ">>",
  NEXT_PAGE_TITLE: "next page",
  LAST_PAGE_TITLE: "last page",
  PRE_PAGE_TITLE: "previous page",
  FIRST_PAGE_TITLE: "first page",
  SIZE_PER_PAGE_LIST: [10, 25, 30, 50],
  HIDE_SIZE_PER_PAGE: false,
  HIDE_PAGE_LIST_ONLY_ONE_PAGE: false,
};
