/** Auto-generated by matcha-server/tests/rests/mock_schema_match.test.js */
        export const postgrestSchemaTableMock = {
    "swagger": "2.0",
    "info": {
        "description": "This is a dynamic API generated by PostgREST",
        "title": "PostgREST API",
        "version": "12.0.2"
    },
    "host": "0.0.0.0:3000",
    "basePath": "/",
    "schemes": [
        "http"
    ],
    "consumes": [
        "application/json",
        "application/vnd.pgrst.object+json;nulls=stripped",
        "application/vnd.pgrst.object+json",
        "text/csv"
    ],
    "produces": [
        "application/json",
        "application/vnd.pgrst.object+json;nulls=stripped",
        "application/vnd.pgrst.object+json",
        "text/csv"
    ],
    "paths": {
        "/": {
            "get": {
                "tags": [
                    "Introspection"
                ],
                "summary": "OpenAPI description (this document)",
                "produces": [
                    "application/openapi+json",
                    "application/json"
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/sessionhistories": {
            "get": {
                "tags": [
                    "sessionhistories"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.ipaddress"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.referrer"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_agent"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.created_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/sessionhistories"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "sessionhistories"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.sessionhistories"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "sessionhistories"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.ipaddress"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.referrer"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_agent"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.created_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "sessionhistories"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.ipaddress"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.referrer"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.user_agent"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistories.created_at"
                    },
                    {
                        "$ref": "#/parameters/body.sessionhistories"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/prompts": {
            "get": {
                "tags": [
                    "prompts"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.prompts.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.title"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.sysprompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.precontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vectormatchtext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/prompts"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "prompts"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.prompts"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "prompts"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.prompts.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.title"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.sysprompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.precontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vectormatchtext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "prompts"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.prompts.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.title"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.sysprompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.precontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.vectormatchtext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.prompts.updated_at"
                    },
                    {
                        "$ref": "#/parameters/body.prompts"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/available_llms": {
            "get": {
                "tags": [
                    "available_llms"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.url"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.character_limit"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/available_llms"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "available_llms"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.available_llms"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "available_llms"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.url"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.character_limit"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "available_llms"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.url"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.prompt_type"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_llms.character_limit"
                    },
                    {
                        "$ref": "#/parameters/body.available_llms"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/active_users": {
            "get": {
                "tags": [
                    "active_users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.active_users.id"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/active_users"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "active_users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.active_users"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "active_users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.active_users.id"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "active_users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.active_users.id"
                    },
                    {
                        "$ref": "#/parameters/body.active_users"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/available_embedding_models": {
            "get": {
                "tags": [
                    "available_embedding_models"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_dimensions"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/available_embedding_models"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "available_embedding_models"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.available_embedding_models"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "available_embedding_models"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_dimensions"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "available_embedding_models"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.model_dimensions"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.available_embedding_models.updated_at"
                    },
                    {
                        "$ref": "#/parameters/body.available_embedding_models"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/missions": {
            "get": {
                "tags": [
                    "missions"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.missions.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.syscontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.chat_settings"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/missions"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "missions"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.missions"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "missions"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.missions.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.syscontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.chat_settings"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "missions"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.missions.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.syscontext"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.chat_settings"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.missions.updated_at"
                    },
                    {
                        "$ref": "#/parameters/body.missions"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/users": {
            "get": {
                "tags": [
                    "users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.users.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.firstname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.lastname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.password"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.phone"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.role"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.bad_login_count"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_bad_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.client_ids"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/users"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.users"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.users.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.firstname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.lastname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.password"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.phone"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.role"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.bad_login_count"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_bad_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.client_ids"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "users"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.users.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.firstname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.lastname"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.password"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.phone"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.role"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.bad_login_count"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_bad_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.last_login"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.users.client_ids"
                    },
                    {
                        "$ref": "#/parameters/body.users"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/folders": {
            "get": {
                "tags": [
                    "folders"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.folders.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.embedding_model"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.files_count"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/folders"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "folders"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.folders"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "folders"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.folders.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.embedding_model"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.files_count"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "folders"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.folders.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.mission_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.embedding_model"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.folders.files_count"
                    },
                    {
                        "$ref": "#/parameters/body.folders"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/timezones": {
            "get": {
                "tags": [
                    "timezones"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.timezones.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.timezones.abbrev"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.timezones.utc_offset"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.timezones.is_dst"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/timezones"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            }
        },
        "/clients": {
            "get": {
                "tags": [
                    "clients"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.clients.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.client_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.timezone"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/clients"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "clients"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.clients"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "clients"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.clients.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.client_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.timezone"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "clients"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.clients.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.client_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.clients.timezone"
                    },
                    {
                        "$ref": "#/parameters/body.clients"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/files": {
            "get": {
                "tags": [
                    "files"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.files.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.folder_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.filename"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.contents"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.analysis"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.correction_file_ids"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/files"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "files"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.files"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "files"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.files.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.folder_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.filename"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.contents"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.analysis"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.correction_file_ids"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "files"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.files.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.folder_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.filename"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.contents"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.analysis"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.status"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_by"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.updated_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.files.correction_file_ids"
                    },
                    {
                        "$ref": "#/parameters/body.files"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/vendors": {
            "get": {
                "tags": [
                    "vendors"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.vendors.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.vendor_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.updated_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/vendors"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "vendors"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.vendors"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "vendors"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.vendors.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.vendor_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.updated_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "vendors"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.vendors.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.name"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.vendor_guid"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.description"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.email"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.phonenumber"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.created_at"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.vendors.updated_at"
                    },
                    {
                        "$ref": "#/parameters/body.vendors"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/sessionhistorydetails": {
            "get": {
                "tags": [
                    "sessionhistorydetails"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.session_history_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.log_message"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.created_at"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/order"
                    },
                    {
                        "$ref": "#/parameters/range"
                    },
                    {
                        "$ref": "#/parameters/rangeUnit"
                    },
                    {
                        "$ref": "#/parameters/offset"
                    },
                    {
                        "$ref": "#/parameters/limit"
                    },
                    {
                        "$ref": "#/parameters/preferCount"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "schema": {
                            "items": {
                                "$ref": "#/definitions/sessionhistorydetails"
                            },
                            "type": "array"
                        }
                    },
                    "206": {
                        "description": "Partial Content"
                    }
                }
            },
            "post": {
                "tags": [
                    "sessionhistorydetails"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/body.sessionhistorydetails"
                    },
                    {
                        "$ref": "#/parameters/select"
                    },
                    {
                        "$ref": "#/parameters/preferPost"
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Created"
                    }
                }
            },
            "delete": {
                "tags": [
                    "sessionhistorydetails"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.session_history_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.log_message"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.created_at"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            },
            "patch": {
                "tags": [
                    "sessionhistorydetails"
                ],
                "parameters": [
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.client_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.vendor_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.session_history_id"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.log_message"
                    },
                    {
                        "$ref": "#/parameters/rowFilter.sessionhistorydetails.created_at"
                    },
                    {
                        "$ref": "#/parameters/body.sessionhistorydetails"
                    },
                    {
                        "$ref": "#/parameters/preferReturn"
                    }
                ],
                "responses": {
                    "204": {
                        "description": "No Content"
                    }
                }
            }
        },
        "/rpc/clientcopywizard": {
            "get": {
                "tags": [
                    "(rpc) clientcopywizard"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "jsonb",
                        "in": "query",
                        "name": "p_object",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) clientcopywizard"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_object": {
                                    "format": "jsonb"
                                }
                            },
                            "required": [
                                "p_object"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/get_file_base64": {
            "get": {
                "tags": [
                    "(rpc) get_file_base64"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_file_id",
                        "required": true,
                        "type": "integer"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) get_file_base64"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_file_id": {
                                    "format": "integer",
                                    "type": "integer"
                                }
                            },
                            "required": [
                                "p_file_id"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/create_user_verify_link": {
            "get": {
                "tags": [
                    "(rpc) create_user_verify_link"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "text",
                        "in": "query",
                        "name": "p_userid",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "format": "text",
                        "in": "query",
                        "name": "p_useremail",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) create_user_verify_link"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_useremail": {
                                    "format": "text",
                                    "type": "string"
                                },
                                "p_userid": {
                                    "format": "text",
                                    "type": "string"
                                }
                            },
                            "required": [
                                "p_userid",
                                "p_useremail"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/analyze_folder": {
            "get": {
                "tags": [
                    "(rpc) analyze_folder"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_clientid",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_folderid",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "boolean",
                        "in": "query",
                        "name": "p_reanalyze",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) analyze_folder"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_clientid": {
                                    "format": "integer",
                                    "type": "integer"
                                },
                                "p_folderid": {
                                    "format": "integer",
                                    "type": "integer"
                                },
                                "p_reanalyze": {
                                    "format": "boolean",
                                    "type": "boolean"
                                }
                            },
                            "required": [
                                "p_clientid",
                                "p_folderid",
                                "p_reanalyze"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/analysis_correction": {
            "get": {
                "tags": [
                    "(rpc) analysis_correction"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_file_id",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "text",
                        "in": "query",
                        "name": "p_analysis_json_key",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "format": "jsonb",
                        "in": "query",
                        "name": "p_analysis_json",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) analysis_correction"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_analysis_json": {
                                    "format": "jsonb"
                                },
                                "p_analysis_json_key": {
                                    "format": "text",
                                    "type": "string"
                                },
                                "p_file_id": {
                                    "format": "integer",
                                    "type": "integer"
                                }
                            },
                            "required": [
                                "p_file_id",
                                "p_analysis_json_key",
                                "p_analysis_json"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/folder_delete": {
            "get": {
                "tags": [
                    "(rpc) folder_delete"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "folder_id",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "integer[]",
                        "in": "query",
                        "name": "file_ids",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) folder_delete"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "file_ids": {
                                    "format": "integer[]",
                                    "items": {
                                        "type": "integer"
                                    },
                                    "type": "array"
                                },
                                "folder_id": {
                                    "format": "integer",
                                    "type": "integer"
                                }
                            },
                            "required": [
                                "folder_id",
                                "file_ids"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/log_activity": {
            "get": {
                "tags": [
                    "(rpc) log_activity"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "text",
                        "in": "query",
                        "name": "p_log_message",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) log_activity"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_log_message": {
                                    "format": "text",
                                    "type": "string"
                                }
                            },
                            "required": [
                                "p_log_message"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/analyze_file": {
            "get": {
                "tags": [
                    "(rpc) analyze_file"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_clientid",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "p_fileid",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "boolean",
                        "in": "query",
                        "name": "p_reanalyze",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) analyze_file"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_clientid": {
                                    "format": "integer",
                                    "type": "integer"
                                },
                                "p_fileid": {
                                    "format": "integer",
                                    "type": "integer"
                                },
                                "p_reanalyze": {
                                    "format": "boolean",
                                    "type": "boolean"
                                }
                            },
                            "required": [
                                "p_clientid",
                                "p_fileid",
                                "p_reanalyze"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/login": {
            "get": {
                "tags": [
                    "(rpc) login"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "text",
                        "in": "query",
                        "name": "email",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "format": "text",
                        "in": "query",
                        "name": "password",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) login"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "email": {
                                    "format": "text",
                                    "type": "string"
                                },
                                "password": {
                                    "format": "text",
                                    "type": "string"
                                }
                            },
                            "required": [
                                "email",
                                "password"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/mission_delete": {
            "get": {
                "tags": [
                    "(rpc) mission_delete"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "integer",
                        "in": "query",
                        "name": "mission_id",
                        "required": true,
                        "type": "integer"
                    },
                    {
                        "format": "integer[]",
                        "in": "query",
                        "name": "folder_ids",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "format": "integer[]",
                        "in": "query",
                        "name": "prompt_ids",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) mission_delete"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "folder_ids": {
                                    "format": "integer[]",
                                    "items": {
                                        "type": "integer"
                                    },
                                    "type": "array"
                                },
                                "mission_id": {
                                    "format": "integer",
                                    "type": "integer"
                                },
                                "prompt_ids": {
                                    "format": "integer[]",
                                    "items": {
                                        "type": "integer"
                                    },
                                    "type": "array"
                                }
                            },
                            "required": [
                                "mission_id",
                                "folder_ids",
                                "prompt_ids"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/requestpassword": {
            "get": {
                "tags": [
                    "(rpc) requestpassword"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "text",
                        "in": "query",
                        "name": "email",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) requestpassword"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "email": {
                                    "format": "text",
                                    "type": "string"
                                }
                            },
                            "required": [
                                "email"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/me": {
            "get": {
                "tags": [
                    "(rpc) me"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) me"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/rpc/missioncopywizard": {
            "get": {
                "tags": [
                    "(rpc) missioncopywizard"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "format": "jsonb",
                        "in": "query",
                        "name": "p_object",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "tags": [
                    "(rpc) missioncopywizard"
                ],
                "produces": [
                    "application/json",
                    "application/vnd.pgrst.object+json;nulls=stripped",
                    "application/vnd.pgrst.object+json"
                ],
                "parameters": [
                    {
                        "in": "body",
                        "name": "args",
                        "required": true,
                        "schema": {
                            "properties": {
                                "p_object": {
                                    "format": "jsonb"
                                }
                            },
                            "required": [
                                "p_object"
                            ],
                            "type": "object"
                        }
                    },
                    {
                        "$ref": "#/parameters/preferParams"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        }
    },
    "definitions": {
        "sessionhistories": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "bigint",
                    "type": "integer"
                },
                "user_id": {
                    "description": "Note:\nThis is a Foreign Key to `active_users.id`.<fk table='active_users' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "ipaddress": {
                    "format": "text",
                    "type": "string"
                },
                "referrer": {
                    "format": "text",
                    "type": "string"
                },
                "user_agent": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "prompts": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "mission_id": {
                    "description": "Note:\nThis is a Foreign Key to `missions.id`.<fk table='missions' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "title": {
                    "format": "text",
                    "type": "string"
                },
                "sysprompt": {
                    "format": "text",
                    "type": "string"
                },
                "precontext": {
                    "format": "text",
                    "type": "string"
                },
                "prompt": {
                    "format": "text",
                    "type": "string"
                },
                "vectormatchtext": {
                    "format": "text",
                    "type": "string"
                },
                "prompt_type": {
                    "enum": [
                        "discrete value",
                        "multiple-choice"
                    ],
                    "format": "data.prompt_type",
                    "type": "string"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "available_llms": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "url": {
                    "format": "text",
                    "type": "string"
                },
                "prompt_type": {
                    "format": "text",
                    "type": "string"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "character_limit": {
                    "format": "integer",
                    "type": "integer"
                }
            },
            "type": "object"
        },
        "active_users": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                }
            },
            "type": "object"
        },
        "available_embedding_models": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "model_name": {
                    "format": "text",
                    "type": "string"
                },
                "model_dimensions": {
                    "format": "integer",
                    "type": "integer"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "missions": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "description": {
                    "format": "text",
                    "type": "string"
                },
                "syscontext": {
                    "format": "text",
                    "type": "string"
                },
                "chat_settings": {
                    "format": "jsonb"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "users": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "firstname": {
                    "format": "text",
                    "type": "string"
                },
                "lastname": {
                    "format": "text",
                    "type": "string"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "email": {
                    "format": "text",
                    "type": "string"
                },
                "password": {
                    "format": "text",
                    "type": "string"
                },
                "phone": {
                    "format": "text",
                    "type": "string"
                },
                "role": {
                    "enum": [
                        "webuser",
                        "webwriter",
                        "webadmin",
                        "vendoradmin",
                        "matchaadmin"
                    ],
                    "format": "data.user_role",
                    "type": "string"
                },
                "status": {
                    "enum": [
                        "pending",
                        "active",
                        "reset",
                        "locked",
                        "deleted"
                    ],
                    "format": "data.user_status",
                    "type": "string"
                },
                "bad_login_count": {
                    "format": "bigint",
                    "type": "integer"
                },
                "last_bad_login": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "last_login": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "client_ids": {
                    "format": "text",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "folders": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "description": {
                    "format": "text",
                    "type": "string"
                },
                "mission_id": {
                    "description": "Note:\nThis is a Foreign Key to `missions.id`.<fk table='missions' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "embedding_model": {
                    "description": "Note:\nThis is a Foreign Key to `available_embedding_models.id`.<fk table='available_embedding_models' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "files_count": {
                    "format": "bigint",
                    "type": "integer"
                }
            },
            "type": "object"
        },
        "timezones": {
            "properties": {
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "abbrev": {
                    "format": "text",
                    "type": "string"
                },
                "utc_offset": {
                    "format": "interval",
                    "type": "string"
                },
                "is_dst": {
                    "format": "boolean",
                    "type": "boolean"
                }
            },
            "type": "object"
        },
        "clients": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "client_guid": {
                    "format": "text",
                    "type": "string"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "description": {
                    "format": "text",
                    "type": "string"
                },
                "email": {
                    "format": "text",
                    "type": "string"
                },
                "phonenumber": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "status": {
                    "enum": [
                        "live",
                        "implementing",
                        "retired",
                        "demo",
                        "staging"
                    ],
                    "format": "data.client_status",
                    "type": "string"
                },
                "timezone": {
                    "format": "text",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "files": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "folder_id": {
                    "description": "Note:\nThis is a Foreign Key to `folders.id`.<fk table='folders' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "filename": {
                    "format": "text",
                    "type": "string"
                },
                "contents": {
                    "format": "bytea",
                    "type": "string"
                },
                "analysis": {
                    "format": "jsonb"
                },
                "status": {
                    "enum": [
                        "loaded",
                        "indexing",
                        "indexed",
                        "analyzing",
                        "analyzed",
                        "error",
                        "deleted"
                    ],
                    "format": "data.file_status",
                    "type": "string"
                },
                "created_by": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_by": {
                    "format": "text",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "correction_file_ids": {
                    "format": "text",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "vendors": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "integer",
                    "type": "integer"
                },
                "name": {
                    "format": "text",
                    "type": "string"
                },
                "vendor_guid": {
                    "format": "text",
                    "type": "string"
                },
                "description": {
                    "format": "text",
                    "type": "string"
                },
                "email": {
                    "format": "text",
                    "type": "string"
                },
                "phonenumber": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                },
                "updated_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        },
        "sessionhistorydetails": {
            "properties": {
                "id": {
                    "description": "Note:\nThis is a Primary Key.<pk/>",
                    "format": "bigint",
                    "type": "integer"
                },
                "client_id": {
                    "description": "Note:\nThis is a Foreign Key to `clients.id`.<fk table='clients' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "vendor_id": {
                    "description": "Note:\nThis is a Foreign Key to `vendors.id`.<fk table='vendors' column='id'/>",
                    "format": "integer",
                    "type": "integer"
                },
                "session_history_id": {
                    "description": "Note:\nThis is a Foreign Key to `sessionhistories.id`.<fk table='sessionhistories' column='id'/>",
                    "format": "bigint",
                    "type": "integer"
                },
                "log_message": {
                    "format": "text",
                    "type": "string"
                },
                "created_at": {
                    "format": "timestamp with time zone",
                    "type": "string"
                }
            },
            "type": "object"
        }
    },
    "parameters": {
        "preferParams": {
            "name": "Prefer",
            "description": "Preference",
            "required": false,
            "enum": [
                "params=single-object"
            ],
            "in": "header",
            "type": "string"
        },
        "preferReturn": {
            "name": "Prefer",
            "description": "Preference",
            "required": false,
            "enum": [
                "return=representation",
                "return=minimal",
                "return=none"
            ],
            "in": "header",
            "type": "string"
        },
        "preferCount": {
            "name": "Prefer",
            "description": "Preference",
            "required": false,
            "enum": [
                "count=none"
            ],
            "in": "header",
            "type": "string"
        },
        "preferPost": {
            "name": "Prefer",
            "description": "Preference",
            "required": false,
            "enum": [
                "return=representation",
                "return=minimal",
                "return=none",
                "resolution=ignore-duplicates",
                "resolution=merge-duplicates"
            ],
            "in": "header",
            "type": "string"
        },
        "select": {
            "name": "select",
            "description": "Filtering Columns",
            "required": false,
            "in": "query",
            "type": "string"
        },
        "on_conflict": {
            "name": "on_conflict",
            "description": "On Conflict",
            "required": false,
            "in": "query",
            "type": "string"
        },
        "order": {
            "name": "order",
            "description": "Ordering",
            "required": false,
            "in": "query",
            "type": "string"
        },
        "range": {
            "name": "Range",
            "description": "Limiting and Pagination",
            "required": false,
            "in": "header",
            "type": "string"
        },
        "rangeUnit": {
            "name": "Range-Unit",
            "description": "Limiting and Pagination",
            "required": false,
            "default": "items",
            "in": "header",
            "type": "string"
        },
        "offset": {
            "name": "offset",
            "description": "Limiting and Pagination",
            "required": false,
            "in": "query",
            "type": "string"
        },
        "limit": {
            "name": "limit",
            "description": "Limiting and Pagination",
            "required": false,
            "in": "query",
            "type": "string"
        },
        "body.sessionhistories": {
            "name": "sessionhistories",
            "description": "sessionhistories",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/sessionhistories"
            }
        },
        "rowFilter.sessionhistories.id": {
            "name": "id",
            "required": false,
            "format": "bigint",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.user_id": {
            "name": "user_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.ipaddress": {
            "name": "ipaddress",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.referrer": {
            "name": "referrer",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.user_agent": {
            "name": "user_agent",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistories.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "body.prompts": {
            "name": "prompts",
            "description": "prompts",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/prompts"
            }
        },
        "rowFilter.prompts.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.mission_id": {
            "name": "mission_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.title": {
            "name": "title",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.sysprompt": {
            "name": "sysprompt",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.precontext": {
            "name": "precontext",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.prompt": {
            "name": "prompt",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.vectormatchtext": {
            "name": "vectormatchtext",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.prompt_type": {
            "name": "prompt_type",
            "required": false,
            "format": "data.prompt_type",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.prompts.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "body.available_llms": {
            "name": "available_llms",
            "description": "available_llms",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/available_llms"
            }
        },
        "rowFilter.available_llms.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.url": {
            "name": "url",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.prompt_type": {
            "name": "prompt_type",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_llms.character_limit": {
            "name": "character_limit",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "body.active_users": {
            "name": "active_users",
            "description": "active_users",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/active_users"
            }
        },
        "rowFilter.active_users.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "body.available_embedding_models": {
            "name": "available_embedding_models",
            "description": "available_embedding_models",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/available_embedding_models"
            }
        },
        "rowFilter.available_embedding_models.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.model_name": {
            "name": "model_name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.model_dimensions": {
            "name": "model_dimensions",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.available_embedding_models.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "body.missions": {
            "name": "missions",
            "description": "missions",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/missions"
            }
        },
        "rowFilter.missions.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.description": {
            "name": "description",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.syscontext": {
            "name": "syscontext",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.chat_settings": {
            "name": "chat_settings",
            "required": false,
            "format": "jsonb",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.missions.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "body.users": {
            "name": "users",
            "description": "users",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/users"
            }
        },
        "rowFilter.users.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.firstname": {
            "name": "firstname",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.lastname": {
            "name": "lastname",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.email": {
            "name": "email",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.password": {
            "name": "password",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.phone": {
            "name": "phone",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.role": {
            "name": "role",
            "required": false,
            "format": "data.user_role",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.status": {
            "name": "status",
            "required": false,
            "format": "data.user_status",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.bad_login_count": {
            "name": "bad_login_count",
            "required": false,
            "format": "bigint",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.last_bad_login": {
            "name": "last_bad_login",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.last_login": {
            "name": "last_login",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.users.client_ids": {
            "name": "client_ids",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "body.folders": {
            "name": "folders",
            "description": "folders",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/folders"
            }
        },
        "rowFilter.folders.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.description": {
            "name": "description",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.mission_id": {
            "name": "mission_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.embedding_model": {
            "name": "embedding_model",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.folders.files_count": {
            "name": "files_count",
            "required": false,
            "format": "bigint",
            "in": "query",
            "type": "string"
        },
        "body.timezones": {
            "name": "timezones",
            "description": "timezones",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/timezones"
            }
        },
        "rowFilter.timezones.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.timezones.abbrev": {
            "name": "abbrev",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.timezones.utc_offset": {
            "name": "utc_offset",
            "required": false,
            "format": "interval",
            "in": "query",
            "type": "string"
        },
        "rowFilter.timezones.is_dst": {
            "name": "is_dst",
            "required": false,
            "format": "boolean",
            "in": "query",
            "type": "string"
        },
        "body.clients": {
            "name": "clients",
            "description": "clients",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/clients"
            }
        },
        "rowFilter.clients.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.client_guid": {
            "name": "client_guid",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.description": {
            "name": "description",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.email": {
            "name": "email",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.phonenumber": {
            "name": "phonenumber",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.status": {
            "name": "status",
            "required": false,
            "format": "data.client_status",
            "in": "query",
            "type": "string"
        },
        "rowFilter.clients.timezone": {
            "name": "timezone",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "body.files": {
            "name": "files",
            "description": "files",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/files"
            }
        },
        "rowFilter.files.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.folder_id": {
            "name": "folder_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.filename": {
            "name": "filename",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.contents": {
            "name": "contents",
            "required": false,
            "format": "bytea",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.analysis": {
            "name": "analysis",
            "required": false,
            "format": "jsonb",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.status": {
            "name": "status",
            "required": false,
            "format": "data.file_status",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.created_by": {
            "name": "created_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.updated_by": {
            "name": "updated_by",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.files.correction_file_ids": {
            "name": "correction_file_ids",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "body.vendors": {
            "name": "vendors",
            "description": "vendors",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/vendors"
            }
        },
        "rowFilter.vendors.id": {
            "name": "id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.name": {
            "name": "name",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.vendor_guid": {
            "name": "vendor_guid",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.description": {
            "name": "description",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.email": {
            "name": "email",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.phonenumber": {
            "name": "phonenumber",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "rowFilter.vendors.updated_at": {
            "name": "updated_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        },
        "body.sessionhistorydetails": {
            "name": "sessionhistorydetails",
            "description": "sessionhistorydetails",
            "required": false,
            "in": "body",
            "schema": {
                "$ref": "#/definitions/sessionhistorydetails"
            }
        },
        "rowFilter.sessionhistorydetails.id": {
            "name": "id",
            "required": false,
            "format": "bigint",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistorydetails.client_id": {
            "name": "client_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistorydetails.vendor_id": {
            "name": "vendor_id",
            "required": false,
            "format": "integer",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistorydetails.session_history_id": {
            "name": "session_history_id",
            "required": false,
            "format": "bigint",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistorydetails.log_message": {
            "name": "log_message",
            "required": false,
            "format": "text",
            "in": "query",
            "type": "string"
        },
        "rowFilter.sessionhistorydetails.created_at": {
            "name": "created_at",
            "required": false,
            "format": "timestamp with time zone",
            "in": "query",
            "type": "string"
        }
    },
    "externalDocs": {
        "description": "PostgREST Documentation",
        "url": "https://postgrest.org/en/v12.0/api.html"
    }
};